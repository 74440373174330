@import 'variable';
.min-auto {
    min-width: auto!important;
}
.overflow-hidden {
    overflow: hidden;
}
.font-weight-600 {
    font-weight: 600;
    font-style: normal;
}
.p-20 {
    padding: 20px;
}
.exam-clock {
    display: inline-flex;
    width: 160px;
    height: 160px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    &.text-danger {
        background: rgba(255, 111, 97,0.2);
    }
    &.text-success {
        background: rgba(57, 178, 123,0.4);
    }
    .material-icons {
        font-size: 40px;
    }
}
.dp {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: #4b4b4a;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.white-input {
    border: none;
    padding: 0;
    padding-bottom: 15px;
    color: #191d21;
    opacity: 0.5;
    font-size: 16px;
    &:focus {
        box-shadow: none;
    }
}
.small-label {
    text-transform: uppercase;
    color: #b0afac;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
}
h1 {
    font-size: $baseSize + 16;
    line-height: 1.25;
    color: $bodytextcolor;
}
h2 {
    font-size: $baseSize + 12;
    line-height: 38px;
    color: $bodytextcolor;
}
p{
    font-size: $baseSize;
    line-height: 1.5;
    color: #4b4b4a;
}
button:focus {
    outline: none;
}
.dark-bg {
    background: #e2e1dd;
}
.black-bg {
    background: rgba(0,0,0,0.92);
}
.link-color {
    color: $activeBgColor;
    display: inline-block;
    font-size: 15px;
    i {
        vertical-align: middle;
    }
}
.form-group {
    margin-bottom: 20px;
}
.text-success{
    color: $successColor
}
.text-danger {
    color: $dangerColor
}
select.form-control {
    background: #e2e1dd;
    border: none;
    &:focus {
        background: #e2e1dd;
        outline: none;
        box-shadow: none;
    }
}
.small-container {
    max-width: 800px;
}
.bg{
    width: 100%;
}
.category-tag-circle{
    @include circle-number(28px,auto,null,13px,400,28px,none,16px); 
    padding: 0 12px;
}
button.tag-gray {
    background: #eeede9;
    color: rgba(50, 50, 49,0.5);
    border-radius: 3px;

}
.tag-green {
    background: $successColor;
    &,i{
        color: #fff!important;
    }

}
.category-tag-square{
    @include circle-number(32px,auto,null,13px,400,28px,none,3px); 
    padding: 0 12px;
    cursor: pointer;
}
.category-tag-square {
    display: inline-flex;
    align-items: center;
}
.small-tag {
    @include circle-number(20px,auto,null,10px,600,1,none,32px); 
}
.md-tag {
    @include circle-number(20px,auto,null,11px,600,1,none,32px); 
}
.blue-tag {
    border-radius: 32px;
    background-color: #e5f5ff;
    color: #0099ff;
}
.tag-web{
    background: #f3d8d6;
    color:#b3736e;
}
.tag-epost{
    background: #cfeeee;
    color:#6f9999;
}
.tag-sms{
    background: #fbf1df;
    color:#a29379;
}
[type="button"],
[type="submit"] {
    &.btn {
        height: 44px;
        padding: 0 16px;
        border-radius: 2px;
        border: none;
        display: inline-flex;
        align-items: center;
        font-size: 15px;
        min-width: 125px;
        justify-content: center;
        i{
            font-size: 18px;
        }
        &:focus {
            outline: none;
        }   
    }
    &.btn-green {
        background: #39b27b;
        color: #fff;
    }
    &.btn-gray {
        background: #e2e1dd;
        color: #323231;
    }
    &.btn-blue {
        background: #0099ff;
        color: #fff;
        height: 36px;
        font-size: 14px;
        line-height: 36px;
    }
    &.btn-tag {
        background: rgba(0, 153, 255, 0.05);
        color: #0099ff;
        border: none;
        height: 40px;
        padding: 0 16px;
        cursor: pointer;
        font-size: 14px;
        &:focus {
            outline: none;
        }
    }
    &.btn-danger {
        background: $dangerColor;
    }
    &.btn-black {
        background: $bodytextcolor;
        color: #fff;
    }
    &.btn-outline {
        border: 1px solid $successColor;
        color: $successColor;
        height: 36px;
        font-size: 14px;
        background: none;
    }
    &.btn-gray-outline {
        border: solid 1px rgba(5, 5, 5, 0.1);
        color: #393939;
        background: none;
    }
}
.half-color {
    opacity: 0.5!important;
}
.gray-scale {
    opacity: 0.7
}
/*Checkbox and Radio*/
.form-radio
{
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     position: relative;
     color: #666;
     vertical-align: middle;
     border: 0;     
     cursor: pointer;     
     outline: none;
     cursor: pointer;
    opacity: 0.4;
    &[type="radio"]{
        border-radius: 50px;
    }
    &[type="checkbox"] {
        border-radius: 2px;
    }
    & ~ label
    {
        font-size: 15px;
        font-weight: 400;
        color: #666;
        cursor: pointer;
        margin: 0;
        vertical-align: top;
        width: calc(100% - 32px);
        margin-left: 6px;
        
    }    
    &:checkbox {
        & ~ label {
            font-weight: 400;
        }
    }
}
.tick-radio {
    display: flex;
    align-items: center;
    label span {
        color: #7e7d7b;
        font-size: 13px;
    }
    .form-radio{
        border: 2px solid #c9c8c5;
        height: 20px;
        width: 20px;
        &:checked {
            background-color: $activeBgColor;
            opacity: 1;
            border-color: $activeBgColor;
            &:before {
                position: absolute;
                font: 14px/1 'Material Icons';
                transform: translate(-50%,-50%);
                top: 50%;
                left: 50%;
                content: "done";
                color: #fff;
            }
            & ~ label {
                color: $activeBgColor;
            }
        }   
    }
}
.dot-radio {
    display: flex;
    align-items: center;
    .form-radio{
        border: 2px solid #c9c8c5;
        height: 15px;
        width: 15px;      
        &:checked {
            border-color: $activeBgColor;
            opacity: 1;
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -3.5px;
                content: "";
                color: #fff;
                width: 7px;
                height: 7px;
                background :$activeBgColor;
                border-radius: 100%;
            }
            & ~ label {
                color: $activeBgColor;
            }
        }   
    }
}
.divider {
    font-size: 22px;
    line-height: 1;
    margin-top: 19px;
    color: #979694;
    padding: 0 10px;
}
.alert-container {
    position: absolute;
    bottom: 0;
    left: 10px;
    z-index: 999;
}
.alert {
    max-width: 320px;
    font-size: 15px;
    opacity: 0.95;
    font-weight: 300;
    color: #fff;
    border-radius: 3px;
    margin: 0 0 10px;
    span {
        font-weight: 500;
    }
    &.alert-uncategorised {
        background: rgb(25, 25, 25);
    }
    &.alert-info {
        background: #0099ff;
    }
    &.alert-warning {
        background: #f5a623;
    }
    &.alert-danger {
        background: #ff6f61
    }
    &.alert-sucess {
        background: #39b27b;
    } 
}
.alert-simple {
    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        i {
            font-size: 15px;
        }
    }
    .modal-content {
        padding: 40px 30px 30px;
        background: #fbfaf6;
    }
    .modal-footer {
        padding: 26px 0 0;
        border: none;
        justify-content: space-between;
    }
    p {
        margin-top: 30px;
    }
    .modal-body {
        .material-icons {
            font-size: 48px;
            color: #0099ff;
        }
    }
}
.alert-detelet-message {
    .modal-content {
        padding: 28px 30px 30px;
        background: #fbfaf6;
    }
    .modal-header {
        padding: 0 0 20px;
        border: none;
        .material-icons {
            font-size: 15px;
        }
    }
    p {
        font-size: 14px;
        color: #4b4b4a;
        line-height: 24px;
        margin-bottom: 30px;
    }
    .modal-body {
        padding: 0;
        .message-header {
            & > * {
                display: inline-block;
            }
            img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
            }
            h6 {
                font-size: 14px;
                color: #323232;
                margin: 0 10px;
            }
            span {
                font-size: 12px;
                opacity: 0.5;
                color: #323232;
            }
        }
        .user-message {
            background: rgba(2555, 111, 97, 0.2);
            padding: 10px;
            border-radius: 3px;
            margin-bottom: 30px;
        }
        .message-body {
            font-size: 14px;
            line-height: 20px;
            padding: 16px 10px;
            background: rgba(255, 255, 255, 0.5);
            margin-top: 10px;
            color: #323232;
        }
    }
    .modal-footer {
        padding: 0;
        border: none;
        justify-content: space-between;
    }
    .group-stats {
        margin-top: 20px;
        .stats-label {
            font-size: 14px;
            color: rgba(0,0,0,0.2);
        }
        h5 {
            font-weight: 400;
            line-height: 32px;
        }
    }
}