/*Input Start*/
.date-picker {
    .icon {
        position: absolute;
        right: 0;
        top: 20px;
        pointer-events: none;
        svg{
            height: 18px;
            width:18px;
        }
    }
}

.error{
    color: $dangerColor;
}
.success {
    color: $successColor;
}
.error,.success {
    margin-top: 8px;
    font-size: 13px;
    line-height: 18px;
}
.form-input {
    @extend .position-relative;
    input:focus ~ .icon, input:valid ~ .icon {
        g,#path-section {
            fill: $activeBgColor;
        }
    }
    input:invalid, input.invalid {
        & ~ .bar:before,& ~ .bar:after{
            background: $dangerColor;
        }
    } 
    input:valid, input.valid {
        & ~ .bar:before,& ~ .bar:after{
            background: $successColor;
        }
    }
    input.invalid {
        & ~ .bar:before,& ~ .bar:after{
            background: $dangerColor;
        }
    }
    input.valid {
        & ~ .bar:before,& ~ .bar:after{
            background: $successColor;
        }
    }
    input {
        padding:32px 10px 9px 0;
        color: $bodytextcolor;
        border:none;
        width:100%;
        border-bottom:1px solid rgba(50, 50, 49,0.3);
        background: none;
        font-size: 15px;
        line-height: 24px;
        &:focus {
            outline:none; 
        }
      }
      
      /* LABEL */
      label {
        @include basecolor(0.3);
        font-size:18px;
        font-weight:normal;
        position:absolute;
        pointer-events:none;
        left:0px;
        top:20px;
        line-height:1.6;
        margin: 0;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
        width: 100%;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      /* active state */
      input:focus ~ label, input:not([value=""]) ~ label, input.flatpickr-input:not([val=""]) ~ label {
        top:-2px;
        font-size:14px;
        color:$activeBgColor;
        font-weight: 500;
      }
      
      /* BOTTOM BARS*/
      .bar 	{ 
          position:relative; 
          display:block;
          width:100%;
          &:before,&:after {
            content:'';
            height:1px; 
            width:0;
            bottom:0; 
            position:absolute;
            background:$activeBgColor; 
            transition:0.2s ease all; 
            -moz-transition:0.2s ease all; 
            -webkit-transition:0.2s ease all;
          }
          &:before {
            left:50%;
          }
          &:after {
            right:50%; 
          }
        }
      
      input:focus ~ .bar:before, input:focus ~ .bar:after,input:valid ~ .bar:before, input:valid ~ .bar:after {
        width:50%;
      }
    
      .highlight {
        position:absolute;
        height:60%; 
        width:100px; 
        top:25%; 
        left:0;
        pointer-events:none;
        opacity:0;
      }
      
      /* active state */
      input:focus ~ .highlight {
        -webkit-animation:inputHighlighter 0.3s ease;
        -moz-animation:inputHighlighter 0.3s ease;
        animation:inputHighlighter 0.3s ease;
      }
}
.form-textarea {
    @extend .position-relative;
    textarea:focus ~ .icon, textarea:valid ~ .icon {
        g,#path-section {
            fill: $activeBgColor;
        }
    }
    textarea:invalid, textarea.invalid {
        & ~ .bar:before,& ~ .bar:after{
            background: $dangerColor;
        }
    } 
    textarea:valid, textarea.valid {
        & ~ .bar:before,& ~ .bar:after{
            background: $successColor;
        }
    }
    textarea.invalid {
        & ~ .bar:before,& ~ .bar:after{
            background: $dangerColor;
        }
    }
    textarea.valid {
        & ~ .bar:before,& ~ .bar:after{
            background: $successColor;
        }
    }
    textarea {
        // padding:32px 10px 9px 0;
        margin-top: 25px;
        color: $bodytextcolor;
        border:none;
        width:100%;
        background: none;
        font-size: 15px;
        line-height: 24px;
        &:focus {
            outline:none; 
        }
        outline: 0;
        box-shadow: none;
        border-radius: 0;
        box-sizing: content-box;
        overflow: hidden;
        resize: none;
      }
      
      /* LABEL */
      label {
        @include basecolor(0.3);
        font-size:18px;
        font-weight:normal;
        position:absolute;
        pointer-events:none;
        left:0px;
        top:20px;
        line-height:1.6;
        margin: 0;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
        width: 100%;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      /* active state */
      textarea:focus ~ label, textarea:not(:empty) ~ label {
        top:-2px;
        font-size:14px;
        color:$activeBgColor;
        font-weight: 500;
      }
      
      /* BOTTOM BARS*/
      .bar 	{ 
          position:relative; 
          display:block;
          width:100%;
          &:before,&:after {
            content:'';
            height:1px; 
            width:0;
            bottom:0; 
            position:absolute;
            background:$activeBgColor; 
            transition:0.2s ease all; 
            -moz-transition:0.2s ease all; 
            -webkit-transition:0.2s ease all;
          }
          &:before {
            left:50%;
          }
          &:after {
            right:50%; 
          }
        }
      
        textarea:focus ~ .bar:before, textarea:focus ~ .bar:after,textarea:valid ~ .bar:before, textarea:valid ~ .bar:after {
        width:50%;
      }
    
      .highlight {
        position:absolute;
        height:60%; 
        width:100px; 
        top:25%; 
        left:0;
        pointer-events:none;
        opacity:0;
      }
      
      /* active state */
      textarea:focus ~ .highlight {
        -webkit-animation:inputHighlighter 0.3s ease;
        -moz-animation:inputHighlighter 0.3s ease;
        animation:inputHighlighter 0.3s ease;
      }
}
.select-dropdown.dropdown {
    input ~ label {
        top: auto;
        bottom: 5px;
        font-size: 15px;
    }
    input {
        padding-top: 0;
    }
    .icon {
        top: 7px;
    }
}
/*Input End*/
/*Tags and select dropdown*/
.select-dropdown {
    .add-custom-tag .add-tags{
        background:  #fff;
        cursor: pointer;
    }
}
.add-custom-tag {
    .add-tags{
        background:  #eeede9;
        padding:0 12px 12px;
        border-radius: 2px;
    }
    .tag {
        padding: 7px 12px;
        span {
            font-size: $baseSize - 1;
        }
    }
}
.add-tags{
    .tag {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        display: inline-flex;
        align-items: center;
        margin-top: 12px;
        cursor: pointer;
        span{
            color: $bodytextcolor;
            opacity: 0.8;
        }
        i{
            font-size: $baseSize - 1;
            opacity: 0.3;
            cursor: pointer;
        }
    }
    input {
        background: none;
        border: none;
        color: $bodytextcolor;
        opacity: 0.8;
        font-size: $baseSize - 1;
        margin: 18px 0 6px;
        &:focus{
            outline: none;
        }
    }
}
.suggested-tag {
    margin-top: 12px;
    label {
        color: $bodytextcolor;
        opacity: 0.5;
        font-size: 12px;
        font-weight: 600;
    }
    .tag {
        padding: 6px;
        cursor: pointer;
        span {
            font-size: $baseSize - 3;
            color: rgba(50, 50, 49,0.7);
        }
    }
}

.select-dropdown {
    position: relative;
    .dropdown-menu{
        list-style: none;
        padding: 0;
        margin: 0;
        background: #fff;
        border-top: solid #fbfaf6;
        max-height: 250px;
        overflow-y: auto;
        border-width: 1px 0 0 0;
        width: 100%;
        .has-input {
            &:hover {
                background :#fff;
            }
            .form-control {
                background: #eee;
                border: none;
                &:focus {
                    box-shadow: none;
                }
            }
        }
        li {
            padding: 8px 12px;
            font-size: 15px;
            line-height: 24px;
            &:hover {
                background: #eeede9;
                cursor: pointer;
                small {
                    display: inline-block;
                }
            }
            span {
                font-size: 15px;
            }
            small {
                display: none;
                font-size: 13px;
            }
        }
    }
}
/*Tags and select dropdown*/

.quiz-tip {
    svg {
        margin: 30px auto;
        height: 48px;
    }
    .form-radio {
        @include positionabsolute(10px,auto,auto,10px);
    }
}
.radio-tabs{
    padding: 14px;
    flex: 1 1 auto!important;
    &:not(:last-child) {
        margin-right: 8px;
    }
    .form-radio{
        &:checked{
            & ~ label:after {
                background: $activeBgColor;
                opacity: 0.05;
            }
        }
        & ~ label:after {
            content: "";
            @include positionabsolute(0,0,0,0);
            background: #555;
            z-index: 1;
            opacity: 0.09;
        }
    }
}

.react-select-container {
    margin-bottom: 20px;
    label {
        top:-2px;
        font-size:14px;
        color:$activeBgColor;
        font-weight: 500;
        margin-bottom: 6px;
    }
}

.react-switch-container {
    margin-top:35px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #39b27b !important;
}