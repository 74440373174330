@mixin media-breakpoint-up($screen){
    @if $screen == 'mobile'{
        @media (max-width: 767px) {
            @content;
        }
    }

    @if $screen == 'desktop'{
        @media (min-width: 1025px) {
            @content;
        }
    }
    
    @if $screen == 'notDesktop'{
        @media (max-width: 1024px) {
            @content;
        }
    }

    @if $screen == 'ipad'{
        @media (min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }

    @if $screen == 'notMobile' {
        @media (min-width: 768px) {
            @content;
        }
    }
}
@mixin basecolor($opacity) {
    color: rgba(50, 50, 49,$opacity)
}
@mixin slideAnimation {
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
@mixin positionabsolute($top,$right,$bottom,$left){
    top:$top;
    right:$right;
    bottom: $bottom;
    left: $left;
    position: absolute;
}
@mixin circle-number($height,$width,$bg,$size,$weight,$line-height,$border,$radius) {
    display: inline-block;
    width: $width;
    height: $height;
    border-radius: $radius;
    background: $bg;
    font-size: $size;
    text-align: center;
    font-weight: $weight;
    line-height: $line-height;
    border:$border;
    .percentage{
        background: #5dbe91;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
}
@mixin menu($fontsize,$barheight,$color,$top-margin,$type,$active-bg,$line-height,$hover-bg) {
    padding: 0;
    margin: 0;
    list-style: none;
    & > {
        li {
            &:not(:first-child){
                margin-top: $top-margin;
            }
            display: $type;
            .active {
                background: $active-bg;
            }
            &:not(.active){
                &:hover {
                    background: $hover-bg;
                }
            }
            a {
                display: block;
                border-radius: 2px;
                display: block;
                padding: $barheight;
                font-size: $fontsize;
                line-height: $line-height;
                color: $color;
                font-weight: 600;
                .count {
                    margin-left: 8px;
                    @include circle-number(20px,20px,rgba(0, 0, 0, 0.2),14px,400,20px,none,100%);
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
@mixin percentCounter($c-width,$c-heaight,$active-width,$active-height,$c-bg,$outline:false) {
    $theme-color: $green;
    $back-color: rgba(0,0,0,0.1);
    @if $outline == true {
        $back-color: #fbfaf6;
    }
    
    .circle{
        text-align: center;
        width: $c-width;
        height: $c-heaight;
        border-radius: 100%;
        background-color: $c-bg;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .active-border{
        text-align: center;
        width: $active-width;
        height: $active-height;
        border-radius: 100%;
        background-color:rgba(0,0,0,0.2);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .uploading-file:before {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0px;
        transform: rotate(-180deg);
    }
    $step : 1;
    $loops : round(100 / $step);
    $increment : 360 / $loops;
    $half : round($loops / 2);

    @for $i from 0 through $loops {
        .active-border[data-progress="#{$i*$step}"],.uploading-file[data-progress="#{$i*$step}"]:before {
            @if ($i < $half) {
                $next-deg: 90deg + $increment * $i;
                background-image: linear-gradient(90deg, $back-color 50%, transparent 50%, transparent), linear-gradient($next-deg, $theme-color 50%, $back-color 50%, $back-color);
            }
            @else {
                $next-deg: -90deg + $increment * ($i - $half);

                background-image: linear-gradient($next-deg, $theme-color 50%, transparent 50%, transparent), linear-gradient(270deg, $theme-color 50%, $back-color 50%, $back-color);
            }
        }
    }
}
@mixin  media {
    .media {
        border: 4px solid #fff;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;
        &.without-image {
            background: rgba(0,0,0,0.05);
            border: none;
            padding: 22px 0;
            box-shadow: none;
            .media-body {
                padding-top: 0;
            }
        }
        img {
            height: 60px;
        }
        .media-body {
            padding: 7px 0 0 12px;
            position: relative;
            width: calc(100% - 90px);
        }
        h6 {
            font-size: 15px;
            line-height: 24px;
            margin: 0;
        }
        span {
            font-size: 13px;
            line-height: 20px;
            color: rgba(0,0,0,0.5);
        }
        .count {
            @include circle-number(20px,auto,rgba(0, 0, 0, 0.1),14px,600,20px,none,33px);
            padding:0 6px; 
        }
        @include percentCounter(28px,28px,32px,32px,#fff);
    }
}
@mixin close-button($left,$top, $padding) {
    .close {
        background: $primaryBgColor;
        z-index: 999;
        opacity: 1;
        font-size: $baseSize - 3;
        padding: $padding;
        text-shadow: none;
        position: absolute;
        left: $left;
        top:$top;
        text-transform: uppercase;
        &:focus {
            outline: none;
        }
        &:hover {
            opacity: 1;
        }
        i {
            display: block;
            font-size: 18px;
        }
        span {
            opacity: 0.3;
            color: $bodytextcolor;
            font-size: 10px;
        }
    }
}
@mixin new-notification($top,$right,$left) {
    position: relative;
    &:after {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #ff6f61;
        position: absolute;
        left:$left;
        right:$right;
        top:$top;
        content: "";
    }
}
@mixin white-box($bg-color,$radius,$padding,$margin) {
    background: $bg-color;
    border-radius: $radius;
    padding: $padding;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    margin:$margin;
}
@mixin search-and-options($color,$search-arrow-bg){
    .options {
        i{
            font-size: 18px;
        }
        a,i {
            color: $color; 
            text-decoration: none;   
        }
        svg g {
            fill: $color;
        }
        & > * {
            min-width: 24px;
            min-height: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }
    }
    .search {
        &:not(.show) {
            visibility: hidden;
            z-index: -1;
        }
        .input-group {
            &:before {
                content: "search";
                font-family: 'Material Icons';
                position: absolute;
                right: 5px;
                top: 4px;
                z-index: 9;
                color: rgba(25, 29, 33, 0.7);
            }
        }
        .input-group-text {
            border: none;
            padding-left: 0;
            background: $search-arrow-bg;
            cursor: pointer;
        }
        .form-control {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            border: none;
            color: rgba(25, 29, 33,0.6);
            font-size: 14px;
            border-radius: 4px!important;
        }
        i {
            font-size: 18px;
        }
    }
}
@mixin upload-file-button {
    .file-name {
        border: none;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        font-size: 14px;
        align-items: center;
        display: inline-flex;
        height: 36px;
        padding: 0 16px;
        margin: 8px 8px 0 0;
        i {
            font-size: 18px;
            margin-right:8px;
        }
        span {
            color: #979694;
            margin-left: 10px;
        }
    }
}
/*Switch Button*/
@mixin switch($width,$height,$circle-width,$circle-height,$bg,$icons:false) {
    .switch {
        position: relative;
        display: inline-block;
        width: $width;
        height: $height;
        cursor: pointer;
        &:focus{
            outline: none;
        }
        input { 
            opacity: 0;
            width: 0;
            height: 0;
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            outline: none !important;
            box-shadow: none!important;
            @if $icons{
                &:after,&:before{
                    font-family: 'Material Icons';
                    line-height: 32px;
                }  
                &:after {
                    content: "done";
                    font-size: 20px;
                    color: rgba(0,0,0,0.3);
                    left: 4px;
                    bottom: 4px;
                }  
                &:before {
                    content: "close";
                    font-size: 18px;
                    text-align: center;
                    right: 4px;
                    bottom: 4px;
                }
            }@else {
                &:before {
                    content: "";
                    right: 2px;
                    bottom: 2px;
                }
            }
            &:after,&:before {
                position: absolute;
                -webkit-transition: .4s;
                transition: .4s;

            }
            &:before {
                position: absolute;
                height: $circle-height;
                width: $circle-width;
                background-color: white;
            }
        
        }
        @if $icons {
            input:checked + .slider:before {
                -webkit-transform: translateX(-36px);
                -ms-transform: translateX(-36px);
                transform: translateX(-36px);
                content: "done";
            }
            input:checked + .slider:after {
                -webkit-transform: translateX(46px);
                -ms-transform: translateX(46px);
                transform: translateX(46px);
                content: "close";
                color: #fff;
            }
        }@else {
            input:checked + .slider:before {
                -webkit-transform: translateX(-17px);
                -ms-transform: translateX(-17px);
                transform: translateX(-17px);
            }
        }
        input:checked + .slider {
            background-color: $bg;
        } 
        input:focus + .slider {
            box-shadow: 0 0 1px $bg;
        }  
        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }
        .slider.round:before {
            border-radius: 50%;
        }
    }
}
   
@mixin custom-col($vheight,$two-col:false) {
    & > * {
        float: left;
        min-height: $vheight;
    }
    .custom-col-1 {
        width: 320px;
    }
    @if $two-col {
        .custom-col-2 {
            width: calc(100% - 320px)
        }
    }@else {
        .custom-col-2 {
            width: calc(100% - 320px - 280px)
        }
    }
    .custom-col-3 {
        width: 280px;
    }
}