.page-container {
    background: #fbfaf6;
    min-height: 93vh;
}
.page-content {
    padding: 20px 0 0;
}
.plain-layout {
    margin-top: -64px !important;
}

.site-loader img {
    width: 96px;
    height: 96px;
}

.page-content .options > * {
    min-width: 24px;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.page-content .options > a {
    color: #7e7d7b;
    text-decoration: none;
}

.page-content .options button {
    height: 32px;
    color: rgba(50, 50, 49, 0.6);
    font-size: 14px;
}


.page-content .options .dropdown > a {
    color: #7e7d7b;
    text-decoration: none;
}
.page-content .page-title-detail {
    font-style: italic;
    font-size: 14px;
}

.page-table {
    margin-top: 20px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 5px 10px 5px;
    position: relative;
    min-height: 200px;
}

.table-wrapper {
    position: relative;
    overflow: auto;
    height: calc(100vh - 300px);
}

.page-content .pointer {
    cursor: pointer !important;
}

.page-table tr {
    cursor: pointer;
}

.page-table th, .page-table td {
    padding: 5px;
    border: none;
}

.page-table td {
    padding: 4px 5px;
    border: none;
    vertical-align: middle;
    font-size: 14px;
}

.page-table th {
    padding: 10px 5px;
    font-size: 14px;
    font-weight: 500;
    border-top: none;
}

.page-table table thead th {
    border-bottom: 2px solid #dee2e6;
}

.page-table .table-buttons {
    padding: 1px;
    width: 100px;
    text-align: right;
}

.page-table .table-buttons-visible {
    padding: 1px;
    width: 100px;
    text-align: right;
}

.page-table .table-buttons button {
    background-color: white;
}

.page-table .table-buttons-visible button {
    background-color: white;
}

.page-content .collapsible-option {
    max-height: 32px;
}

.page-content .align-center {
    text-align: center;
    align-items: center;
}

.page-link {
    color: #6c757d;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}

.with-icon .icon {
    position: absolute;
    right: 0;
    top: 20px;
    pointer-events: none;
}
.alert-container {
    z-index: 1051;
}

.flat-tab .media > img {
    width: 42px !important; 
}

.flat-tab .media.active .media-body {
    border-bottom: none !important;
    padding-bottom: 0 !important; 
}

.flat-tab .media {
    > img {
      width: 42px !important;
    }
    &.active .media-body {
      border-bottom: none !important;
      padding-bottom: 0 !important;
    }
}

.flat-tab {
    .buyer-stats-table{
        td {
            padding-top: 4px;
            padding-bottom: 4px;
            font-weight: 500;
            font-size: 14px;
            &:not(:first-child){
                text-align: right;
            }
            .material-icons {
                color: #c9c8c5;
            }
        }
    }
    .nav-tabs {
        display: table;
        width: 100%;
        li{
            display: table-cell;
        }
        a {
            display: block;
            border:none;
            text-align: center;
            border-bottom: 3px solid #EEEDE9;
            color: $bodytextcolor;
            padding: 13px 10px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
            &:not(.active){
                // opacity: 0.3;
                background: #EEEDE9;
            }
            &.active {
                background: $primaryBgColor;
                border-bottom-color: $activeBgColor;
                
            }
        }
    }
}

.flat-tab {
    position: relative;
    .nav-item {
        position: relative;
        .nav-link {
            border-color: #e2e1dd;
        }
        cursor: pointer;
    }
    .count {
        @include circle-number(14px, 14px, $successColor, 9px, 500, 14px, none, 100%);
        color: #fff;
        position: absolute;
        right: 3px;
        top: 3px;
    }
    .media {
        padding: 16px;
        align-items: flex-start;
        cursor: pointer;
        & > img {
            width: 32px;
        }
        &.active {
            background: #fbfaf6;
            border-right: 1px solid #eeede9;
        }
    }
    .media-body {
        margin-left: 20px;
        h5 {
            font-size: 15px;
            font-weight: 600;
            color: #323232;
        }
        h6 {
            font-size: 14px;
            color: #979694;
            font-weight: 400;
            line-height: 18px;
            margin: 0;
            span {
                color: #323232;
            }
        }
    }
    .announcement-type {
        color: #979694;
        font-size: 12px;
    }
    // .name-letter {
    //     width: 32px;
    //     height: 32px;
    //     background: #4b4b4a;
    //     border-radius: 100%;
    //     line-height: 32px;
    //     text-align: center;
    //     color: #fff;
    //     font-size: 14px;
    //     font-weight: 600;
    // }
}

.txt-low {
    text-transform: lowercase;
}

.txt-cap {
    text-transform: capitalize;
}

.txt-upp {
    text-transform: uppercase;
}

.spinner-container {
    display: flex;
    flex: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #EAEBEC;
    opacity: 0.6;
    z-index: 3;
}
.spinner-container img {
    margin: auto;
}

/*
table.table-ellipsis {
    table-layout: fixed;
}

.table-ellipsis td  {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
*/

.table.table-ellipsis tbody td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

[data-cell="select"] {
    width: 40px !important;
    text-align: center;
}

.table-user-list {
    th {
        text-decoration: underline;
    }

    [data-cell="buttons"] {
        width: 100px !important;
        text-align: center;
    }

    [data-cell="role"] {
        width: 70px !important;
        text-align: center;
    }

    [data-cell="username"] {
        width: 150px !important;       
    }

    td[data-cell="username"] {
        text-transform: lowercase;   
    }

    [data-cell="email"] {
        width: 200px !important;
    }

    td[data-cell="email"] {
        text-transform: lowercase;   
    }

    [data-cell="groups"] {
        width: 150px !important;
        text-align: center;
    }

    [data-cell="name"] {
        width: auto !important;
        text-transform: capitalize;
    }

    [data-cell="surname"] {
        width: auto !important;
        text-transform: capitalize;
    }

    [data-cell="mobile"] {
        width: 120px !important;
    }

}

.table-group-list {
    th {
        text-decoration: underline;
    }

    [data-cell="buttons"] {
        width: 100px !important;
        text-align: center;
    }

    [data-cell="user_count"] {
        width: 125px !important;
        text-align: center;
    }

    [data-cell="session_count"] {
        width: 155px !important;
        text-align: center;
    }

    td[data-cell="description"] {
        width: auto !important;
        text-transform: capitalize;
    }

    [data-cell="name"] {
        width: auto !important;
        text-transform: capitalize;
    }
}


.table-session-list {
    th {
        text-decoration: underline;
    }

    [data-cell="buttons"] {
        width: 100px !important;
        text-align: center;
    }

    [data-cell="attendee_count"] {
        width: 125px !important;
        text-align: center;
        text-transform: capitalize;
    }

    [data-cell="name"] {
        width: auto !important;
        text-transform: capitalize;
    }

    [data-cell="start_date"] {
        width: 140px !important;
    }

    [data-cell="duration"] {
        width: 100px !important;
        text-transform: capitalize;
    }
} 

.pull-right {
    float: right;
}

.ts-08 {
    transform: scale(0.8);
}

.ts-09 {
    transform: scale(0.9);
}
.ts-1 {
    transform: scale(1);
}

.spinner-container[data-name=login-form-spin] {
    margin-left: -40px;
    margin-top: -40px;
}


.add-custom-tag {
    .add-tags{
        background:  #eeede9;
        padding:0 12px 12px;
        border-radius: 2px;
    }
    .tag {
        padding: 7px 12px;
        span {
            font-size: $baseSize - 1;
        }
    }
    .tag-full {
        width: 100% !important;
        span, label, input:not([type='checkbox']), textarea {
            width: 100% !important;
        }
        input, textarea {
            margin: 0px;
        }
        .b-input {
            border: solid 1px #dedede;
            padding: 3px;
        }
    }
}