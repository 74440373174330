/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0, .login-block .btn-primary, .notifcation-modal .modal-header {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle, .notification-bell, .hide-notification {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex, .login-block .btn-primary, .login-block .dropdown .dropdown-toggle {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center, .notification-bell, .hide-notification {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .login-wrapper .login-block-footer, .login-block .btn-primary, .login-block .dropdown .dropdown-toggle, .notification-bell, .hide-notification {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative, .form-input, .form-textarea, .login-block {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .login-wrapper .login-block-footer img,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, .login-block p,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0, .coypright-modal .modal-content, .notifcation-modal .modal-content {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/*Switch Button*/
.min-auto {
  min-width: auto !important; }

.overflow-hidden {
  overflow: hidden; }

.font-weight-600 {
  font-weight: 600;
  font-style: normal; }

.p-20 {
  padding: 20px; }

.exam-clock {
  display: inline-flex;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  align-items: center;
  justify-content: center; }
  .exam-clock.text-danger {
    background: rgba(255, 111, 97, 0.2); }
  .exam-clock.text-success {
    background: rgba(57, 178, 123, 0.4); }
  .exam-clock .material-icons {
    font-size: 40px; }

.dp {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #4b4b4a;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center; }

.white-input {
  border: none;
  padding: 0;
  padding-bottom: 15px;
  color: #191d21;
  opacity: 0.5;
  font-size: 16px; }
  .white-input:focus {
    box-shadow: none; }

.small-label {
  text-transform: uppercase;
  color: #b0afac;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px; }

h1 {
  font-size: 32px;
  line-height: 1.25;
  color: #323231; }

h2 {
  font-size: 28px;
  line-height: 38px;
  color: #323231; }

p {
  font-size: 16px;
  line-height: 1.5;
  color: #4b4b4a; }

button:focus {
  outline: none; }

.dark-bg {
  background: #e2e1dd; }

.black-bg {
  background: rgba(0, 0, 0, 0.92); }

.link-color {
  color: #0099ff;
  display: inline-block;
  font-size: 15px; }
  .link-color i {
    vertical-align: middle; }

.form-group {
  margin-bottom: 20px; }

.text-success {
  color: #39b27b !important; }

.text-danger {
  color: #ff6f61 !important; }

select.form-control {
  background: #e2e1dd;
  border: none; }
  select.form-control:focus {
    background: #e2e1dd;
    outline: none;
    box-shadow: none; }

.small-container {
  max-width: 800px; }

.bg {
  width: 100%; }

.category-tag-circle {
  display: inline-block;
  width: auto;
  height: 28px;
  border-radius: 16px;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  line-height: 28px;
  border: none;
  padding: 0 12px; }
  .category-tag-circle .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

button.tag-gray {
  background: #eeede9;
  color: rgba(50, 50, 49, 0.5);
  border-radius: 3px; }

.tag-green {
  background: #39b27b !important; }
  .tag-green, .tag-green i {
    color: #fff !important; }

.category-tag-square {
  display: inline-block;
  width: auto;
  height: 32px;
  border-radius: 3px;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  line-height: 28px;
  border: none;
  padding: 0 12px;
  cursor: pointer; }
  .category-tag-square .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

.category-tag-square {
  display: inline-flex;
  align-items: center; }

.small-tag {
  display: inline-block;
  width: auto;
  height: 20px;
  border-radius: 32px;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  border: none; }
  .small-tag .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

.md-tag {
  display: inline-block;
  width: auto;
  height: 20px;
  border-radius: 32px;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  border: none; }
  .md-tag .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

.blue-tag {
  border-radius: 32px;
  background-color: #e5f5ff;
  color: #0099ff; }

.tag-web {
  background: #f3d8d6;
  color: #b3736e; }

.tag-epost {
  background: #cfeeee;
  color: #6f9999; }

.tag-sms {
  background: #fbf1df;
  color: #a29379; }

[type="button"].btn,
[type="submit"].btn {
  height: 44px;
  padding: 0 16px;
  border-radius: 2px;
  border: none;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  min-width: 125px;
  justify-content: center; }
  [type="button"].btn i,
  [type="submit"].btn i {
    font-size: 18px; }
  [type="button"].btn:focus,
  [type="submit"].btn:focus {
    outline: none; }

[type="button"].btn-green,
[type="submit"].btn-green {
  background: #39b27b;
  color: #fff; }

[type="button"].btn-gray,
[type="submit"].btn-gray {
  background: #e2e1dd;
  color: #323231; }

[type="button"].btn-blue,
[type="submit"].btn-blue {
  background: #0099ff;
  color: #fff;
  height: 36px;
  font-size: 14px;
  line-height: 36px; }

[type="button"].btn-tag,
[type="submit"].btn-tag {
  background: rgba(0, 153, 255, 0.05);
  color: #0099ff;
  border: none;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
  font-size: 14px; }
  [type="button"].btn-tag:focus,
  [type="submit"].btn-tag:focus {
    outline: none; }

[type="button"].btn-danger,
[type="submit"].btn-danger {
  background: #ff6f61 !important; }

[type="button"].btn-black,
[type="submit"].btn-black {
  background: #323231;
  color: #fff; }

[type="button"].btn-outline,
[type="submit"].btn-outline {
  border: 1px solid #39b27b !important;
  color: #39b27b !important;
  height: 36px;
  font-size: 14px;
  background: none; }

[type="button"].btn-gray-outline,
[type="submit"].btn-gray-outline {
  border: solid 1px rgba(5, 5, 5, 0.1);
  color: #393939;
  background: none; }

.half-color {
  opacity: 0.5 !important; }

.gray-scale {
  opacity: 0.7; }

/*Checkbox and Radio*/
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  color: #666;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  outline: none;
  cursor: pointer;
  opacity: 0.4; }
  .form-radio[type="radio"] {
    border-radius: 50px; }
  .form-radio[type="checkbox"] {
    border-radius: 2px; }
  .form-radio ~ label {
    font-size: 15px;
    font-weight: 400;
    color: #666;
    cursor: pointer;
    margin: 0;
    vertical-align: top;
    width: calc(100% - 32px);
    margin-left: 6px; }
  .form-radio:checkbox ~ label {
    font-weight: 400; }

.tick-radio {
  display: flex;
  align-items: center; }
  .tick-radio label span {
    color: #7e7d7b;
    font-size: 13px; }
  .tick-radio .form-radio {
    border: 2px solid #c9c8c5;
    height: 20px;
    width: 20px; }
    .tick-radio .form-radio:checked {
      background-color: #0099ff;
      opacity: 1;
      border-color: #0099ff; }
      .tick-radio .form-radio:checked:before {
        position: absolute;
        font: 14px/1 'Material Icons';
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        content: "done";
        color: #fff; }
      .tick-radio .form-radio:checked ~ label {
        color: #0099ff; }

.dot-radio {
  display: flex;
  align-items: center; }
  .dot-radio .form-radio {
    border: 2px solid #c9c8c5;
    height: 15px;
    width: 15px; }
    .dot-radio .form-radio:checked {
      border-color: #0099ff;
      opacity: 1; }
      .dot-radio .form-radio:checked:before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -3.5px;
        content: "";
        color: #fff;
        width: 7px;
        height: 7px;
        background: #0099ff;
        border-radius: 100%; }
      .dot-radio .form-radio:checked ~ label {
        color: #0099ff; }

.divider {
  font-size: 22px;
  line-height: 1;
  margin-top: 19px;
  color: #979694;
  padding: 0 10px; }

.alert-container {
  position: absolute;
  bottom: 0;
  left: 10px;
  z-index: 999; }

.alert {
  max-width: 320px;
  font-size: 15px;
  opacity: 0.95;
  font-weight: 300;
  color: #fff;
  border-radius: 3px;
  margin: 0 0 10px; }
  .alert span {
    font-weight: 500; }
  .alert.alert-uncategorised {
    background: #191919; }
  .alert.alert-info {
    background: #0099ff; }
  .alert.alert-warning {
    background: #f5a623; }
  .alert.alert-danger {
    background: #ff6f61; }
  .alert.alert-sucess {
    background: #39b27b; }

.alert-simple .close {
  position: absolute;
  right: 10px;
  top: 10px; }
  .alert-simple .close i {
    font-size: 15px; }

.alert-simple .modal-content {
  padding: 40px 30px 30px;
  background: #fbfaf6; }

.alert-simple .modal-footer {
  padding: 26px 0 0;
  border: none;
  justify-content: space-between; }

.alert-simple p {
  margin-top: 30px; }

.alert-simple .modal-body .material-icons {
  font-size: 48px;
  color: #0099ff; }

.alert-detelet-message .modal-content {
  padding: 28px 30px 30px;
  background: #fbfaf6; }

.alert-detelet-message .modal-header {
  padding: 0 0 20px;
  border: none; }
  .alert-detelet-message .modal-header .material-icons {
    font-size: 15px; }

.alert-detelet-message p {
  font-size: 14px;
  color: #4b4b4a;
  line-height: 24px;
  margin-bottom: 30px; }

.alert-detelet-message .modal-body {
  padding: 0; }
  .alert-detelet-message .modal-body .message-header > * {
    display: inline-block; }
  .alert-detelet-message .modal-body .message-header img {
    width: 32px;
    height: 32px;
    border-radius: 100%; }
  .alert-detelet-message .modal-body .message-header h6 {
    font-size: 14px;
    color: #323232;
    margin: 0 10px; }
  .alert-detelet-message .modal-body .message-header span {
    font-size: 12px;
    opacity: 0.5;
    color: #323232; }
  .alert-detelet-message .modal-body .user-message {
    background: rgba(255, 111, 97, 0.2);
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 30px; }
  .alert-detelet-message .modal-body .message-body {
    font-size: 14px;
    line-height: 20px;
    padding: 16px 10px;
    background: rgba(255, 255, 255, 0.5);
    margin-top: 10px;
    color: #323232; }

.alert-detelet-message .modal-footer {
  padding: 0;
  border: none;
  justify-content: space-between; }

.alert-detelet-message .group-stats {
  margin-top: 20px; }
  .alert-detelet-message .group-stats .stats-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.2); }
  .alert-detelet-message .group-stats h5 {
    font-weight: 400;
    line-height: 32px; }

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }

@-moz-keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }

@keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }

/*Input Start*/
.date-picker .icon {
  position: absolute;
  right: 0;
  top: 20px;
  pointer-events: none; }
  .date-picker .icon svg {
    height: 18px;
    width: 18px; }

.error {
  color: #ff6f61 !important; }

.success {
  color: #39b27b !important; }

.error, .success {
  margin-top: 8px;
  font-size: 13px;
  line-height: 18px; }

.form-input {
  /* LABEL */
  /* active state */
  /* BOTTOM BARS*/
  /* active state */ }
  .form-input input:focus ~ .icon g, .form-input input:focus ~ .icon #path-section, .form-input input:valid ~ .icon g, .form-input input:valid ~ .icon #path-section {
    fill: #0099ff; }
  .form-input input:invalid ~ .bar:before, .form-input input:invalid ~ .bar:after, .form-input input.invalid ~ .bar:before, .form-input input.invalid ~ .bar:after {
    background: #ff6f61 !important; }
  .form-input input:valid ~ .bar:before, .form-input input:valid ~ .bar:after, .form-input input.valid ~ .bar:before, .form-input input.valid ~ .bar:after {
    background: #39b27b !important; }
  .form-input input.invalid ~ .bar:before, .form-input input.invalid ~ .bar:after {
    background: #ff6f61 !important; }
  .form-input input.valid ~ .bar:before, .form-input input.valid ~ .bar:after {
    background: #39b27b !important; }
  .form-input input {
    padding: 32px 10px 9px 0;
    color: #323231;
    border: none;
    width: 100%;
    border-bottom: 1px solid rgba(50, 50, 49, 0.3);
    background: none;
    font-size: 15px;
    line-height: 24px; }
    .form-input input:focus {
      outline: none; }
  .form-input label {
    color: rgba(50, 50, 49, 0.3);
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 20px;
    line-height: 1.6;
    margin: 0;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    width: 100%;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .form-input input:focus ~ label, .form-input input:not([value=""]) ~ label, .form-input input.flatpickr-input:not([val=""]) ~ label {
    top: -2px;
    font-size: 14px;
    color: #0099ff;
    font-weight: 500; }
  .form-input .bar {
    position: relative;
    display: block;
    width: 100%; }
    .form-input .bar:before, .form-input .bar:after {
      content: '';
      height: 1px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: #0099ff;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all; }
    .form-input .bar:before {
      left: 50%; }
    .form-input .bar:after {
      right: 50%; }
  .form-input input:focus ~ .bar:before, .form-input input:focus ~ .bar:after, .form-input input:valid ~ .bar:before, .form-input input:valid ~ .bar:after {
    width: 50%; }
  .form-input .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0; }
  .form-input input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease; }

.form-textarea {
  /* LABEL */
  /* active state */
  /* BOTTOM BARS*/
  /* active state */ }
  .form-textarea textarea:focus ~ .icon g, .form-textarea textarea:focus ~ .icon #path-section, .form-textarea textarea:valid ~ .icon g, .form-textarea textarea:valid ~ .icon #path-section {
    fill: #0099ff; }
  .form-textarea textarea:invalid ~ .bar:before, .form-textarea textarea:invalid ~ .bar:after, .form-textarea textarea.invalid ~ .bar:before, .form-textarea textarea.invalid ~ .bar:after {
    background: #ff6f61 !important; }
  .form-textarea textarea:valid ~ .bar:before, .form-textarea textarea:valid ~ .bar:after, .form-textarea textarea.valid ~ .bar:before, .form-textarea textarea.valid ~ .bar:after {
    background: #39b27b !important; }
  .form-textarea textarea.invalid ~ .bar:before, .form-textarea textarea.invalid ~ .bar:after {
    background: #ff6f61 !important; }
  .form-textarea textarea.valid ~ .bar:before, .form-textarea textarea.valid ~ .bar:after {
    background: #39b27b !important; }
  .form-textarea textarea {
    margin-top: 25px;
    color: #323231;
    border: none;
    width: 100%;
    background: none;
    font-size: 15px;
    line-height: 24px;
    outline: 0;
    box-shadow: none;
    border-radius: 0;
    box-sizing: content-box;
    overflow: hidden;
    resize: none; }
    .form-textarea textarea:focus {
      outline: none; }
  .form-textarea label {
    color: rgba(50, 50, 49, 0.3);
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 20px;
    line-height: 1.6;
    margin: 0;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    width: 100%;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .form-textarea textarea:focus ~ label, .form-textarea textarea:not(:empty) ~ label {
    top: -2px;
    font-size: 14px;
    color: #0099ff;
    font-weight: 500; }
  .form-textarea .bar {
    position: relative;
    display: block;
    width: 100%; }
    .form-textarea .bar:before, .form-textarea .bar:after {
      content: '';
      height: 1px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: #0099ff;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all; }
    .form-textarea .bar:before {
      left: 50%; }
    .form-textarea .bar:after {
      right: 50%; }
  .form-textarea textarea:focus ~ .bar:before, .form-textarea textarea:focus ~ .bar:after, .form-textarea textarea:valid ~ .bar:before, .form-textarea textarea:valid ~ .bar:after {
    width: 50%; }
  .form-textarea .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0; }
  .form-textarea textarea:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease; }

.select-dropdown.dropdown input ~ label {
  top: auto;
  bottom: 5px;
  font-size: 15px; }

.select-dropdown.dropdown input {
  padding-top: 0; }

.select-dropdown.dropdown .icon {
  top: 7px; }

/*Input End*/
/*Tags and select dropdown*/
.select-dropdown .add-custom-tag .add-tags {
  background: #fff;
  cursor: pointer; }

.add-custom-tag .add-tags {
  background: #eeede9;
  padding: 0 12px 12px;
  border-radius: 2px; }

.add-custom-tag .tag {
  padding: 7px 12px; }
  .add-custom-tag .tag span {
    font-size: 15px; }

.add-tags .tag {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer; }
  .add-tags .tag span {
    color: #323231;
    opacity: 0.8; }
  .add-tags .tag i {
    font-size: 15px;
    opacity: 0.3;
    cursor: pointer; }

.add-tags input {
  background: none;
  border: none;
  color: #323231;
  opacity: 0.8;
  font-size: 15px;
  margin: 18px 0 6px; }
  .add-tags input:focus {
    outline: none; }

.suggested-tag {
  margin-top: 12px; }
  .suggested-tag label {
    color: #323231;
    opacity: 0.5;
    font-size: 12px;
    font-weight: 600; }
  .suggested-tag .tag {
    padding: 6px;
    cursor: pointer; }
    .suggested-tag .tag span {
      font-size: 13px;
      color: rgba(50, 50, 49, 0.7); }

.select-dropdown {
  position: relative; }
  .select-dropdown .dropdown-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    border-top: solid #fbfaf6;
    max-height: 250px;
    overflow-y: auto;
    border-width: 1px 0 0 0;
    width: 100%; }
    .select-dropdown .dropdown-menu .has-input:hover {
      background: #fff; }
    .select-dropdown .dropdown-menu .has-input .form-control {
      background: #eee;
      border: none; }
      .select-dropdown .dropdown-menu .has-input .form-control:focus {
        box-shadow: none; }
    .select-dropdown .dropdown-menu li {
      padding: 8px 12px;
      font-size: 15px;
      line-height: 24px; }
      .select-dropdown .dropdown-menu li:hover {
        background: #eeede9;
        cursor: pointer; }
        .select-dropdown .dropdown-menu li:hover small {
          display: inline-block; }
      .select-dropdown .dropdown-menu li span {
        font-size: 15px; }
      .select-dropdown .dropdown-menu li small {
        display: none;
        font-size: 13px; }

/*Tags and select dropdown*/
.quiz-tip svg {
  margin: 30px auto;
  height: 48px; }

.quiz-tip .form-radio {
  top: 10px;
  right: auto;
  bottom: auto;
  left: 10px;
  position: absolute; }

.radio-tabs {
  padding: 14px;
  flex: 1 1 auto !important; }
  .radio-tabs:not(:last-child) {
    margin-right: 8px; }
  .radio-tabs .form-radio:checked ~ label:after {
    background: #0099ff;
    opacity: 0.05; }
  .radio-tabs .form-radio ~ label:after {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #555;
    z-index: 1;
    opacity: 0.09; }

.react-select-container {
  margin-bottom: 20px; }
  .react-select-container label {
    top: -2px;
    font-size: 14px;
    color: #0099ff;
    font-weight: 500;
    margin-bottom: 6px; }

.react-switch-container {
  margin-top: 35px; }

.dropdown-item.active, .dropdown-item:active {
  background-color: #39b27b !important; }

.page-container {
  background: #fbfaf6;
  min-height: 93vh; }

.page-content {
  padding: 20px 0 0; }

.plain-layout {
  margin-top: -64px !important; }

.site-loader img {
  width: 96px;
  height: 96px; }

.page-content .options > * {
  min-width: 24px;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; }

.page-content .options > a {
  color: #7e7d7b;
  text-decoration: none; }

.page-content .options button {
  height: 32px;
  color: rgba(50, 50, 49, 0.6);
  font-size: 14px; }

.page-content .options .dropdown > a {
  color: #7e7d7b;
  text-decoration: none; }

.page-content .page-title-detail {
  font-style: italic;
  font-size: 14px; }

.page-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  padding: 10px 5px 10px 5px;
  position: relative;
  min-height: 200px; }

.table-wrapper {
  position: relative;
  overflow: auto;
  height: calc(100vh - 300px); }

.page-content .pointer {
  cursor: pointer !important; }

.page-table tr {
  cursor: pointer; }

.page-table th, .page-table td {
  padding: 5px;
  border: none; }

.page-table td {
  padding: 4px 5px;
  border: none;
  vertical-align: middle;
  font-size: 14px; }

.page-table th {
  padding: 10px 5px;
  font-size: 14px;
  font-weight: 500;
  border-top: none; }

.page-table table thead th {
  border-bottom: 2px solid #dee2e6; }

.page-table .table-buttons {
  padding: 1px;
  width: 100px;
  text-align: right; }

.page-table .table-buttons-visible {
  padding: 1px;
  width: 100px;
  text-align: right; }

.page-table .table-buttons button {
  background-color: white; }

.page-table .table-buttons-visible button {
  background-color: white; }

.page-content .collapsible-option {
  max-height: 32px; }

.page-content .align-center {
  text-align: center;
  align-items: center; }

.page-link {
  color: #6c757d; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03); }

.with-icon .icon {
  position: absolute;
  right: 0;
  top: 20px;
  pointer-events: none; }

.alert-container {
  z-index: 1051; }

.flat-tab .media > img {
  width: 42px !important; }

.flat-tab .media.active .media-body {
  border-bottom: none !important;
  padding-bottom: 0 !important; }

.flat-tab .media > img {
  width: 42px !important; }

.flat-tab .media.active .media-body {
  border-bottom: none !important;
  padding-bottom: 0 !important; }

.flat-tab .buyer-stats-table td {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 14px; }
  .flat-tab .buyer-stats-table td:not(:first-child) {
    text-align: right; }
  .flat-tab .buyer-stats-table td .material-icons {
    color: #c9c8c5; }

.flat-tab .nav-tabs {
  display: table;
  width: 100%; }
  .flat-tab .nav-tabs li {
    display: table-cell; }
  .flat-tab .nav-tabs a {
    display: block;
    border: none;
    text-align: center;
    border-bottom: 3px solid #EEEDE9;
    color: #323231;
    padding: 13px 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600; }
    .flat-tab .nav-tabs a:not(.active) {
      background: #EEEDE9; }
    .flat-tab .nav-tabs a.active {
      background: #fbfaf6;
      border-bottom-color: #0099ff; }

.flat-tab {
  position: relative; }
  .flat-tab .nav-item {
    position: relative;
    cursor: pointer; }
    .flat-tab .nav-item .nav-link {
      border-color: #e2e1dd; }
  .flat-tab .count {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #39b27b !important;
    font-size: 9px;
    text-align: center;
    font-weight: 500;
    line-height: 14px;
    border: none;
    color: #fff;
    position: absolute;
    right: 3px;
    top: 3px; }
    .flat-tab .count .percentage {
      background: #5dbe91;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
  .flat-tab .media {
    padding: 16px;
    align-items: flex-start;
    cursor: pointer; }
    .flat-tab .media > img {
      width: 32px; }
    .flat-tab .media.active {
      background: #fbfaf6;
      border-right: 1px solid #eeede9; }
  .flat-tab .media-body {
    margin-left: 20px; }
    .flat-tab .media-body h5 {
      font-size: 15px;
      font-weight: 600;
      color: #323232; }
    .flat-tab .media-body h6 {
      font-size: 14px;
      color: #979694;
      font-weight: 400;
      line-height: 18px;
      margin: 0; }
      .flat-tab .media-body h6 span {
        color: #323232; }
  .flat-tab .announcement-type {
    color: #979694;
    font-size: 12px; }

.txt-low {
  text-transform: lowercase; }

.txt-cap {
  text-transform: capitalize; }

.txt-upp {
  text-transform: uppercase; }

.spinner-container {
  display: flex;
  flex: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #EAEBEC;
  opacity: 0.6;
  z-index: 3; }

.spinner-container img {
  margin: auto; }

/*
table.table-ellipsis {
    table-layout: fixed;
}

.table-ellipsis td  {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
*/
.table.table-ellipsis tbody td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

[data-cell="select"] {
  width: 40px !important;
  text-align: center; }

.table-user-list th {
  text-decoration: underline; }

.table-user-list [data-cell="buttons"] {
  width: 100px !important;
  text-align: center; }

.table-user-list [data-cell="role"] {
  width: 70px !important;
  text-align: center; }

.table-user-list [data-cell="username"] {
  width: 150px !important; }

.table-user-list td[data-cell="username"] {
  text-transform: lowercase; }

.table-user-list [data-cell="email"] {
  width: 200px !important; }

.table-user-list td[data-cell="email"] {
  text-transform: lowercase; }

.table-user-list [data-cell="groups"] {
  width: 150px !important;
  text-align: center; }

.table-user-list [data-cell="name"] {
  width: auto !important;
  text-transform: capitalize; }

.table-user-list [data-cell="surname"] {
  width: auto !important;
  text-transform: capitalize; }

.table-user-list [data-cell="mobile"] {
  width: 120px !important; }

.table-group-list th {
  text-decoration: underline; }

.table-group-list [data-cell="buttons"] {
  width: 100px !important;
  text-align: center; }

.table-group-list [data-cell="user_count"] {
  width: 125px !important;
  text-align: center; }

.table-group-list [data-cell="session_count"] {
  width: 155px !important;
  text-align: center; }

.table-group-list td[data-cell="description"] {
  width: auto !important;
  text-transform: capitalize; }

.table-group-list [data-cell="name"] {
  width: auto !important;
  text-transform: capitalize; }

.table-session-list th {
  text-decoration: underline; }

.table-session-list [data-cell="buttons"] {
  width: 100px !important;
  text-align: center; }

.table-session-list [data-cell="attendee_count"] {
  width: 125px !important;
  text-align: center;
  text-transform: capitalize; }

.table-session-list [data-cell="name"] {
  width: auto !important;
  text-transform: capitalize; }

.table-session-list [data-cell="start_date"] {
  width: 140px !important; }

.table-session-list [data-cell="duration"] {
  width: 100px !important;
  text-transform: capitalize; }

.pull-right {
  float: right; }

.ts-08 {
  transform: scale(0.8); }

.ts-09 {
  transform: scale(0.9); }

.ts-1 {
  transform: scale(1); }

.spinner-container[data-name=login-form-spin] {
  margin-left: -40px;
  margin-top: -40px; }

.add-custom-tag .add-tags {
  background: #eeede9;
  padding: 0 12px 12px;
  border-radius: 2px; }

.add-custom-tag .tag {
  padding: 7px 12px; }
  .add-custom-tag .tag span {
    font-size: 15px; }

.add-custom-tag .tag-full {
  width: 100% !important; }
  .add-custom-tag .tag-full span, .add-custom-tag .tag-full label, .add-custom-tag .tag-full input:not([type='checkbox']), .add-custom-tag .tag-full textarea {
    width: 100% !important; }
  .add-custom-tag .tag-full input, .add-custom-tag .tag-full textarea {
    margin: 0px; }
  .add-custom-tag .tag-full .b-input {
    border: solid 1px #dedede;
    padding: 3px; }

@media (min-width: 950px) {
  .user-browse-modal .modal-dialog {
    max-width: 900px; }
  .import-modal .modal-dialog {
    max-width: 1000px; } }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: none !important;
  background-image: none !important;
  color: #323231 !important; }

body {
  padding-top: 64px; }

.w-90 {
  width: 90%; }

a {
  cursor: pointer !important; }

/*Login Page*/
.wellcome-tag {
  font-family: Arial, Helvetica, sans-serif; }

.login-tag {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; }

.neon {
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 25px;
  color: #c60000;
  -moz-text-shadow: 0 0 20px #ff005b;
  -o-text-shadow: 0 0 20px #ff005b;
  -ms-text-shadow: 0 0 20px #ff005b;
  -webkit-text-shadow: 0 0 20px #ff005b;
  text-shadow: 0 0 20px #ff005b; }

.neon:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 60px;
  z-index: -1;
  color: #ff005b;
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  -webkit-filter: blur(15px);
  filter: blur(15px); }

.neon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff0059;
  z-index: -2;
  opacity: .5;
  -moz-filter: blur(40px);
  -o-filter: blur(40px);
  -ms-filter: blur(40px);
  -webkit-filter: blur(40px);
  filter: blur(40px); }

.login-wrapper {
  background: url(../images/login-bg.jpg) 50% 50%/cover no-repeat; }
  .login-wrapper .min-container {
    max-width: 440px; }
  .login-wrapper .login-block-footer {
    background-color: #fbfaf6;
    padding: 10px 20px;
    border-radius: 0 0 3px 3px; }
    .login-wrapper .login-block-footer span {
      font-size: 13px; }
    .login-wrapper .login-block-footer img {
      height: 30px; }

.login-block {
  background-color: #fbfaf6; }
  .login-block:after {
    -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px; }
  .login-block h1 {
    margin: 40px 0 10px 0; }
  .login-block a {
    font-size: 14px; }
    .login-block a:hover {
      text-decoration: none; }
  .login-block a:not(.forgot) {
    color: #323231; }
    .login-block a:not(.forgot) i {
      vertical-align: middle; }
  .login-block .forgot {
    color: rgba(0, 0, 0, 0.7);
    color: rgba(50, 50, 49, 0.7);
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.2); }
  .login-block .btn-primary {
    background: #39b27b;
    height: 48px;
    padding: 0 24px;
    color: #fff;
    font-size: 16px; }
    .login-block .btn-primary i {
      font-size: 16px;
      margin-left: 20px; }
  .login-block .dropdown {
    position: absolute;
    right: 20px;
    bottom: 20px; }
    .login-block .dropdown .dropdown-menu {
      min-width: 100%;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      border: none; }
      .login-block .dropdown .dropdown-menu a {
        padding: 4px 10px;
        font-size: 13px;
        text-decoration: none; }
    .login-block .dropdown .dropdown-toggle {
      border-radius: 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      background-color: #fff;
      border-color: #fff;
      color: #323231;
      padding: 0 10px;
      height: 40px;
      font-size: 13px; }
      .login-block .dropdown .dropdown-toggle:focus, .login-block .dropdown .dropdown-toggle:active {
        box-shadow: none;
        outline: none; }
      .login-block .dropdown .dropdown-toggle:after {
        border: none;
        margin: 0; }
      .login-block .dropdown .dropdown-toggle .arrow {
        color: #979694; }

.notification {
  width: 320px;
  max-width: 100%; }
  .notification .notification-block {
    border-radius: 3px;
    background-color: #fbfaf6;
    padding: 30px 20px 10px;
    border-top: 5px solid #0099ff; }
    .notification .notification-block:not(:last-child) {
      margin-bottom: 20px; }
    .notification .notification-block h3, .notification .notification-block p {
      margin-bottom: 20px; }
    .notification .notification-block p {
      font-size: 15px; }
    .notification .notification-block ul {
      list-style: none;
      padding: 0;
      margin: 0 -20px; }
      .notification .notification-block ul li {
        font-size: 15px;
        color: #323231;
        padding: 8px 20px 8px 44px;
        cursor: pointer;
        position: relative; }
        .notification .notification-block ul li:hover {
          background: #eeede9; }
          .notification .notification-block ul li:hover:before {
            color: #0099ff; }
        .notification .notification-block ul li:before {
          content: "arrow_forward";
          font-family: 'Material Icons';
          position: absolute;
          left: 16px;
          top: 8px;
          color: #979694; }

.modal-backdrop {
  opacity: 0.6; }

.coypright-modal .modal-dialog, .notifcation-modal .modal-dialog {
  max-width: 600px; }

.coypright-modal .modal-content, .notifcation-modal .modal-content {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.32); }

.coypright-modal .modal-header .close, .notifcation-modal .modal-header .close {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #fff;
  font-weight: 300;
  line-height: 30px; }
  .coypright-modal .modal-header .close:hover, .notifcation-modal .modal-header .close:hover {
    color: #fff; }
  .coypright-modal .modal-header .close:focus, .notifcation-modal .modal-header .close:focus {
    outline: none; }

.notifcation-modal .modal-header {
  padding: 30px 30px 0; }
  .notifcation-modal .modal-header h1 {
    line-height: 1.25;
    letter-spacing: -0.5px; }

.notifcation-modal .notifcation-content {
  padding: 30px 30px 0; }

.notifcation-modal .banner {
  padding-top: 30px; }
  .notifcation-modal .banner img {
    width: 100%; }

.notifcation-modal .modal-body {
  padding: 0; }
  .notifcation-modal .modal-body p {
    margin-bottom: 30px;
    font-size: 15px; }
    .notifcation-modal .modal-body p a {
      color: #0099ff;
      text-decoration: underline;
      text-decoration-color: rgba(0, 153, 255, 0.3); }

.notifcation-modal .media {
  padding: 30px 30px 0; }
  .notifcation-modal .media img {
    width: 36px; }
  .notifcation-modal .media .media-body {
    margin-left: 10px; }
    .notifcation-modal .media .media-body span {
      display: block;
      font-size: 15px;
      color: #4b4b4a;
      font-weight: 500; }
    .notifcation-modal .media .media-body small {
      color: #7e7d7b;
      font-size: 13px; }

.coypright-modal .modal-header {
  padding: 0; }

.coypright-modal .banner {
  padding: 60px 75px;
  background: #fbfaf6; }
  .coypright-modal .banner img {
    width: 450px; }

.coypright-modal .modal-body {
  padding: 40px 30px 14px; }
  .coypright-modal .modal-body p {
    font-size: 15px; }
    .coypright-modal .modal-body p span {
      font-weight: 500; }

.notification-bell, .hide-notification {
  width: 50px;
  height: 50px;
  display: inline-flex;
  cursor: pointer;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
  .notification-bell i, .hide-notification i {
    color: #fff;
    font-size: 20px; }

.notification-bell {
  background: #0099ff;
  z-index: 9; }

.hide-notification {
  background: #ff6f61 !important;
  font-size: 24px;
  color: #fff; }

.active-notification-panel .notification-bell, .active-notification-panel .hide-notification {
  transform: translateX(-305px); }

.active-notification-panel .hide-notification {
  z-index: 9; }

/*Header*/
header {
  background: url(../images/navigation-bg.jpg) no-repeat 50% 50%/cover;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999; }

/*Header End*/
/*Inner Dropdown Menu*/
.top-menu-dropdown {
  background: #fbfaf6;
  position: fixed !important;
  top: 64px !important;
  left: 0 !important;
  right: 0;
  z-index: 999;
  transform: none !important;
  display: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  border: none; }
  .top-menu-dropdown .container-fluid {
    position: relative;
    z-index: 999; }
  .top-menu-dropdown .tab-content {
    background: #fbfaf6; }
  .top-menu-dropdown:after {
    content: "";
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6); }
  .top-menu-dropdown .sidebar-list {
    padding: 0;
    margin: 0;
    list-style: none;
    flex-direction: column; }
    .top-menu-dropdown .sidebar-list > li {
      display: block; }
      .top-menu-dropdown .sidebar-list > li:not(:first-child) {
        margin-top: 0; }
      .top-menu-dropdown .sidebar-list > li .active {
        background: #e2e1dd; }
      .top-menu-dropdown .sidebar-list > li:not(.active):hover {
        background: #eeede9; }
      .top-menu-dropdown .sidebar-list > li a {
        display: block;
        border-radius: 2px;
        display: block;
        padding: 12px 25px;
        font-size: 17px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600; }
        .top-menu-dropdown .sidebar-list > li a .count {
          margin-left: 8px;
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: rgba(0, 0, 0, 0.2);
          font-size: 14px;
          text-align: center;
          font-weight: 400;
          line-height: 20px;
          border: none; }
          .top-menu-dropdown .sidebar-list > li a .count .percentage {
            background: #5dbe91;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1; }
        .top-menu-dropdown .sidebar-list > li a:hover {
          text-decoration: none; }
    .top-menu-dropdown .sidebar-list li.new-notification {
      position: relative; }
      .top-menu-dropdown .sidebar-list li.new-notification:after {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #ff6f61;
        position: absolute;
        left: 10px;
        right: auto;
        top: 20px;
        content: ""; }
  .top-menu-dropdown .side-bar {
    background: #eeede9;
    padding: 32px 16px 32px;
    min-height: 100%; }
    .top-menu-dropdown .side-bar .sidebar-list {
      margin-bottom: 0; }
      .top-menu-dropdown .side-bar .sidebar-list li:first-child {
        margin-top: 0; }

/*Media*/
.lecture-bars {
  padding: 32px 0 20px; }
  .lecture-bars .media {
    margin-bottom: 12px; }
    .lecture-bars .media .material-icons {
      position: absolute;
      right: 12px;
      top: 0; }
  .lecture-bars .media {
    border: 4px solid #fff;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer; }
    .lecture-bars .media.without-image {
      background: rgba(0, 0, 0, 0.05);
      border: none;
      padding: 22px 0;
      box-shadow: none; }
      .lecture-bars .media.without-image .media-body {
        padding-top: 0; }
    .lecture-bars .media img {
      height: 60px; }
    .lecture-bars .media .media-body {
      padding: 7px 0 0 12px;
      position: relative;
      width: calc(100% - 90px); }
    .lecture-bars .media h6 {
      font-size: 15px;
      line-height: 24px;
      margin: 0; }
    .lecture-bars .media span {
      font-size: 13px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.5); }
    .lecture-bars .media .count {
      display: inline-block;
      width: auto;
      height: 20px;
      border-radius: 33px;
      background: rgba(0, 0, 0, 0.1);
      font-size: 14px;
      text-align: center;
      font-weight: 600;
      line-height: 20px;
      border: none;
      padding: 0 6px; }
      .lecture-bars .media .count .percentage {
        background: #5dbe91;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1; }
    .lecture-bars .media .circle {
      text-align: center;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center; }
    .lecture-bars .media .active-border {
      text-align: center;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center; }
    .lecture-bars .media .uploading-file:before {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0px;
      transform: rotate(-180deg); }
    .lecture-bars .media .active-border[data-progress="0"], .lecture-bars .media .uploading-file[data-progress="0"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="1"], .lecture-bars .media .uploading-file[data-progress="1"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="2"], .lecture-bars .media .uploading-file[data-progress="2"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="3"], .lecture-bars .media .uploading-file[data-progress="3"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="4"], .lecture-bars .media .uploading-file[data-progress="4"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="5"], .lecture-bars .media .uploading-file[data-progress="5"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="6"], .lecture-bars .media .uploading-file[data-progress="6"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="7"], .lecture-bars .media .uploading-file[data-progress="7"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="8"], .lecture-bars .media .uploading-file[data-progress="8"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="9"], .lecture-bars .media .uploading-file[data-progress="9"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="10"], .lecture-bars .media .uploading-file[data-progress="10"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="11"], .lecture-bars .media .uploading-file[data-progress="11"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="12"], .lecture-bars .media .uploading-file[data-progress="12"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="13"], .lecture-bars .media .uploading-file[data-progress="13"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="14"], .lecture-bars .media .uploading-file[data-progress="14"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="15"], .lecture-bars .media .uploading-file[data-progress="15"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="16"], .lecture-bars .media .uploading-file[data-progress="16"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="17"], .lecture-bars .media .uploading-file[data-progress="17"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="18"], .lecture-bars .media .uploading-file[data-progress="18"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="19"], .lecture-bars .media .uploading-file[data-progress="19"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="20"], .lecture-bars .media .uploading-file[data-progress="20"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="21"], .lecture-bars .media .uploading-file[data-progress="21"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="22"], .lecture-bars .media .uploading-file[data-progress="22"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="23"], .lecture-bars .media .uploading-file[data-progress="23"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="24"], .lecture-bars .media .uploading-file[data-progress="24"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="25"], .lecture-bars .media .uploading-file[data-progress="25"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="26"], .lecture-bars .media .uploading-file[data-progress="26"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="27"], .lecture-bars .media .uploading-file[data-progress="27"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="28"], .lecture-bars .media .uploading-file[data-progress="28"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="29"], .lecture-bars .media .uploading-file[data-progress="29"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="30"], .lecture-bars .media .uploading-file[data-progress="30"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="31"], .lecture-bars .media .uploading-file[data-progress="31"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="32"], .lecture-bars .media .uploading-file[data-progress="32"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="33"], .lecture-bars .media .uploading-file[data-progress="33"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="34"], .lecture-bars .media .uploading-file[data-progress="34"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="35"], .lecture-bars .media .uploading-file[data-progress="35"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="36"], .lecture-bars .media .uploading-file[data-progress="36"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="37"], .lecture-bars .media .uploading-file[data-progress="37"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="38"], .lecture-bars .media .uploading-file[data-progress="38"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="39"], .lecture-bars .media .uploading-file[data-progress="39"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="40"], .lecture-bars .media .uploading-file[data-progress="40"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="41"], .lecture-bars .media .uploading-file[data-progress="41"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="42"], .lecture-bars .media .uploading-file[data-progress="42"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="43"], .lecture-bars .media .uploading-file[data-progress="43"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="44"], .lecture-bars .media .uploading-file[data-progress="44"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="45"], .lecture-bars .media .uploading-file[data-progress="45"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="46"], .lecture-bars .media .uploading-file[data-progress="46"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="47"], .lecture-bars .media .uploading-file[data-progress="47"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="48"], .lecture-bars .media .uploading-file[data-progress="48"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="49"], .lecture-bars .media .uploading-file[data-progress="49"]:before {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="50"], .lecture-bars .media .uploading-file[data-progress="50"]:before {
      background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="51"], .lecture-bars .media .uploading-file[data-progress="51"]:before {
      background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="52"], .lecture-bars .media .uploading-file[data-progress="52"]:before {
      background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="53"], .lecture-bars .media .uploading-file[data-progress="53"]:before {
      background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="54"], .lecture-bars .media .uploading-file[data-progress="54"]:before {
      background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="55"], .lecture-bars .media .uploading-file[data-progress="55"]:before {
      background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="56"], .lecture-bars .media .uploading-file[data-progress="56"]:before {
      background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="57"], .lecture-bars .media .uploading-file[data-progress="57"]:before {
      background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="58"], .lecture-bars .media .uploading-file[data-progress="58"]:before {
      background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="59"], .lecture-bars .media .uploading-file[data-progress="59"]:before {
      background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="60"], .lecture-bars .media .uploading-file[data-progress="60"]:before {
      background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="61"], .lecture-bars .media .uploading-file[data-progress="61"]:before {
      background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="62"], .lecture-bars .media .uploading-file[data-progress="62"]:before {
      background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="63"], .lecture-bars .media .uploading-file[data-progress="63"]:before {
      background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="64"], .lecture-bars .media .uploading-file[data-progress="64"]:before {
      background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="65"], .lecture-bars .media .uploading-file[data-progress="65"]:before {
      background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="66"], .lecture-bars .media .uploading-file[data-progress="66"]:before {
      background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="67"], .lecture-bars .media .uploading-file[data-progress="67"]:before {
      background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="68"], .lecture-bars .media .uploading-file[data-progress="68"]:before {
      background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="69"], .lecture-bars .media .uploading-file[data-progress="69"]:before {
      background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="70"], .lecture-bars .media .uploading-file[data-progress="70"]:before {
      background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="71"], .lecture-bars .media .uploading-file[data-progress="71"]:before {
      background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="72"], .lecture-bars .media .uploading-file[data-progress="72"]:before {
      background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="73"], .lecture-bars .media .uploading-file[data-progress="73"]:before {
      background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="74"], .lecture-bars .media .uploading-file[data-progress="74"]:before {
      background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="75"], .lecture-bars .media .uploading-file[data-progress="75"]:before {
      background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="76"], .lecture-bars .media .uploading-file[data-progress="76"]:before {
      background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="77"], .lecture-bars .media .uploading-file[data-progress="77"]:before {
      background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="78"], .lecture-bars .media .uploading-file[data-progress="78"]:before {
      background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="79"], .lecture-bars .media .uploading-file[data-progress="79"]:before {
      background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="80"], .lecture-bars .media .uploading-file[data-progress="80"]:before {
      background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="81"], .lecture-bars .media .uploading-file[data-progress="81"]:before {
      background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="82"], .lecture-bars .media .uploading-file[data-progress="82"]:before {
      background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="83"], .lecture-bars .media .uploading-file[data-progress="83"]:before {
      background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="84"], .lecture-bars .media .uploading-file[data-progress="84"]:before {
      background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="85"], .lecture-bars .media .uploading-file[data-progress="85"]:before {
      background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="86"], .lecture-bars .media .uploading-file[data-progress="86"]:before {
      background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="87"], .lecture-bars .media .uploading-file[data-progress="87"]:before {
      background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="88"], .lecture-bars .media .uploading-file[data-progress="88"]:before {
      background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="89"], .lecture-bars .media .uploading-file[data-progress="89"]:before {
      background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="90"], .lecture-bars .media .uploading-file[data-progress="90"]:before {
      background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="91"], .lecture-bars .media .uploading-file[data-progress="91"]:before {
      background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="92"], .lecture-bars .media .uploading-file[data-progress="92"]:before {
      background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="93"], .lecture-bars .media .uploading-file[data-progress="93"]:before {
      background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="94"], .lecture-bars .media .uploading-file[data-progress="94"]:before {
      background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="95"], .lecture-bars .media .uploading-file[data-progress="95"]:before {
      background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="96"], .lecture-bars .media .uploading-file[data-progress="96"]:before {
      background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="97"], .lecture-bars .media .uploading-file[data-progress="97"]:before {
      background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="98"], .lecture-bars .media .uploading-file[data-progress="98"]:before {
      background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="99"], .lecture-bars .media .uploading-file[data-progress="99"]:before {
      background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
    .lecture-bars .media .active-border[data-progress="100"], .lecture-bars .media .uploading-file[data-progress="100"]:before {
      background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .lecture-bars .active-border {
    position: absolute;
    right: 8px;
    top: 13px; }

.carousel-slide .circle span, .lesson-header .circle span {
  position: relative; }

.carousel-slide .circle .number, .lesson-header .circle .number {
  left: 4px;
  top: 5px; }

.carousel-slide .circle .slash, .lesson-header .circle .slash {
  opacity: 0.3;
  font-size: 25px;
  line-height: 32px;
  left: 2px;
  top: -4px; }

.carousel-slide .circle .small-number, .lesson-header .circle .small-number {
  position: relative;
  left: 0;
  top: -7px;
  font-size: 12px; }

.carousel-slide .lesson-counter, .lesson-header .lesson-counter {
  text-align: center; }
  .carousel-slide .lesson-counter .counter-label, .lesson-header .lesson-counter .counter-label {
    font-size: 11px;
    font-weight: 600;
    color: rgba(50, 50, 49, 0.3);
    line-height: 20px; }
  .carousel-slide .lesson-counter .active-border, .lesson-header .lesson-counter .active-border {
    margin: 0; }

.carousel-slide .text-danger, .lesson-header .text-danger {
  color: #ff6f61 !important; }

.carousel-slide .text-success, .lesson-header .text-success {
  color: #39b27b !important; }

.carousel-slide .counter-point, .lesson-header .counter-point {
  font-size: 12px; }

.carousel-slide .circle, .lesson-header .circle {
  text-align: center;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: #eeede9;
  display: flex;
  justify-content: center;
  align-items: center; }

.carousel-slide .active-border, .lesson-header .active-border {
  text-align: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }

.carousel-slide .uploading-file:before, .lesson-header .uploading-file:before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0px;
  transform: rotate(-180deg); }

.carousel-slide .active-border[data-progress="0"], .carousel-slide .uploading-file[data-progress="0"]:before, .lesson-header .active-border[data-progress="0"], .lesson-header .uploading-file[data-progress="0"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="1"], .carousel-slide .uploading-file[data-progress="1"]:before, .lesson-header .active-border[data-progress="1"], .lesson-header .uploading-file[data-progress="1"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="2"], .carousel-slide .uploading-file[data-progress="2"]:before, .lesson-header .active-border[data-progress="2"], .lesson-header .uploading-file[data-progress="2"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="3"], .carousel-slide .uploading-file[data-progress="3"]:before, .lesson-header .active-border[data-progress="3"], .lesson-header .uploading-file[data-progress="3"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="4"], .carousel-slide .uploading-file[data-progress="4"]:before, .lesson-header .active-border[data-progress="4"], .lesson-header .uploading-file[data-progress="4"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="5"], .carousel-slide .uploading-file[data-progress="5"]:before, .lesson-header .active-border[data-progress="5"], .lesson-header .uploading-file[data-progress="5"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="6"], .carousel-slide .uploading-file[data-progress="6"]:before, .lesson-header .active-border[data-progress="6"], .lesson-header .uploading-file[data-progress="6"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="7"], .carousel-slide .uploading-file[data-progress="7"]:before, .lesson-header .active-border[data-progress="7"], .lesson-header .uploading-file[data-progress="7"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="8"], .carousel-slide .uploading-file[data-progress="8"]:before, .lesson-header .active-border[data-progress="8"], .lesson-header .uploading-file[data-progress="8"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="9"], .carousel-slide .uploading-file[data-progress="9"]:before, .lesson-header .active-border[data-progress="9"], .lesson-header .uploading-file[data-progress="9"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="10"], .carousel-slide .uploading-file[data-progress="10"]:before, .lesson-header .active-border[data-progress="10"], .lesson-header .uploading-file[data-progress="10"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="11"], .carousel-slide .uploading-file[data-progress="11"]:before, .lesson-header .active-border[data-progress="11"], .lesson-header .uploading-file[data-progress="11"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="12"], .carousel-slide .uploading-file[data-progress="12"]:before, .lesson-header .active-border[data-progress="12"], .lesson-header .uploading-file[data-progress="12"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="13"], .carousel-slide .uploading-file[data-progress="13"]:before, .lesson-header .active-border[data-progress="13"], .lesson-header .uploading-file[data-progress="13"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="14"], .carousel-slide .uploading-file[data-progress="14"]:before, .lesson-header .active-border[data-progress="14"], .lesson-header .uploading-file[data-progress="14"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="15"], .carousel-slide .uploading-file[data-progress="15"]:before, .lesson-header .active-border[data-progress="15"], .lesson-header .uploading-file[data-progress="15"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="16"], .carousel-slide .uploading-file[data-progress="16"]:before, .lesson-header .active-border[data-progress="16"], .lesson-header .uploading-file[data-progress="16"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="17"], .carousel-slide .uploading-file[data-progress="17"]:before, .lesson-header .active-border[data-progress="17"], .lesson-header .uploading-file[data-progress="17"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="18"], .carousel-slide .uploading-file[data-progress="18"]:before, .lesson-header .active-border[data-progress="18"], .lesson-header .uploading-file[data-progress="18"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="19"], .carousel-slide .uploading-file[data-progress="19"]:before, .lesson-header .active-border[data-progress="19"], .lesson-header .uploading-file[data-progress="19"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="20"], .carousel-slide .uploading-file[data-progress="20"]:before, .lesson-header .active-border[data-progress="20"], .lesson-header .uploading-file[data-progress="20"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="21"], .carousel-slide .uploading-file[data-progress="21"]:before, .lesson-header .active-border[data-progress="21"], .lesson-header .uploading-file[data-progress="21"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="22"], .carousel-slide .uploading-file[data-progress="22"]:before, .lesson-header .active-border[data-progress="22"], .lesson-header .uploading-file[data-progress="22"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="23"], .carousel-slide .uploading-file[data-progress="23"]:before, .lesson-header .active-border[data-progress="23"], .lesson-header .uploading-file[data-progress="23"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="24"], .carousel-slide .uploading-file[data-progress="24"]:before, .lesson-header .active-border[data-progress="24"], .lesson-header .uploading-file[data-progress="24"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="25"], .carousel-slide .uploading-file[data-progress="25"]:before, .lesson-header .active-border[data-progress="25"], .lesson-header .uploading-file[data-progress="25"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="26"], .carousel-slide .uploading-file[data-progress="26"]:before, .lesson-header .active-border[data-progress="26"], .lesson-header .uploading-file[data-progress="26"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="27"], .carousel-slide .uploading-file[data-progress="27"]:before, .lesson-header .active-border[data-progress="27"], .lesson-header .uploading-file[data-progress="27"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="28"], .carousel-slide .uploading-file[data-progress="28"]:before, .lesson-header .active-border[data-progress="28"], .lesson-header .uploading-file[data-progress="28"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="29"], .carousel-slide .uploading-file[data-progress="29"]:before, .lesson-header .active-border[data-progress="29"], .lesson-header .uploading-file[data-progress="29"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="30"], .carousel-slide .uploading-file[data-progress="30"]:before, .lesson-header .active-border[data-progress="30"], .lesson-header .uploading-file[data-progress="30"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="31"], .carousel-slide .uploading-file[data-progress="31"]:before, .lesson-header .active-border[data-progress="31"], .lesson-header .uploading-file[data-progress="31"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="32"], .carousel-slide .uploading-file[data-progress="32"]:before, .lesson-header .active-border[data-progress="32"], .lesson-header .uploading-file[data-progress="32"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="33"], .carousel-slide .uploading-file[data-progress="33"]:before, .lesson-header .active-border[data-progress="33"], .lesson-header .uploading-file[data-progress="33"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="34"], .carousel-slide .uploading-file[data-progress="34"]:before, .lesson-header .active-border[data-progress="34"], .lesson-header .uploading-file[data-progress="34"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="35"], .carousel-slide .uploading-file[data-progress="35"]:before, .lesson-header .active-border[data-progress="35"], .lesson-header .uploading-file[data-progress="35"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="36"], .carousel-slide .uploading-file[data-progress="36"]:before, .lesson-header .active-border[data-progress="36"], .lesson-header .uploading-file[data-progress="36"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="37"], .carousel-slide .uploading-file[data-progress="37"]:before, .lesson-header .active-border[data-progress="37"], .lesson-header .uploading-file[data-progress="37"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="38"], .carousel-slide .uploading-file[data-progress="38"]:before, .lesson-header .active-border[data-progress="38"], .lesson-header .uploading-file[data-progress="38"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="39"], .carousel-slide .uploading-file[data-progress="39"]:before, .lesson-header .active-border[data-progress="39"], .lesson-header .uploading-file[data-progress="39"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="40"], .carousel-slide .uploading-file[data-progress="40"]:before, .lesson-header .active-border[data-progress="40"], .lesson-header .uploading-file[data-progress="40"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="41"], .carousel-slide .uploading-file[data-progress="41"]:before, .lesson-header .active-border[data-progress="41"], .lesson-header .uploading-file[data-progress="41"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="42"], .carousel-slide .uploading-file[data-progress="42"]:before, .lesson-header .active-border[data-progress="42"], .lesson-header .uploading-file[data-progress="42"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="43"], .carousel-slide .uploading-file[data-progress="43"]:before, .lesson-header .active-border[data-progress="43"], .lesson-header .uploading-file[data-progress="43"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="44"], .carousel-slide .uploading-file[data-progress="44"]:before, .lesson-header .active-border[data-progress="44"], .lesson-header .uploading-file[data-progress="44"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="45"], .carousel-slide .uploading-file[data-progress="45"]:before, .lesson-header .active-border[data-progress="45"], .lesson-header .uploading-file[data-progress="45"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="46"], .carousel-slide .uploading-file[data-progress="46"]:before, .lesson-header .active-border[data-progress="46"], .lesson-header .uploading-file[data-progress="46"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="47"], .carousel-slide .uploading-file[data-progress="47"]:before, .lesson-header .active-border[data-progress="47"], .lesson-header .uploading-file[data-progress="47"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="48"], .carousel-slide .uploading-file[data-progress="48"]:before, .lesson-header .active-border[data-progress="48"], .lesson-header .uploading-file[data-progress="48"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="49"], .carousel-slide .uploading-file[data-progress="49"]:before, .lesson-header .active-border[data-progress="49"], .lesson-header .uploading-file[data-progress="49"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="50"], .carousel-slide .uploading-file[data-progress="50"]:before, .lesson-header .active-border[data-progress="50"], .lesson-header .uploading-file[data-progress="50"]:before {
  background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="51"], .carousel-slide .uploading-file[data-progress="51"]:before, .lesson-header .active-border[data-progress="51"], .lesson-header .uploading-file[data-progress="51"]:before {
  background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="52"], .carousel-slide .uploading-file[data-progress="52"]:before, .lesson-header .active-border[data-progress="52"], .lesson-header .uploading-file[data-progress="52"]:before {
  background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="53"], .carousel-slide .uploading-file[data-progress="53"]:before, .lesson-header .active-border[data-progress="53"], .lesson-header .uploading-file[data-progress="53"]:before {
  background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="54"], .carousel-slide .uploading-file[data-progress="54"]:before, .lesson-header .active-border[data-progress="54"], .lesson-header .uploading-file[data-progress="54"]:before {
  background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="55"], .carousel-slide .uploading-file[data-progress="55"]:before, .lesson-header .active-border[data-progress="55"], .lesson-header .uploading-file[data-progress="55"]:before {
  background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="56"], .carousel-slide .uploading-file[data-progress="56"]:before, .lesson-header .active-border[data-progress="56"], .lesson-header .uploading-file[data-progress="56"]:before {
  background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="57"], .carousel-slide .uploading-file[data-progress="57"]:before, .lesson-header .active-border[data-progress="57"], .lesson-header .uploading-file[data-progress="57"]:before {
  background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="58"], .carousel-slide .uploading-file[data-progress="58"]:before, .lesson-header .active-border[data-progress="58"], .lesson-header .uploading-file[data-progress="58"]:before {
  background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="59"], .carousel-slide .uploading-file[data-progress="59"]:before, .lesson-header .active-border[data-progress="59"], .lesson-header .uploading-file[data-progress="59"]:before {
  background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="60"], .carousel-slide .uploading-file[data-progress="60"]:before, .lesson-header .active-border[data-progress="60"], .lesson-header .uploading-file[data-progress="60"]:before {
  background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="61"], .carousel-slide .uploading-file[data-progress="61"]:before, .lesson-header .active-border[data-progress="61"], .lesson-header .uploading-file[data-progress="61"]:before {
  background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="62"], .carousel-slide .uploading-file[data-progress="62"]:before, .lesson-header .active-border[data-progress="62"], .lesson-header .uploading-file[data-progress="62"]:before {
  background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="63"], .carousel-slide .uploading-file[data-progress="63"]:before, .lesson-header .active-border[data-progress="63"], .lesson-header .uploading-file[data-progress="63"]:before {
  background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="64"], .carousel-slide .uploading-file[data-progress="64"]:before, .lesson-header .active-border[data-progress="64"], .lesson-header .uploading-file[data-progress="64"]:before {
  background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="65"], .carousel-slide .uploading-file[data-progress="65"]:before, .lesson-header .active-border[data-progress="65"], .lesson-header .uploading-file[data-progress="65"]:before {
  background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="66"], .carousel-slide .uploading-file[data-progress="66"]:before, .lesson-header .active-border[data-progress="66"], .lesson-header .uploading-file[data-progress="66"]:before {
  background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="67"], .carousel-slide .uploading-file[data-progress="67"]:before, .lesson-header .active-border[data-progress="67"], .lesson-header .uploading-file[data-progress="67"]:before {
  background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="68"], .carousel-slide .uploading-file[data-progress="68"]:before, .lesson-header .active-border[data-progress="68"], .lesson-header .uploading-file[data-progress="68"]:before {
  background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="69"], .carousel-slide .uploading-file[data-progress="69"]:before, .lesson-header .active-border[data-progress="69"], .lesson-header .uploading-file[data-progress="69"]:before {
  background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="70"], .carousel-slide .uploading-file[data-progress="70"]:before, .lesson-header .active-border[data-progress="70"], .lesson-header .uploading-file[data-progress="70"]:before {
  background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="71"], .carousel-slide .uploading-file[data-progress="71"]:before, .lesson-header .active-border[data-progress="71"], .lesson-header .uploading-file[data-progress="71"]:before {
  background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="72"], .carousel-slide .uploading-file[data-progress="72"]:before, .lesson-header .active-border[data-progress="72"], .lesson-header .uploading-file[data-progress="72"]:before {
  background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="73"], .carousel-slide .uploading-file[data-progress="73"]:before, .lesson-header .active-border[data-progress="73"], .lesson-header .uploading-file[data-progress="73"]:before {
  background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="74"], .carousel-slide .uploading-file[data-progress="74"]:before, .lesson-header .active-border[data-progress="74"], .lesson-header .uploading-file[data-progress="74"]:before {
  background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="75"], .carousel-slide .uploading-file[data-progress="75"]:before, .lesson-header .active-border[data-progress="75"], .lesson-header .uploading-file[data-progress="75"]:before {
  background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="76"], .carousel-slide .uploading-file[data-progress="76"]:before, .lesson-header .active-border[data-progress="76"], .lesson-header .uploading-file[data-progress="76"]:before {
  background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="77"], .carousel-slide .uploading-file[data-progress="77"]:before, .lesson-header .active-border[data-progress="77"], .lesson-header .uploading-file[data-progress="77"]:before {
  background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="78"], .carousel-slide .uploading-file[data-progress="78"]:before, .lesson-header .active-border[data-progress="78"], .lesson-header .uploading-file[data-progress="78"]:before {
  background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="79"], .carousel-slide .uploading-file[data-progress="79"]:before, .lesson-header .active-border[data-progress="79"], .lesson-header .uploading-file[data-progress="79"]:before {
  background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="80"], .carousel-slide .uploading-file[data-progress="80"]:before, .lesson-header .active-border[data-progress="80"], .lesson-header .uploading-file[data-progress="80"]:before {
  background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="81"], .carousel-slide .uploading-file[data-progress="81"]:before, .lesson-header .active-border[data-progress="81"], .lesson-header .uploading-file[data-progress="81"]:before {
  background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="82"], .carousel-slide .uploading-file[data-progress="82"]:before, .lesson-header .active-border[data-progress="82"], .lesson-header .uploading-file[data-progress="82"]:before {
  background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="83"], .carousel-slide .uploading-file[data-progress="83"]:before, .lesson-header .active-border[data-progress="83"], .lesson-header .uploading-file[data-progress="83"]:before {
  background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="84"], .carousel-slide .uploading-file[data-progress="84"]:before, .lesson-header .active-border[data-progress="84"], .lesson-header .uploading-file[data-progress="84"]:before {
  background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="85"], .carousel-slide .uploading-file[data-progress="85"]:before, .lesson-header .active-border[data-progress="85"], .lesson-header .uploading-file[data-progress="85"]:before {
  background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="86"], .carousel-slide .uploading-file[data-progress="86"]:before, .lesson-header .active-border[data-progress="86"], .lesson-header .uploading-file[data-progress="86"]:before {
  background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="87"], .carousel-slide .uploading-file[data-progress="87"]:before, .lesson-header .active-border[data-progress="87"], .lesson-header .uploading-file[data-progress="87"]:before {
  background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="88"], .carousel-slide .uploading-file[data-progress="88"]:before, .lesson-header .active-border[data-progress="88"], .lesson-header .uploading-file[data-progress="88"]:before {
  background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="89"], .carousel-slide .uploading-file[data-progress="89"]:before, .lesson-header .active-border[data-progress="89"], .lesson-header .uploading-file[data-progress="89"]:before {
  background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="90"], .carousel-slide .uploading-file[data-progress="90"]:before, .lesson-header .active-border[data-progress="90"], .lesson-header .uploading-file[data-progress="90"]:before {
  background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="91"], .carousel-slide .uploading-file[data-progress="91"]:before, .lesson-header .active-border[data-progress="91"], .lesson-header .uploading-file[data-progress="91"]:before {
  background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="92"], .carousel-slide .uploading-file[data-progress="92"]:before, .lesson-header .active-border[data-progress="92"], .lesson-header .uploading-file[data-progress="92"]:before {
  background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="93"], .carousel-slide .uploading-file[data-progress="93"]:before, .lesson-header .active-border[data-progress="93"], .lesson-header .uploading-file[data-progress="93"]:before {
  background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="94"], .carousel-slide .uploading-file[data-progress="94"]:before, .lesson-header .active-border[data-progress="94"], .lesson-header .uploading-file[data-progress="94"]:before {
  background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="95"], .carousel-slide .uploading-file[data-progress="95"]:before, .lesson-header .active-border[data-progress="95"], .lesson-header .uploading-file[data-progress="95"]:before {
  background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="96"], .carousel-slide .uploading-file[data-progress="96"]:before, .lesson-header .active-border[data-progress="96"], .lesson-header .uploading-file[data-progress="96"]:before {
  background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="97"], .carousel-slide .uploading-file[data-progress="97"]:before, .lesson-header .active-border[data-progress="97"], .lesson-header .uploading-file[data-progress="97"]:before {
  background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="98"], .carousel-slide .uploading-file[data-progress="98"]:before, .lesson-header .active-border[data-progress="98"], .lesson-header .uploading-file[data-progress="98"]:before {
  background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="99"], .carousel-slide .uploading-file[data-progress="99"]:before, .lesson-header .active-border[data-progress="99"], .lesson-header .uploading-file[data-progress="99"]:before {
  background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.carousel-slide .active-border[data-progress="100"], .carousel-slide .uploading-file[data-progress="100"]:before, .lesson-header .active-border[data-progress="100"], .lesson-header .uploading-file[data-progress="100"]:before {
  background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

/*Lesson homepage*/
.lesson-header {
  background: #eeede9;
  padding: 30px 0 23px; }
  .lesson-header .media-body span:not(.count) {
    color: rgba(50, 50, 49, 0.3);
    font-weight: 600;
    font-size: 12px; }
  .lesson-header .media-body a {
    color: rgba(50, 50, 49, 0.7);
    font-size: 13px;
    display: inline-block;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid #e2e1dd; }
  .lesson-header .media-body .count {
    display: inline-block;
    width: auto;
    height: 20px;
    border-radius: 2px;
    background: #e2e1dd;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 20px;
    border: none;
    padding: 0 6px;
    color: rgba(50, 50, 49, 0.7); }
    .lesson-header .media-body .count .percentage {
      background: #5dbe91;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }

.studen-tabs .tab-content {
  background: #fbfaf6; }

.lesson-tabs .tab-header {
  background: #eeede9; }
  .lesson-tabs .tab-header .nav a {
    line-height: 32px;
    display: block;
    color: rgba(50, 50, 49, 0.8);
    font-weight: 500;
    padding: 0;
    border: none; }
    .lesson-tabs .tab-header .nav a:hover {
      border: none; }
  .lesson-tabs .tab-header .material-icons {
    vertical-align: middle; }
  .lesson-tabs .tab-header li.new-notification {
    position: relative; }
    .lesson-tabs .tab-header li.new-notification:after {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #ff6f61;
      position: absolute;
      left: auto;
      right: 0;
      top: 0;
      content: ""; }
  .lesson-tabs .tab-header a.active {
    background: none;
    border: none;
    position: relative; }

.lesson-tabs .tab-content {
  padding-top: 16px; }

/*Notification bar*/
.notification-bar {
  background: #fce9ab;
  border-radius: 2px;
  padding: 20px 26px; }
  .notification-bar p {
    margin: 0;
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    line-height: 20px; }
    .notification-bar p span {
      font-weight: 600; }
    .notification-bar p a {
      color: rgba(0, 0, 0, 0.7);
      text-decoration: none; }
  .notification-bar img {
    margin-right: 14px; }

/*Content box*/
.content-box.shot-content {
  position: relative; }
  .content-box.shot-content:after {
    background: linear-gradient(-180deg, rgba(251, 250, 246, 0) 0%, #FBFAF6 100%);
    content: "";
    width: 100%;
    height: 60px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0; }

.content-box h2 {
  margin-bottom: 20px; }

.content-box p {
  margin-bottom: 0;
  opacity: 0.8; }
  .content-box p:nth-last-of-type(1) {
    margin-bottom: 0; }

.content-box .arrow-list li {
  padding-left: 32px;
  list-style: none;
  position: relative; }
  .content-box .arrow-list li:before {
    content: "arrow_forward";
    font-family: 'Material Icons';
    position: absolute;
    left: 0;
    color: rgba(0, 0, 0, 0.5); }

.this-week-activity {
  margin-top: 50px;
  background: #fff;
  padding: 26px 30px 35px 0;
  position: relative;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)); }
  .this-week-activity:after {
    position: absolute;
    content: "";
    width: 1000%;
    top: 0;
    bottom: 0;
    border-radius: 2px;
    box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.08);
    background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
    right: 100%; }
  .this-week-activity .text-success span {
    display: block; }
  .this-week-activity .text-success span:not(.number) {
    font-size: 12px; }
  .this-week-activity .text-success .number {
    font-weight: 300;
    font-size: 48px;
    line-height: 48px; }
  .this-week-activity .media-body {
    margin-left: 30px; }
    .this-week-activity .media-body .date {
      color: rgba(33, 37, 41, 0.3);
      font-size: 12px;
      font-weight: 600;
      line-height: 20px; }
    .this-week-activity .media-body h4 {
      margin: 10px 0; }
    .this-week-activity .media-body p {
      color: rgba(33, 37, 41, 0.8);
      line-height: 24px; }

.week-points .points, .upcomming-events .points, .announcements .points {
  margin-top: 20px; }

.week-points, .debates-section, .upcomming-events, .announcements {
  margin-top: 30px; }
  .week-points .count, .debates-section .count, .upcomming-events .count, .announcements .count {
    display: inline-block;
    width: 21px;
    height: 20px;
    border-radius: 100%;
    background: #eeede9;
    font-size: 13px;
    text-align: center;
    font-weight: 400;
    line-height: 20px;
    border: none; }
    .week-points .count .percentage, .debates-section .count .percentage, .upcomming-events .count .percentage, .announcements .count .percentage {
      background: #5dbe91;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
  .week-points h5, .debates-section h5, .upcomming-events h5, .announcements h5 {
    font-weight: 500;
    color: #323231;
    line-height: 32px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeede9;
    margin-bottom: 0;
    display: flex;
    align-items: center; }
    .week-points h5 a, .debates-section h5 a, .upcomming-events h5 a, .announcements h5 a {
      color: #323231;
      text-decoration: none; }
  .week-points .points > div:not(.circle):not(.active-border), .debates-section .points > div:not(.circle):not(.active-border), .upcomming-events .points > div:not(.circle):not(.active-border), .announcements .points > div:not(.circle):not(.active-border) {
    margin-left: 24px; }
  .week-points .points h6, .debates-section .points h6, .upcomming-events .points h6, .announcements .points h6 {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    margin: 0 0 4px; }
  .week-points .points span, .debates-section .points span, .upcomming-events .points span, .announcements .points span {
    color: #b0afac;
    font-size: 14px; }

.debates-section .points {
  position: relative;
  margin-top: 18px; }
  .debates-section .points.new-notification {
    position: relative; }
    .debates-section .points.new-notification:after {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #ff6f61;
      position: absolute;
      left: -18px;
      right: auto;
      top: 22px;
      content: ""; }
  .debates-section .points > div:not(.circle) {
    width: 100%; }
    .debates-section .points > div:not(.circle) span {
      display: block;
      padding: 0 0 18px;
      border-bottom: 1px solid  #eeede9; }

.debates-section img {
  width: 48px;
  height: 48px;
  border-radius: 100%; }

.announcements .date {
  color: #b0afac;
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-top: 4px; }

.announcements span {
  font-size: 15px;
  color: #7e7d7b !important;
  display: block; }

.week-points .circle {
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }

.week-points .active-border {
  text-align: center;
  width: 0;
  height: 0;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }

.week-points .uploading-file:before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0px;
  transform: rotate(-180deg); }

.week-points .active-border[data-progress="0"], .week-points .uploading-file[data-progress="0"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="1"], .week-points .uploading-file[data-progress="1"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="2"], .week-points .uploading-file[data-progress="2"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="3"], .week-points .uploading-file[data-progress="3"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="4"], .week-points .uploading-file[data-progress="4"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="5"], .week-points .uploading-file[data-progress="5"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="6"], .week-points .uploading-file[data-progress="6"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="7"], .week-points .uploading-file[data-progress="7"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="8"], .week-points .uploading-file[data-progress="8"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="9"], .week-points .uploading-file[data-progress="9"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="10"], .week-points .uploading-file[data-progress="10"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="11"], .week-points .uploading-file[data-progress="11"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="12"], .week-points .uploading-file[data-progress="12"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="13"], .week-points .uploading-file[data-progress="13"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="14"], .week-points .uploading-file[data-progress="14"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="15"], .week-points .uploading-file[data-progress="15"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="16"], .week-points .uploading-file[data-progress="16"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="17"], .week-points .uploading-file[data-progress="17"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="18"], .week-points .uploading-file[data-progress="18"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="19"], .week-points .uploading-file[data-progress="19"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="20"], .week-points .uploading-file[data-progress="20"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="21"], .week-points .uploading-file[data-progress="21"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="22"], .week-points .uploading-file[data-progress="22"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="23"], .week-points .uploading-file[data-progress="23"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="24"], .week-points .uploading-file[data-progress="24"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="25"], .week-points .uploading-file[data-progress="25"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="26"], .week-points .uploading-file[data-progress="26"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="27"], .week-points .uploading-file[data-progress="27"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="28"], .week-points .uploading-file[data-progress="28"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="29"], .week-points .uploading-file[data-progress="29"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="30"], .week-points .uploading-file[data-progress="30"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="31"], .week-points .uploading-file[data-progress="31"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="32"], .week-points .uploading-file[data-progress="32"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="33"], .week-points .uploading-file[data-progress="33"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="34"], .week-points .uploading-file[data-progress="34"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="35"], .week-points .uploading-file[data-progress="35"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="36"], .week-points .uploading-file[data-progress="36"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="37"], .week-points .uploading-file[data-progress="37"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="38"], .week-points .uploading-file[data-progress="38"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="39"], .week-points .uploading-file[data-progress="39"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="40"], .week-points .uploading-file[data-progress="40"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="41"], .week-points .uploading-file[data-progress="41"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="42"], .week-points .uploading-file[data-progress="42"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="43"], .week-points .uploading-file[data-progress="43"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="44"], .week-points .uploading-file[data-progress="44"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="45"], .week-points .uploading-file[data-progress="45"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="46"], .week-points .uploading-file[data-progress="46"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="47"], .week-points .uploading-file[data-progress="47"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="48"], .week-points .uploading-file[data-progress="48"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="49"], .week-points .uploading-file[data-progress="49"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="50"], .week-points .uploading-file[data-progress="50"]:before {
  background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="51"], .week-points .uploading-file[data-progress="51"]:before {
  background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="52"], .week-points .uploading-file[data-progress="52"]:before {
  background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="53"], .week-points .uploading-file[data-progress="53"]:before {
  background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="54"], .week-points .uploading-file[data-progress="54"]:before {
  background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="55"], .week-points .uploading-file[data-progress="55"]:before {
  background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="56"], .week-points .uploading-file[data-progress="56"]:before {
  background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="57"], .week-points .uploading-file[data-progress="57"]:before {
  background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="58"], .week-points .uploading-file[data-progress="58"]:before {
  background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="59"], .week-points .uploading-file[data-progress="59"]:before {
  background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="60"], .week-points .uploading-file[data-progress="60"]:before {
  background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="61"], .week-points .uploading-file[data-progress="61"]:before {
  background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="62"], .week-points .uploading-file[data-progress="62"]:before {
  background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="63"], .week-points .uploading-file[data-progress="63"]:before {
  background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="64"], .week-points .uploading-file[data-progress="64"]:before {
  background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="65"], .week-points .uploading-file[data-progress="65"]:before {
  background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="66"], .week-points .uploading-file[data-progress="66"]:before {
  background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="67"], .week-points .uploading-file[data-progress="67"]:before {
  background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="68"], .week-points .uploading-file[data-progress="68"]:before {
  background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="69"], .week-points .uploading-file[data-progress="69"]:before {
  background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="70"], .week-points .uploading-file[data-progress="70"]:before {
  background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="71"], .week-points .uploading-file[data-progress="71"]:before {
  background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="72"], .week-points .uploading-file[data-progress="72"]:before {
  background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="73"], .week-points .uploading-file[data-progress="73"]:before {
  background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="74"], .week-points .uploading-file[data-progress="74"]:before {
  background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="75"], .week-points .uploading-file[data-progress="75"]:before {
  background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="76"], .week-points .uploading-file[data-progress="76"]:before {
  background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="77"], .week-points .uploading-file[data-progress="77"]:before {
  background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="78"], .week-points .uploading-file[data-progress="78"]:before {
  background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="79"], .week-points .uploading-file[data-progress="79"]:before {
  background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="80"], .week-points .uploading-file[data-progress="80"]:before {
  background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="81"], .week-points .uploading-file[data-progress="81"]:before {
  background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="82"], .week-points .uploading-file[data-progress="82"]:before {
  background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="83"], .week-points .uploading-file[data-progress="83"]:before {
  background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="84"], .week-points .uploading-file[data-progress="84"]:before {
  background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="85"], .week-points .uploading-file[data-progress="85"]:before {
  background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="86"], .week-points .uploading-file[data-progress="86"]:before {
  background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="87"], .week-points .uploading-file[data-progress="87"]:before {
  background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="88"], .week-points .uploading-file[data-progress="88"]:before {
  background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="89"], .week-points .uploading-file[data-progress="89"]:before {
  background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="90"], .week-points .uploading-file[data-progress="90"]:before {
  background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="91"], .week-points .uploading-file[data-progress="91"]:before {
  background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="92"], .week-points .uploading-file[data-progress="92"]:before {
  background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="93"], .week-points .uploading-file[data-progress="93"]:before {
  background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="94"], .week-points .uploading-file[data-progress="94"]:before {
  background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="95"], .week-points .uploading-file[data-progress="95"]:before {
  background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="96"], .week-points .uploading-file[data-progress="96"]:before {
  background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="97"], .week-points .uploading-file[data-progress="97"]:before {
  background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="98"], .week-points .uploading-file[data-progress="98"]:before {
  background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="99"], .week-points .uploading-file[data-progress="99"]:before {
  background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .active-border[data-progress="100"], .week-points .uploading-file[data-progress="100"]:before {
  background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.week-points .circle {
  border: 1px solid #eeede9;
  transform: none; }

.upcomming-events .circle {
  text-align: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: #fbfaf6;
  display: flex;
  justify-content: center;
  align-items: center; }

.upcomming-events .active-border {
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }

.upcomming-events .uploading-file:before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0px;
  transform: rotate(-180deg); }

.upcomming-events .active-border[data-progress="0"], .upcomming-events .uploading-file[data-progress="0"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="1"], .upcomming-events .uploading-file[data-progress="1"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="2"], .upcomming-events .uploading-file[data-progress="2"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="3"], .upcomming-events .uploading-file[data-progress="3"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="4"], .upcomming-events .uploading-file[data-progress="4"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="5"], .upcomming-events .uploading-file[data-progress="5"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="6"], .upcomming-events .uploading-file[data-progress="6"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="7"], .upcomming-events .uploading-file[data-progress="7"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="8"], .upcomming-events .uploading-file[data-progress="8"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="9"], .upcomming-events .uploading-file[data-progress="9"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="10"], .upcomming-events .uploading-file[data-progress="10"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="11"], .upcomming-events .uploading-file[data-progress="11"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="12"], .upcomming-events .uploading-file[data-progress="12"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="13"], .upcomming-events .uploading-file[data-progress="13"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="14"], .upcomming-events .uploading-file[data-progress="14"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="15"], .upcomming-events .uploading-file[data-progress="15"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="16"], .upcomming-events .uploading-file[data-progress="16"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="17"], .upcomming-events .uploading-file[data-progress="17"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="18"], .upcomming-events .uploading-file[data-progress="18"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="19"], .upcomming-events .uploading-file[data-progress="19"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="20"], .upcomming-events .uploading-file[data-progress="20"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="21"], .upcomming-events .uploading-file[data-progress="21"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="22"], .upcomming-events .uploading-file[data-progress="22"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="23"], .upcomming-events .uploading-file[data-progress="23"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="24"], .upcomming-events .uploading-file[data-progress="24"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="25"], .upcomming-events .uploading-file[data-progress="25"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="26"], .upcomming-events .uploading-file[data-progress="26"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="27"], .upcomming-events .uploading-file[data-progress="27"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="28"], .upcomming-events .uploading-file[data-progress="28"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="29"], .upcomming-events .uploading-file[data-progress="29"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="30"], .upcomming-events .uploading-file[data-progress="30"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="31"], .upcomming-events .uploading-file[data-progress="31"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="32"], .upcomming-events .uploading-file[data-progress="32"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="33"], .upcomming-events .uploading-file[data-progress="33"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="34"], .upcomming-events .uploading-file[data-progress="34"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="35"], .upcomming-events .uploading-file[data-progress="35"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="36"], .upcomming-events .uploading-file[data-progress="36"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="37"], .upcomming-events .uploading-file[data-progress="37"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="38"], .upcomming-events .uploading-file[data-progress="38"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="39"], .upcomming-events .uploading-file[data-progress="39"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="40"], .upcomming-events .uploading-file[data-progress="40"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="41"], .upcomming-events .uploading-file[data-progress="41"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="42"], .upcomming-events .uploading-file[data-progress="42"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="43"], .upcomming-events .uploading-file[data-progress="43"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="44"], .upcomming-events .uploading-file[data-progress="44"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="45"], .upcomming-events .uploading-file[data-progress="45"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="46"], .upcomming-events .uploading-file[data-progress="46"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="47"], .upcomming-events .uploading-file[data-progress="47"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="48"], .upcomming-events .uploading-file[data-progress="48"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="49"], .upcomming-events .uploading-file[data-progress="49"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="50"], .upcomming-events .uploading-file[data-progress="50"]:before {
  background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="51"], .upcomming-events .uploading-file[data-progress="51"]:before {
  background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="52"], .upcomming-events .uploading-file[data-progress="52"]:before {
  background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="53"], .upcomming-events .uploading-file[data-progress="53"]:before {
  background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="54"], .upcomming-events .uploading-file[data-progress="54"]:before {
  background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="55"], .upcomming-events .uploading-file[data-progress="55"]:before {
  background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="56"], .upcomming-events .uploading-file[data-progress="56"]:before {
  background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="57"], .upcomming-events .uploading-file[data-progress="57"]:before {
  background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="58"], .upcomming-events .uploading-file[data-progress="58"]:before {
  background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="59"], .upcomming-events .uploading-file[data-progress="59"]:before {
  background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="60"], .upcomming-events .uploading-file[data-progress="60"]:before {
  background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="61"], .upcomming-events .uploading-file[data-progress="61"]:before {
  background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="62"], .upcomming-events .uploading-file[data-progress="62"]:before {
  background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="63"], .upcomming-events .uploading-file[data-progress="63"]:before {
  background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="64"], .upcomming-events .uploading-file[data-progress="64"]:before {
  background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="65"], .upcomming-events .uploading-file[data-progress="65"]:before {
  background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="66"], .upcomming-events .uploading-file[data-progress="66"]:before {
  background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="67"], .upcomming-events .uploading-file[data-progress="67"]:before {
  background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="68"], .upcomming-events .uploading-file[data-progress="68"]:before {
  background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="69"], .upcomming-events .uploading-file[data-progress="69"]:before {
  background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="70"], .upcomming-events .uploading-file[data-progress="70"]:before {
  background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="71"], .upcomming-events .uploading-file[data-progress="71"]:before {
  background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="72"], .upcomming-events .uploading-file[data-progress="72"]:before {
  background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="73"], .upcomming-events .uploading-file[data-progress="73"]:before {
  background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="74"], .upcomming-events .uploading-file[data-progress="74"]:before {
  background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="75"], .upcomming-events .uploading-file[data-progress="75"]:before {
  background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="76"], .upcomming-events .uploading-file[data-progress="76"]:before {
  background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="77"], .upcomming-events .uploading-file[data-progress="77"]:before {
  background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="78"], .upcomming-events .uploading-file[data-progress="78"]:before {
  background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="79"], .upcomming-events .uploading-file[data-progress="79"]:before {
  background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="80"], .upcomming-events .uploading-file[data-progress="80"]:before {
  background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="81"], .upcomming-events .uploading-file[data-progress="81"]:before {
  background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="82"], .upcomming-events .uploading-file[data-progress="82"]:before {
  background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="83"], .upcomming-events .uploading-file[data-progress="83"]:before {
  background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="84"], .upcomming-events .uploading-file[data-progress="84"]:before {
  background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="85"], .upcomming-events .uploading-file[data-progress="85"]:before {
  background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="86"], .upcomming-events .uploading-file[data-progress="86"]:before {
  background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="87"], .upcomming-events .uploading-file[data-progress="87"]:before {
  background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="88"], .upcomming-events .uploading-file[data-progress="88"]:before {
  background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="89"], .upcomming-events .uploading-file[data-progress="89"]:before {
  background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="90"], .upcomming-events .uploading-file[data-progress="90"]:before {
  background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="91"], .upcomming-events .uploading-file[data-progress="91"]:before {
  background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="92"], .upcomming-events .uploading-file[data-progress="92"]:before {
  background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="93"], .upcomming-events .uploading-file[data-progress="93"]:before {
  background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="94"], .upcomming-events .uploading-file[data-progress="94"]:before {
  background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="95"], .upcomming-events .uploading-file[data-progress="95"]:before {
  background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="96"], .upcomming-events .uploading-file[data-progress="96"]:before {
  background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="97"], .upcomming-events .uploading-file[data-progress="97"]:before {
  background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="98"], .upcomming-events .uploading-file[data-progress="98"]:before {
  background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="99"], .upcomming-events .uploading-file[data-progress="99"]:before {
  background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .active-border[data-progress="100"], .upcomming-events .uploading-file[data-progress="100"]:before {
  background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.upcomming-events .circle img {
  height: 15px; }

.select-branch select {
  margin-top: 30px; }

/*Lesson homepage for teacher*/
.teacher-tabs .tab-content {
  background: #e2e1dd; }

.carousel-slide {
  background: #fff;
  padding: 0 0 20px;
  position: relative;
  cursor: pointer; }
  .carousel-slide .block-link {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; }
  .carousel-slide h4 {
    padding: 15px;
    border-bottom: 1px solid rgba(33, 37, 41, 0.2); }
    .carousel-slide h4 img {
      opacity: 0.4; }
  .carousel-slide ul {
    padding: 20px 15px;
    margin: 0;
    list-style: none; }
  .carousel-slide li:not(:last-child) {
    padding-bottom: 10px; }
  .carousel-slide li:last-child {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(33, 37, 41, 0.1); }
  .carousel-slide li .number {
    float: right;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px; }
  .carousel-slide li span:not(.number) {
    color: rgba(33, 37, 41, 0.5); }

.lesson-help {
  background: #fff;
  border-radius: 3px;
  padding: 25px 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  margin: 0 0 16px; }
  .lesson-help p {
    white-space: pre-line;
    font-size: 14px;
    color: #565b65;
    margin: 0; }
    .lesson-help p span {
      opacity: 0.6; }

/*Lesson Detail*/
.course-background {
  position: relative; }
  .course-background .row {
    position: absolute;
    bottom: 0;
    color: #fff;
    left: 0;
    right: 0;
    padding: 15px;
    font-size: 15px; }
  .course-background .user-name img {
    border-radius: 100%;
    margin-right: 10px; }
  .course-background .week, .course-background .date {
    font-size: 12px;
    display: block; }
  .course-background .date {
    opacity: 0.7; }

.discussion-content {
  overflow: hidden; }
  .discussion-content .white-box {
    background: #fff;
    border-radius: 2px;
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    margin: 0 0 90px;
    overflow: hidden; }
  .discussion-content .progressed-bar {
    margin-top: 36px; }
  .discussion-content .banner p {
    margin: 0;
    padding: 12px 24px 12px;
    font-size: 14px;
    color: #656d78; }
  .discussion-content .course-footer {
    background: rgba(25, 25, 25, 0.04); }
    .discussion-content .course-footer a {
      color: #0099ff; }

.progressed-bar h4, .progressed-bar .count {
  position: relative;
  z-index: 9; }

.progressed-bar h4 {
  display: inline-flex;
  align-items: center;
  background: #fff;
  margin: 0;
  line-height: 32px;
  padding-right: 15px; }
  .progressed-bar h4 img {
    height: 17px; }

.progressed-bar .count {
  display: inline-block;
  width: auto;
  height: 32px;
  border-radius: 32px;
  background: #dff2e9;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  line-height: 32px;
  border: none;
  padding: 0 22px;
  overflow: hidden;
  align-items: center; }
  .progressed-bar .count .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }
  .progressed-bar .count i {
    font-size: 16px;
    margin: 0 6px; }
  .progressed-bar .count span {
    opacity: 0.5; }

.progressed-bar .circle {
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: #39b27b;
  display: flex;
  justify-content: center;
  align-items: center; }

.progressed-bar .active-border {
  text-align: center;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }

.progressed-bar .uploading-file:before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0px;
  transform: rotate(-180deg); }

.progressed-bar .active-border[data-progress="0"], .progressed-bar .uploading-file[data-progress="0"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="1"], .progressed-bar .uploading-file[data-progress="1"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="2"], .progressed-bar .uploading-file[data-progress="2"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="3"], .progressed-bar .uploading-file[data-progress="3"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="4"], .progressed-bar .uploading-file[data-progress="4"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="5"], .progressed-bar .uploading-file[data-progress="5"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="6"], .progressed-bar .uploading-file[data-progress="6"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="7"], .progressed-bar .uploading-file[data-progress="7"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="8"], .progressed-bar .uploading-file[data-progress="8"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="9"], .progressed-bar .uploading-file[data-progress="9"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="10"], .progressed-bar .uploading-file[data-progress="10"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="11"], .progressed-bar .uploading-file[data-progress="11"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="12"], .progressed-bar .uploading-file[data-progress="12"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="13"], .progressed-bar .uploading-file[data-progress="13"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="14"], .progressed-bar .uploading-file[data-progress="14"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="15"], .progressed-bar .uploading-file[data-progress="15"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="16"], .progressed-bar .uploading-file[data-progress="16"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="17"], .progressed-bar .uploading-file[data-progress="17"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="18"], .progressed-bar .uploading-file[data-progress="18"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="19"], .progressed-bar .uploading-file[data-progress="19"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="20"], .progressed-bar .uploading-file[data-progress="20"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="21"], .progressed-bar .uploading-file[data-progress="21"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="22"], .progressed-bar .uploading-file[data-progress="22"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="23"], .progressed-bar .uploading-file[data-progress="23"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="24"], .progressed-bar .uploading-file[data-progress="24"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="25"], .progressed-bar .uploading-file[data-progress="25"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="26"], .progressed-bar .uploading-file[data-progress="26"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="27"], .progressed-bar .uploading-file[data-progress="27"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="28"], .progressed-bar .uploading-file[data-progress="28"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="29"], .progressed-bar .uploading-file[data-progress="29"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="30"], .progressed-bar .uploading-file[data-progress="30"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="31"], .progressed-bar .uploading-file[data-progress="31"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="32"], .progressed-bar .uploading-file[data-progress="32"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="33"], .progressed-bar .uploading-file[data-progress="33"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="34"], .progressed-bar .uploading-file[data-progress="34"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="35"], .progressed-bar .uploading-file[data-progress="35"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="36"], .progressed-bar .uploading-file[data-progress="36"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="37"], .progressed-bar .uploading-file[data-progress="37"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="38"], .progressed-bar .uploading-file[data-progress="38"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="39"], .progressed-bar .uploading-file[data-progress="39"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="40"], .progressed-bar .uploading-file[data-progress="40"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="41"], .progressed-bar .uploading-file[data-progress="41"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="42"], .progressed-bar .uploading-file[data-progress="42"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="43"], .progressed-bar .uploading-file[data-progress="43"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="44"], .progressed-bar .uploading-file[data-progress="44"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="45"], .progressed-bar .uploading-file[data-progress="45"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="46"], .progressed-bar .uploading-file[data-progress="46"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="47"], .progressed-bar .uploading-file[data-progress="47"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="48"], .progressed-bar .uploading-file[data-progress="48"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="49"], .progressed-bar .uploading-file[data-progress="49"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="50"], .progressed-bar .uploading-file[data-progress="50"]:before {
  background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="51"], .progressed-bar .uploading-file[data-progress="51"]:before {
  background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="52"], .progressed-bar .uploading-file[data-progress="52"]:before {
  background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="53"], .progressed-bar .uploading-file[data-progress="53"]:before {
  background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="54"], .progressed-bar .uploading-file[data-progress="54"]:before {
  background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="55"], .progressed-bar .uploading-file[data-progress="55"]:before {
  background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="56"], .progressed-bar .uploading-file[data-progress="56"]:before {
  background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="57"], .progressed-bar .uploading-file[data-progress="57"]:before {
  background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="58"], .progressed-bar .uploading-file[data-progress="58"]:before {
  background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="59"], .progressed-bar .uploading-file[data-progress="59"]:before {
  background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="60"], .progressed-bar .uploading-file[data-progress="60"]:before {
  background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="61"], .progressed-bar .uploading-file[data-progress="61"]:before {
  background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="62"], .progressed-bar .uploading-file[data-progress="62"]:before {
  background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="63"], .progressed-bar .uploading-file[data-progress="63"]:before {
  background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="64"], .progressed-bar .uploading-file[data-progress="64"]:before {
  background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="65"], .progressed-bar .uploading-file[data-progress="65"]:before {
  background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="66"], .progressed-bar .uploading-file[data-progress="66"]:before {
  background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="67"], .progressed-bar .uploading-file[data-progress="67"]:before {
  background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="68"], .progressed-bar .uploading-file[data-progress="68"]:before {
  background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="69"], .progressed-bar .uploading-file[data-progress="69"]:before {
  background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="70"], .progressed-bar .uploading-file[data-progress="70"]:before {
  background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="71"], .progressed-bar .uploading-file[data-progress="71"]:before {
  background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="72"], .progressed-bar .uploading-file[data-progress="72"]:before {
  background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="73"], .progressed-bar .uploading-file[data-progress="73"]:before {
  background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="74"], .progressed-bar .uploading-file[data-progress="74"]:before {
  background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="75"], .progressed-bar .uploading-file[data-progress="75"]:before {
  background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="76"], .progressed-bar .uploading-file[data-progress="76"]:before {
  background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="77"], .progressed-bar .uploading-file[data-progress="77"]:before {
  background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="78"], .progressed-bar .uploading-file[data-progress="78"]:before {
  background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="79"], .progressed-bar .uploading-file[data-progress="79"]:before {
  background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="80"], .progressed-bar .uploading-file[data-progress="80"]:before {
  background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="81"], .progressed-bar .uploading-file[data-progress="81"]:before {
  background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="82"], .progressed-bar .uploading-file[data-progress="82"]:before {
  background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="83"], .progressed-bar .uploading-file[data-progress="83"]:before {
  background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="84"], .progressed-bar .uploading-file[data-progress="84"]:before {
  background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="85"], .progressed-bar .uploading-file[data-progress="85"]:before {
  background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="86"], .progressed-bar .uploading-file[data-progress="86"]:before {
  background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="87"], .progressed-bar .uploading-file[data-progress="87"]:before {
  background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="88"], .progressed-bar .uploading-file[data-progress="88"]:before {
  background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="89"], .progressed-bar .uploading-file[data-progress="89"]:before {
  background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="90"], .progressed-bar .uploading-file[data-progress="90"]:before {
  background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="91"], .progressed-bar .uploading-file[data-progress="91"]:before {
  background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="92"], .progressed-bar .uploading-file[data-progress="92"]:before {
  background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="93"], .progressed-bar .uploading-file[data-progress="93"]:before {
  background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="94"], .progressed-bar .uploading-file[data-progress="94"]:before {
  background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="95"], .progressed-bar .uploading-file[data-progress="95"]:before {
  background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="96"], .progressed-bar .uploading-file[data-progress="96"]:before {
  background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="97"], .progressed-bar .uploading-file[data-progress="97"]:before {
  background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="98"], .progressed-bar .uploading-file[data-progress="98"]:before {
  background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="99"], .progressed-bar .uploading-file[data-progress="99"]:before {
  background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .active-border[data-progress="100"], .progressed-bar .uploading-file[data-progress="100"]:before {
  background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.progressed-bar .circle {
  transform: none;
  margin-right: 12px; }
  .progressed-bar .circle i {
    font-size: 20px; }

.quiz-section ul {
  list-style: none;
  padding: 0; }
  .quiz-section ul li {
    position: relative; }
    .quiz-section ul li:not(:last-child) {
      margin-bottom: 16px; }
    .quiz-section ul li.ticked:after, .quiz-section ul li.wrong:after {
      position: absolute;
      font: 18px/1 'Material Icons';
      top: 8px;
      left: -25px; }
    .quiz-section ul li.ticked:after {
      content: "done";
      color: #39b27b; }
    .quiz-section ul li.wrong:after {
      content: "close";
      color: #ff6f61; }
    .quiz-section ul li.ticked input ~ label:after, .quiz-section ul li.wrong input ~ label:after {
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute; }
    .quiz-section ul li.ticked input ~ label:after {
      background: #39b27b;
      opacity: 0.2; }
    .quiz-section ul li.wrong input ~ label:after {
      background: #ff6f61;
      opacity: 0.2; }

.quiz-section .dot-radio {
  display: inline-flex;
  padding: 4px 12px 4px 6px; }
  .quiz-section .dot-radio label {
    width: calc(100% - 21px);
    line-height: 24px;
    font-weight: 400; }
  .quiz-section .dot-radio input {
    margin-top: 5px; }
    .quiz-section .dot-radio input:checked ~ label {
      color: #323232; }

.homework-ans h6 {
  font-size: 18px; }

.homework-ans textarea {
  width: 100%;
  padding: 12px;
  background: #eeede9;
  border: none;
  resize: none;
  border-radius: 3px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8); }
  .homework-ans textarea:focus {
    outline: none; }

.homework-ans .text-limit {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 12px;
  bottom: 6px; }

.homework-ans .half-color {
  font-size: 13px; }

.upload-image {
  background: #eeede9;
  padding: 48px 15px 32px;
  text-align: center; }

.upload-image.upload-in-process > *:not(.upload-percentage) {
  visibility: hidden; }

.upload-image.upload-in-process.upload-in-process {
  background: rgba(57, 178, 123, 0.1);
  text-align: left;
  position: relative; }
  .upload-image.upload-in-process.upload-in-process h1 {
    font-size: 96px;
    line-height: 1.19;
    color: rgba(0, 0, 0, 0.2); }
  .upload-image.upload-in-process.upload-in-process span {
    font-size: 18px;
    opacity: 0.5; }
  .upload-image.upload-in-process.upload-in-process .upload-percentage {
    padding: 48px 15px 32px;
    z-index: 9;
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #39b27b; }

.main-sidebar {
  background: #eeede9; }
  .main-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .main-sidebar h5 img {
    margin-top: 10px; }
  .main-sidebar > ul li {
    cursor: pointer;
    padding: 16px 10px 16px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .main-sidebar > ul li:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .main-sidebar > ul li:hover {
      background: rgba(0, 0, 0, 0.04); }
  .main-sidebar > ul [active="active"] {
    background: rgba(0, 0, 0, 0.08); }
    .main-sidebar > ul [active="active"] > .media {
      padding-bottom: 16px; }
  .main-sidebar > ul [passive="passive"] img {
    opacity: 0.4; }
  .main-sidebar > ul [passive="passive"] span, .main-sidebar > ul [passive="passive"] label {
    opacity: 0.6; }
  .main-sidebar > ul [outdated="outdated"] label, .main-sidebar > ul [outdated="outdated"] span {
    color: #ff6f61 !important; }
  .main-sidebar > ul [outdated="outdated"] span {
    opacity: 0.8; }
  .main-sidebar > ul [lock="lock"] .material-icons {
    opacity: 0.5;
    font-size: 20px; }
  .main-sidebar > ul [lock="lock"] label, .main-sidebar > ul [lock="lock"] span {
    opacity: 0.3; }
  .main-sidebar .course-percentage {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 124px;
    background: #e2e1dd;
    padding: 10px 20px; }
    .main-sidebar .course-percentage .count {
      display: inline-block;
      width: auto;
      height: 20px;
      border-radius: 32px;
      background: #39b27b;
      font-size: 12px;
      text-align: center;
      font-weight: 600;
      line-height: 20px;
      border: none;
      padding: 0 7px;
      color: #fff; }
      .main-sidebar .course-percentage .count .percentage {
        background: #5dbe91;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1; }
    .main-sidebar .course-percentage .bar {
      background: rgba(0, 0, 0, 0.05);
      height: 6px;
      border-radius: 32px;
      margin: 12px 0 16px;
      position: relative; }
      .main-sidebar .course-percentage .bar .bar-width {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        background: #323231;
        border-radius: 32px; }
    .main-sidebar .course-percentage p {
      font-size: 12px;
      color: #656d78; }
  .main-sidebar .circle {
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #39b27b;
    display: flex;
    justify-content: center;
    align-items: center; }
  .main-sidebar .active-border {
    text-align: center;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center; }
  .main-sidebar .uploading-file:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
    transform: rotate(-180deg); }
  .main-sidebar .active-border[data-progress="0"], .main-sidebar .uploading-file[data-progress="0"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="1"], .main-sidebar .uploading-file[data-progress="1"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="2"], .main-sidebar .uploading-file[data-progress="2"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="3"], .main-sidebar .uploading-file[data-progress="3"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="4"], .main-sidebar .uploading-file[data-progress="4"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="5"], .main-sidebar .uploading-file[data-progress="5"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="6"], .main-sidebar .uploading-file[data-progress="6"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="7"], .main-sidebar .uploading-file[data-progress="7"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="8"], .main-sidebar .uploading-file[data-progress="8"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="9"], .main-sidebar .uploading-file[data-progress="9"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="10"], .main-sidebar .uploading-file[data-progress="10"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="11"], .main-sidebar .uploading-file[data-progress="11"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="12"], .main-sidebar .uploading-file[data-progress="12"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="13"], .main-sidebar .uploading-file[data-progress="13"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="14"], .main-sidebar .uploading-file[data-progress="14"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="15"], .main-sidebar .uploading-file[data-progress="15"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="16"], .main-sidebar .uploading-file[data-progress="16"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="17"], .main-sidebar .uploading-file[data-progress="17"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="18"], .main-sidebar .uploading-file[data-progress="18"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="19"], .main-sidebar .uploading-file[data-progress="19"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="20"], .main-sidebar .uploading-file[data-progress="20"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="21"], .main-sidebar .uploading-file[data-progress="21"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="22"], .main-sidebar .uploading-file[data-progress="22"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="23"], .main-sidebar .uploading-file[data-progress="23"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="24"], .main-sidebar .uploading-file[data-progress="24"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="25"], .main-sidebar .uploading-file[data-progress="25"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="26"], .main-sidebar .uploading-file[data-progress="26"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="27"], .main-sidebar .uploading-file[data-progress="27"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="28"], .main-sidebar .uploading-file[data-progress="28"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="29"], .main-sidebar .uploading-file[data-progress="29"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="30"], .main-sidebar .uploading-file[data-progress="30"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="31"], .main-sidebar .uploading-file[data-progress="31"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="32"], .main-sidebar .uploading-file[data-progress="32"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="33"], .main-sidebar .uploading-file[data-progress="33"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="34"], .main-sidebar .uploading-file[data-progress="34"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="35"], .main-sidebar .uploading-file[data-progress="35"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="36"], .main-sidebar .uploading-file[data-progress="36"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="37"], .main-sidebar .uploading-file[data-progress="37"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="38"], .main-sidebar .uploading-file[data-progress="38"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="39"], .main-sidebar .uploading-file[data-progress="39"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="40"], .main-sidebar .uploading-file[data-progress="40"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="41"], .main-sidebar .uploading-file[data-progress="41"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="42"], .main-sidebar .uploading-file[data-progress="42"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="43"], .main-sidebar .uploading-file[data-progress="43"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="44"], .main-sidebar .uploading-file[data-progress="44"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="45"], .main-sidebar .uploading-file[data-progress="45"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="46"], .main-sidebar .uploading-file[data-progress="46"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="47"], .main-sidebar .uploading-file[data-progress="47"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="48"], .main-sidebar .uploading-file[data-progress="48"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="49"], .main-sidebar .uploading-file[data-progress="49"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="50"], .main-sidebar .uploading-file[data-progress="50"]:before {
    background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="51"], .main-sidebar .uploading-file[data-progress="51"]:before {
    background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="52"], .main-sidebar .uploading-file[data-progress="52"]:before {
    background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="53"], .main-sidebar .uploading-file[data-progress="53"]:before {
    background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="54"], .main-sidebar .uploading-file[data-progress="54"]:before {
    background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="55"], .main-sidebar .uploading-file[data-progress="55"]:before {
    background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="56"], .main-sidebar .uploading-file[data-progress="56"]:before {
    background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="57"], .main-sidebar .uploading-file[data-progress="57"]:before {
    background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="58"], .main-sidebar .uploading-file[data-progress="58"]:before {
    background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="59"], .main-sidebar .uploading-file[data-progress="59"]:before {
    background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="60"], .main-sidebar .uploading-file[data-progress="60"]:before {
    background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="61"], .main-sidebar .uploading-file[data-progress="61"]:before {
    background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="62"], .main-sidebar .uploading-file[data-progress="62"]:before {
    background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="63"], .main-sidebar .uploading-file[data-progress="63"]:before {
    background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="64"], .main-sidebar .uploading-file[data-progress="64"]:before {
    background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="65"], .main-sidebar .uploading-file[data-progress="65"]:before {
    background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="66"], .main-sidebar .uploading-file[data-progress="66"]:before {
    background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="67"], .main-sidebar .uploading-file[data-progress="67"]:before {
    background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="68"], .main-sidebar .uploading-file[data-progress="68"]:before {
    background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="69"], .main-sidebar .uploading-file[data-progress="69"]:before {
    background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="70"], .main-sidebar .uploading-file[data-progress="70"]:before {
    background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="71"], .main-sidebar .uploading-file[data-progress="71"]:before {
    background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="72"], .main-sidebar .uploading-file[data-progress="72"]:before {
    background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="73"], .main-sidebar .uploading-file[data-progress="73"]:before {
    background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="74"], .main-sidebar .uploading-file[data-progress="74"]:before {
    background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="75"], .main-sidebar .uploading-file[data-progress="75"]:before {
    background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="76"], .main-sidebar .uploading-file[data-progress="76"]:before {
    background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="77"], .main-sidebar .uploading-file[data-progress="77"]:before {
    background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="78"], .main-sidebar .uploading-file[data-progress="78"]:before {
    background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="79"], .main-sidebar .uploading-file[data-progress="79"]:before {
    background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="80"], .main-sidebar .uploading-file[data-progress="80"]:before {
    background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="81"], .main-sidebar .uploading-file[data-progress="81"]:before {
    background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="82"], .main-sidebar .uploading-file[data-progress="82"]:before {
    background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="83"], .main-sidebar .uploading-file[data-progress="83"]:before {
    background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="84"], .main-sidebar .uploading-file[data-progress="84"]:before {
    background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="85"], .main-sidebar .uploading-file[data-progress="85"]:before {
    background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="86"], .main-sidebar .uploading-file[data-progress="86"]:before {
    background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="87"], .main-sidebar .uploading-file[data-progress="87"]:before {
    background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="88"], .main-sidebar .uploading-file[data-progress="88"]:before {
    background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="89"], .main-sidebar .uploading-file[data-progress="89"]:before {
    background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="90"], .main-sidebar .uploading-file[data-progress="90"]:before {
    background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="91"], .main-sidebar .uploading-file[data-progress="91"]:before {
    background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="92"], .main-sidebar .uploading-file[data-progress="92"]:before {
    background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="93"], .main-sidebar .uploading-file[data-progress="93"]:before {
    background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="94"], .main-sidebar .uploading-file[data-progress="94"]:before {
    background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="95"], .main-sidebar .uploading-file[data-progress="95"]:before {
    background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="96"], .main-sidebar .uploading-file[data-progress="96"]:before {
    background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="97"], .main-sidebar .uploading-file[data-progress="97"]:before {
    background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="98"], .main-sidebar .uploading-file[data-progress="98"]:before {
    background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="99"], .main-sidebar .uploading-file[data-progress="99"]:before {
    background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .active-border[data-progress="100"], .main-sidebar .uploading-file[data-progress="100"]:before {
    background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .main-sidebar .circle {
    transform: none; }
  .main-sidebar .inner-items {
    margin: 0 -10px 0 -16px;
    background: rgba(0, 0, 0, 0.04);
    padding: 4px; }
    .main-sidebar .inner-items .media {
      padding: 16px 10px 16px 16px; }
      .main-sidebar .inner-items .media label {
        font-size: 13px; }
    .main-sidebar .inner-items .circle {
      width: 24px;
      height: 24px; }
      .main-sidebar .inner-items .circle i {
        font-size: 16px; }
  .main-sidebar .media {
    align-items: center; }
  .main-sidebar .modal-body {
    padding: 0 0 0 12px; }
    .main-sidebar .modal-body label {
      margin: 0;
      display: block;
      font-size: 15px;
      line-height: 20px; }
    .main-sidebar .modal-body span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.3);
      line-height: 14px;
      display: block; }

/*Activity Dropdown*/
.plus-dropdown .dropdown .dropdown-menu {
  right: 64px;
  transform: none !important;
  left: auto !important;
  margin-top: 45px;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  max-width: 421px;
  border: none; }

.plus-dropdown .dropdown-menu label {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  display: block; }

.plus-dropdown .dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0 -1px; }

.plus-dropdown .dropdown-menu li {
  background: #fbfaf6;
  display: inline-block;
  width: 96px;
  text-align: center;
  border: 1px solid #fff;
  margin-top: 4px; }
  .plus-dropdown .dropdown-menu li:hover {
    background: #eeede9; }
  .plus-dropdown .dropdown-menu li a {
    text-decoration: none;
    padding: 7px 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .plus-dropdown .dropdown-menu li span {
    line-height: 20px;
    font-size: 12px;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.5); }
  .plus-dropdown .dropdown-menu li img, .plus-dropdown .dropdown-menu li svg {
    height: 24px; }
  .plus-dropdown .dropdown-menu li svg g {
    fill: #333; }

.modal-from-right .buyer-stats-table td, .announcementTabs .buyer-stats-table td {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 14px; }
  .modal-from-right .buyer-stats-table td:not(:first-child), .announcementTabs .buyer-stats-table td:not(:first-child) {
    text-align: right; }
  .modal-from-right .buyer-stats-table td .material-icons, .announcementTabs .buyer-stats-table td .material-icons {
    color: #c9c8c5; }

.modal-from-right .nav-tabs, .announcementTabs .nav-tabs {
  display: table;
  width: 100%; }
  .modal-from-right .nav-tabs li, .announcementTabs .nav-tabs li {
    display: table-cell; }
  .modal-from-right .nav-tabs a, .announcementTabs .nav-tabs a {
    display: block;
    border: none;
    text-align: center;
    border-bottom: 3px solid #EEEDE9;
    color: #323231;
    padding: 13px 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600; }
    .modal-from-right .nav-tabs a:not(.active), .announcementTabs .nav-tabs a:not(.active) {
      opacity: 0.3;
      background: #EEEDE9; }
    .modal-from-right .nav-tabs a.active, .announcementTabs .nav-tabs a.active {
      background: #fbfaf6;
      border-bottom-color: #0099ff; }

/*Activity Dropdown*/
/*Activity Modal*/
.modal-from-right .options, .modal-from-right .options-bulets, .question-modal .options, .question-modal .options-bulets, .message-people-list .options, .message-people-list .options-bulets {
  list-style: none;
  padding: 0; }
  .modal-from-right .options .active, .modal-from-right .options-bulets .active, .question-modal .options .active, .question-modal .options-bulets .active, .message-people-list .options .active, .message-people-list .options-bulets .active {
    position: relative; }
    .modal-from-right .options .active::after, .modal-from-right .options-bulets .active::after, .question-modal .options .active::after, .question-modal .options-bulets .active::after, .message-people-list .options .active::after, .message-people-list .options-bulets .active::after {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #39b27b;
      position: absolute;
      left: 46px;
      top: 3px;
      content: ""; }
  .modal-from-right .options img, .modal-from-right .options-bulets img, .question-modal .options img, .question-modal .options-bulets img, .message-people-list .options img, .message-people-list .options-bulets img {
    width: 32px;
    border: 2px solid #fbfaf6;
    border-radius: 100%; }

.modal-from-right .announcement-heading, .question-modal .announcement-heading {
  padding-top: 8px;
  padding-bottom: 7px; }
  .modal-from-right .announcement-heading span, .question-modal .announcement-heading span {
    font-size: 11px;
    color: rgba(33, 37, 41, 0.5); }
  .modal-from-right .announcement-heading h6, .question-modal .announcement-heading h6 {
    font-size: 14px; }
  .modal-from-right .announcement-heading a, .question-modal .announcement-heading a {
    color: #0099ff;
    font-size: 12px;
    text-decoration: none; }

.modal-from-right .options img, .modal-from-right .options-bulets img, .question-modal .options img, .question-modal .options-bulets img {
  margin: 0 14px 0 23px; }

.modal-from-right .options li, .modal-from-right .options-bulets li, .question-modal .options li, .question-modal .options-bulets li {
  margin-bottom: 16px; }

.modal-from-right .options span:not(.count):not(.img-holder), .modal-from-right .options-bulets span:not(.count):not(.img-holder), .question-modal .options span:not(.count):not(.img-holder), .question-modal .options-bulets span:not(.count):not(.img-holder) {
  font-weight: 500;
  color: #191919;
  font-size: 14px; }

.modal-from-right .options .count, .modal-from-right .options-bulets .count, .question-modal .options .count, .question-modal .options-bulets .count {
  border-radius: 32px !important;
  font-size: 13px;
  padding: 0 6px;
  margin-left: 10px; }

.modal-from-right .options .img-holder, .modal-from-right .options-bulets .img-holder, .question-modal .options .img-holder, .question-modal .options-bulets .img-holder {
  margin: 0 16px; }

.img-holder {
  position: relative;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  display: inline-block;
  vertical-align: middle; }
  .img-holder > * {
    position: absolute;
    margin: 0; }
    .img-holder > *:first-child {
      top: 0; }
    .img-holder > *:last-child {
      bottom: 0;
      right: 0; }

.modal-from-right .number-count {
  display: inline-block;
  width: auto;
  height: 20px;
  border-radius: 2px;
  background: #e2e1dd;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  border: none;
  padding: 0 5px; }
  .modal-from-right .number-count .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

.modal-from-right .questions {
  min-height: auto; }

.modal-from-right .content-container .main-sidebar {
  min-height: calc(100vh - 77px); }
  .modal-from-right .content-container .main-sidebar > ul li {
    border: none; }

.modal-from-right .modal-dialog {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  position: absolute;
  margin: 0; }

.modal-from-right .modal-content {
  border: none;
  border-radius: 0; }

.modal-from-right .modal-header {
  background: #E2E1DD;
  border-radius: 0;
  border: none; }
  .modal-from-right .modal-header .close {
    background: #fbfaf6;
    z-index: 999;
    opacity: 1;
    font-size: 13px;
    padding: 16px 22px;
    text-shadow: none;
    position: absolute;
    text-transform: uppercase; }
    .modal-from-right .modal-header .close:focus {
      outline: none; }
    .modal-from-right .modal-header .close:hover {
      opacity: 1; }
    .modal-from-right .modal-header .close i {
      display: block;
      font-size: 18px; }
    .modal-from-right .modal-header .close span {
      opacity: 0.3;
      color: #323231;
      font-size: 10px; }

.modal-from-right .modal-title {
  line-height: 31px;
  color: #323231; }

.modal-from-right .nav-tabs {
  display: table;
  width: 100%; }
  .modal-from-right .nav-tabs li {
    display: table-cell; }
  .modal-from-right .nav-tabs a {
    display: block;
    border: none;
    text-align: center;
    border-bottom: 3px solid #EEEDE9;
    color: #323231;
    padding: 13px 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600; }
    .modal-from-right .nav-tabs a:not(.active) {
      opacity: 0.3;
      background: #EEEDE9; }
    .modal-from-right .nav-tabs a.active {
      background: #fbfaf6;
      border-bottom-color: #0099ff; }

.modal-from-right .modal-body {
  min-height: calc(100vh - 63px - 77px);
  background: #fbfaf6; }

.modal-from-right .modal-footer {
  background-color: #fbfaf6; }

.multioptions {
  background: #eeede9;
  padding: 16px 16px 56px 16px;
  margin-top: 14px; }
  .multioptions ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .multioptions li:not(:last-child) {
    margin-bottom: 16px; }

.content-box.black-bg {
  color: #fff; }
  .content-box.black-bg ul {
    margin: 0 -15px; }
  .content-box.black-bg li {
    padding: 15px;
    margin: 0 !important; }
  .content-box.black-bg .tick-radio .form-radio:checked {
    background: #fff !important;
    border-color: #fff; }
    .content-box.black-bg .tick-radio .form-radio:checked:before {
      color: #333; }
  .content-box.black-bg label {
    color: #fff !important; }
  .content-box.black-bg .circle {
    display: inline-block;
    width: 72px;
    height: 72px;
    border-radius: 100%;
    background: none;
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    line-height: 82px;
    border: 1px solid; }
    .content-box.black-bg .circle .percentage {
      background: #5dbe91;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
    .content-box.black-bg .circle.danger-cricle {
      color: #ff6f61 !important;
      border-color: #ff6f61 !important; }
    .content-box.black-bg .circle.success-cricle {
      color: #39b27b !important;
      border-color: #39b27b !important; }
    .content-box.black-bg .circle i {
      font-size: 30px;
      line-height: 38px; }

.video-statistics {
  max-width: 450px;
  margin: 0 auto; }
  .video-statistics ul {
    list-style: none; }
    .video-statistics ul li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
      .video-statistics ul li h4, .video-statistics ul li span {
        margin-top: 8px; }
  .video-statistics > h4 {
    border-bottom: 3px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px; }
  .video-statistics span {
    display: inline-block;
    opacity: 0.8;
    line-height: 24px; }

.content-box.black-bg .video-statistics ul, .content-box.black-bg .video-statistics li {
  padding: 0;
  margin: 0; }

.aperture {
  background: rgba(0, 0, 0, 0.6);
  padding: 30px; }
  .aperture .aperture-setting {
    background: #fbfaf6; }
    .aperture .aperture-setting > .media {
      padding: 20px; }
    .aperture .aperture-setting .aperture-table {
      padding: 20px 0; }
    .aperture .aperture-setting table tr:first-child td {
      padding-top: 14px; }
    .aperture .aperture-setting table th:nth-child(2) div, .aperture .aperture-setting table td:nth-child(2) div {
      width: 174px;
      display: inline-flex;
      align-items: flex-end; }
    .aperture .aperture-setting table th {
      font-size: 14px;
      font-weight: 500;
      padding-top: 12px;
      border-top: none;
      border-bottom: 2px solid #dee2e6; }
    .aperture .aperture-setting table th, .aperture .aperture-setting table td {
      color: #323231; }
      .aperture .aperture-setting table th:first-child, .aperture .aperture-setting table td:first-child {
        padding-left: 20px; }
      .aperture .aperture-setting table th:nth-child(2), .aperture .aperture-setting table td:nth-child(2) {
        text-align: center; }
      .aperture .aperture-setting table th:last-child, .aperture .aperture-setting table td:last-child {
        text-align: right;
        padding-right: 20px; }
    .aperture .aperture-setting table td {
      border: none;
      font-size: 14px;
      vertical-align: middle;
      background: #eeede9;
      padding-top: 8px;
      padding-bottom: 8px; }
      .aperture .aperture-setting table td:nth-child(2) div {
        letter-spacing: 2px; }
        .aperture .aperture-setting table td:nth-child(2) div span, .aperture .aperture-setting table td:nth-child(2) div small {
          color: #979694; }
        .aperture .aperture-setting table td:nth-child(2) div small {
          font-size: 12px; }
        .aperture .aperture-setting table td:nth-child(2) div i {
          font-size: 18px; }
    .aperture .aperture-setting table tr:nth-of-type(odd) td {
      background: #fbfaf6; }

/* Anouncement Section */
.create-announcement {
  padding-top: 60px; }
  .create-announcement p {
    font-size: 15px;
    color: #646462; }

.main-sidebar .options i {
  font-size: 18px; }

.main-sidebar .options a, .main-sidebar .options i {
  color: #646462;
  text-decoration: none; }

.main-sidebar .options svg g {
  fill: #646462; }

.main-sidebar .options > * {
  min-width: 24px;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; }

.main-sidebar .search:not(.show) {
  visibility: hidden;
  z-index: -1; }

.main-sidebar .search .input-group:before {
  content: "search";
  font-family: 'Material Icons';
  position: absolute;
  right: 5px;
  top: 4px;
  z-index: 9;
  color: rgba(25, 29, 33, 0.7); }

.main-sidebar .search .input-group-text {
  border: none;
  padding-left: 0;
  background: #dbdad7;
  cursor: pointer; }

.main-sidebar .search .form-control {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: none;
  color: rgba(25, 29, 33, 0.6);
  font-size: 14px;
  border-radius: 4px !important; }

.main-sidebar .search i {
  font-size: 18px; }

.main-sidebar .search {
  top: 11px;
  right: 12px;
  bottom: auto;
  left: 14px;
  position: absolute; }

.announcementTabs .nav-item {
  position: relative; }
  .announcementTabs .nav-item .nav-link {
    border-color: #e2e1dd; }

.announcementTabs .count {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #39b27b !important;
  font-size: 9px;
  text-align: center;
  font-weight: 500;
  line-height: 14px;
  border: none;
  color: #fff;
  position: absolute;
  right: 3px;
  top: 3px; }
  .announcementTabs .count .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

.announcementTabs .media {
  padding: 16px;
  align-items: flex-start;
  cursor: pointer; }
  .announcementTabs .media > img {
    width: 32px; }
  .announcementTabs .media.active {
    background: #fbfaf6;
    border-right: 1px solid #eeede9; }

.announcementTabs .media-body {
  margin-left: 20px; }
  .announcementTabs .media-body h5 {
    font-size: 15px;
    font-weight: 600;
    color: #323232; }
  .announcementTabs .media-body h6 {
    font-size: 14px;
    color: #979694;
    font-weight: 400;
    line-height: 18px;
    margin: 0; }
    .announcementTabs .media-body h6 span {
      color: #323232; }

.announcementTabs .announcement-type {
  color: #979694;
  font-size: 12px; }

/*Announcement Detail*/
.announcement-heading {
  border-bottom: 1px solid #e2e1dd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 17px; }
  .announcement-heading h6 {
    margin: 0; }

.announcement-detail-content .announcement-stats, .announcement-detail-content .stats-col, .stats-row .announcement-stats, .stats-row .stats-col {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }
  .announcement-detail-content .announcement-stats .circle, .announcement-detail-content .stats-col .circle, .stats-row .announcement-stats .circle, .stats-row .stats-col .circle {
    text-align: center;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: #fbfaf6;
    display: flex;
    justify-content: center;
    align-items: center; }
  .announcement-detail-content .announcement-stats .active-border, .announcement-detail-content .stats-col .active-border, .stats-row .announcement-stats .active-border, .stats-row .stats-col .active-border {
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center; }
  .announcement-detail-content .announcement-stats .uploading-file:before, .announcement-detail-content .stats-col .uploading-file:before, .stats-row .announcement-stats .uploading-file:before, .stats-row .stats-col .uploading-file:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
    transform: rotate(-180deg); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="0"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="0"]:before, .announcement-detail-content .stats-col .active-border[data-progress="0"], .announcement-detail-content .stats-col .uploading-file[data-progress="0"]:before, .stats-row .announcement-stats .active-border[data-progress="0"], .stats-row .announcement-stats .uploading-file[data-progress="0"]:before, .stats-row .stats-col .active-border[data-progress="0"], .stats-row .stats-col .uploading-file[data-progress="0"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="1"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="1"]:before, .announcement-detail-content .stats-col .active-border[data-progress="1"], .announcement-detail-content .stats-col .uploading-file[data-progress="1"]:before, .stats-row .announcement-stats .active-border[data-progress="1"], .stats-row .announcement-stats .uploading-file[data-progress="1"]:before, .stats-row .stats-col .active-border[data-progress="1"], .stats-row .stats-col .uploading-file[data-progress="1"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="2"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="2"]:before, .announcement-detail-content .stats-col .active-border[data-progress="2"], .announcement-detail-content .stats-col .uploading-file[data-progress="2"]:before, .stats-row .announcement-stats .active-border[data-progress="2"], .stats-row .announcement-stats .uploading-file[data-progress="2"]:before, .stats-row .stats-col .active-border[data-progress="2"], .stats-row .stats-col .uploading-file[data-progress="2"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="3"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="3"]:before, .announcement-detail-content .stats-col .active-border[data-progress="3"], .announcement-detail-content .stats-col .uploading-file[data-progress="3"]:before, .stats-row .announcement-stats .active-border[data-progress="3"], .stats-row .announcement-stats .uploading-file[data-progress="3"]:before, .stats-row .stats-col .active-border[data-progress="3"], .stats-row .stats-col .uploading-file[data-progress="3"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="4"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="4"]:before, .announcement-detail-content .stats-col .active-border[data-progress="4"], .announcement-detail-content .stats-col .uploading-file[data-progress="4"]:before, .stats-row .announcement-stats .active-border[data-progress="4"], .stats-row .announcement-stats .uploading-file[data-progress="4"]:before, .stats-row .stats-col .active-border[data-progress="4"], .stats-row .stats-col .uploading-file[data-progress="4"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="5"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="5"]:before, .announcement-detail-content .stats-col .active-border[data-progress="5"], .announcement-detail-content .stats-col .uploading-file[data-progress="5"]:before, .stats-row .announcement-stats .active-border[data-progress="5"], .stats-row .announcement-stats .uploading-file[data-progress="5"]:before, .stats-row .stats-col .active-border[data-progress="5"], .stats-row .stats-col .uploading-file[data-progress="5"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="6"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="6"]:before, .announcement-detail-content .stats-col .active-border[data-progress="6"], .announcement-detail-content .stats-col .uploading-file[data-progress="6"]:before, .stats-row .announcement-stats .active-border[data-progress="6"], .stats-row .announcement-stats .uploading-file[data-progress="6"]:before, .stats-row .stats-col .active-border[data-progress="6"], .stats-row .stats-col .uploading-file[data-progress="6"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="7"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="7"]:before, .announcement-detail-content .stats-col .active-border[data-progress="7"], .announcement-detail-content .stats-col .uploading-file[data-progress="7"]:before, .stats-row .announcement-stats .active-border[data-progress="7"], .stats-row .announcement-stats .uploading-file[data-progress="7"]:before, .stats-row .stats-col .active-border[data-progress="7"], .stats-row .stats-col .uploading-file[data-progress="7"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="8"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="8"]:before, .announcement-detail-content .stats-col .active-border[data-progress="8"], .announcement-detail-content .stats-col .uploading-file[data-progress="8"]:before, .stats-row .announcement-stats .active-border[data-progress="8"], .stats-row .announcement-stats .uploading-file[data-progress="8"]:before, .stats-row .stats-col .active-border[data-progress="8"], .stats-row .stats-col .uploading-file[data-progress="8"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="9"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="9"]:before, .announcement-detail-content .stats-col .active-border[data-progress="9"], .announcement-detail-content .stats-col .uploading-file[data-progress="9"]:before, .stats-row .announcement-stats .active-border[data-progress="9"], .stats-row .announcement-stats .uploading-file[data-progress="9"]:before, .stats-row .stats-col .active-border[data-progress="9"], .stats-row .stats-col .uploading-file[data-progress="9"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="10"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="10"]:before, .announcement-detail-content .stats-col .active-border[data-progress="10"], .announcement-detail-content .stats-col .uploading-file[data-progress="10"]:before, .stats-row .announcement-stats .active-border[data-progress="10"], .stats-row .announcement-stats .uploading-file[data-progress="10"]:before, .stats-row .stats-col .active-border[data-progress="10"], .stats-row .stats-col .uploading-file[data-progress="10"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="11"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="11"]:before, .announcement-detail-content .stats-col .active-border[data-progress="11"], .announcement-detail-content .stats-col .uploading-file[data-progress="11"]:before, .stats-row .announcement-stats .active-border[data-progress="11"], .stats-row .announcement-stats .uploading-file[data-progress="11"]:before, .stats-row .stats-col .active-border[data-progress="11"], .stats-row .stats-col .uploading-file[data-progress="11"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="12"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="12"]:before, .announcement-detail-content .stats-col .active-border[data-progress="12"], .announcement-detail-content .stats-col .uploading-file[data-progress="12"]:before, .stats-row .announcement-stats .active-border[data-progress="12"], .stats-row .announcement-stats .uploading-file[data-progress="12"]:before, .stats-row .stats-col .active-border[data-progress="12"], .stats-row .stats-col .uploading-file[data-progress="12"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="13"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="13"]:before, .announcement-detail-content .stats-col .active-border[data-progress="13"], .announcement-detail-content .stats-col .uploading-file[data-progress="13"]:before, .stats-row .announcement-stats .active-border[data-progress="13"], .stats-row .announcement-stats .uploading-file[data-progress="13"]:before, .stats-row .stats-col .active-border[data-progress="13"], .stats-row .stats-col .uploading-file[data-progress="13"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="14"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="14"]:before, .announcement-detail-content .stats-col .active-border[data-progress="14"], .announcement-detail-content .stats-col .uploading-file[data-progress="14"]:before, .stats-row .announcement-stats .active-border[data-progress="14"], .stats-row .announcement-stats .uploading-file[data-progress="14"]:before, .stats-row .stats-col .active-border[data-progress="14"], .stats-row .stats-col .uploading-file[data-progress="14"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="15"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="15"]:before, .announcement-detail-content .stats-col .active-border[data-progress="15"], .announcement-detail-content .stats-col .uploading-file[data-progress="15"]:before, .stats-row .announcement-stats .active-border[data-progress="15"], .stats-row .announcement-stats .uploading-file[data-progress="15"]:before, .stats-row .stats-col .active-border[data-progress="15"], .stats-row .stats-col .uploading-file[data-progress="15"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="16"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="16"]:before, .announcement-detail-content .stats-col .active-border[data-progress="16"], .announcement-detail-content .stats-col .uploading-file[data-progress="16"]:before, .stats-row .announcement-stats .active-border[data-progress="16"], .stats-row .announcement-stats .uploading-file[data-progress="16"]:before, .stats-row .stats-col .active-border[data-progress="16"], .stats-row .stats-col .uploading-file[data-progress="16"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="17"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="17"]:before, .announcement-detail-content .stats-col .active-border[data-progress="17"], .announcement-detail-content .stats-col .uploading-file[data-progress="17"]:before, .stats-row .announcement-stats .active-border[data-progress="17"], .stats-row .announcement-stats .uploading-file[data-progress="17"]:before, .stats-row .stats-col .active-border[data-progress="17"], .stats-row .stats-col .uploading-file[data-progress="17"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="18"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="18"]:before, .announcement-detail-content .stats-col .active-border[data-progress="18"], .announcement-detail-content .stats-col .uploading-file[data-progress="18"]:before, .stats-row .announcement-stats .active-border[data-progress="18"], .stats-row .announcement-stats .uploading-file[data-progress="18"]:before, .stats-row .stats-col .active-border[data-progress="18"], .stats-row .stats-col .uploading-file[data-progress="18"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="19"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="19"]:before, .announcement-detail-content .stats-col .active-border[data-progress="19"], .announcement-detail-content .stats-col .uploading-file[data-progress="19"]:before, .stats-row .announcement-stats .active-border[data-progress="19"], .stats-row .announcement-stats .uploading-file[data-progress="19"]:before, .stats-row .stats-col .active-border[data-progress="19"], .stats-row .stats-col .uploading-file[data-progress="19"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="20"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="20"]:before, .announcement-detail-content .stats-col .active-border[data-progress="20"], .announcement-detail-content .stats-col .uploading-file[data-progress="20"]:before, .stats-row .announcement-stats .active-border[data-progress="20"], .stats-row .announcement-stats .uploading-file[data-progress="20"]:before, .stats-row .stats-col .active-border[data-progress="20"], .stats-row .stats-col .uploading-file[data-progress="20"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="21"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="21"]:before, .announcement-detail-content .stats-col .active-border[data-progress="21"], .announcement-detail-content .stats-col .uploading-file[data-progress="21"]:before, .stats-row .announcement-stats .active-border[data-progress="21"], .stats-row .announcement-stats .uploading-file[data-progress="21"]:before, .stats-row .stats-col .active-border[data-progress="21"], .stats-row .stats-col .uploading-file[data-progress="21"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="22"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="22"]:before, .announcement-detail-content .stats-col .active-border[data-progress="22"], .announcement-detail-content .stats-col .uploading-file[data-progress="22"]:before, .stats-row .announcement-stats .active-border[data-progress="22"], .stats-row .announcement-stats .uploading-file[data-progress="22"]:before, .stats-row .stats-col .active-border[data-progress="22"], .stats-row .stats-col .uploading-file[data-progress="22"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="23"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="23"]:before, .announcement-detail-content .stats-col .active-border[data-progress="23"], .announcement-detail-content .stats-col .uploading-file[data-progress="23"]:before, .stats-row .announcement-stats .active-border[data-progress="23"], .stats-row .announcement-stats .uploading-file[data-progress="23"]:before, .stats-row .stats-col .active-border[data-progress="23"], .stats-row .stats-col .uploading-file[data-progress="23"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="24"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="24"]:before, .announcement-detail-content .stats-col .active-border[data-progress="24"], .announcement-detail-content .stats-col .uploading-file[data-progress="24"]:before, .stats-row .announcement-stats .active-border[data-progress="24"], .stats-row .announcement-stats .uploading-file[data-progress="24"]:before, .stats-row .stats-col .active-border[data-progress="24"], .stats-row .stats-col .uploading-file[data-progress="24"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="25"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="25"]:before, .announcement-detail-content .stats-col .active-border[data-progress="25"], .announcement-detail-content .stats-col .uploading-file[data-progress="25"]:before, .stats-row .announcement-stats .active-border[data-progress="25"], .stats-row .announcement-stats .uploading-file[data-progress="25"]:before, .stats-row .stats-col .active-border[data-progress="25"], .stats-row .stats-col .uploading-file[data-progress="25"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="26"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="26"]:before, .announcement-detail-content .stats-col .active-border[data-progress="26"], .announcement-detail-content .stats-col .uploading-file[data-progress="26"]:before, .stats-row .announcement-stats .active-border[data-progress="26"], .stats-row .announcement-stats .uploading-file[data-progress="26"]:before, .stats-row .stats-col .active-border[data-progress="26"], .stats-row .stats-col .uploading-file[data-progress="26"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="27"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="27"]:before, .announcement-detail-content .stats-col .active-border[data-progress="27"], .announcement-detail-content .stats-col .uploading-file[data-progress="27"]:before, .stats-row .announcement-stats .active-border[data-progress="27"], .stats-row .announcement-stats .uploading-file[data-progress="27"]:before, .stats-row .stats-col .active-border[data-progress="27"], .stats-row .stats-col .uploading-file[data-progress="27"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="28"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="28"]:before, .announcement-detail-content .stats-col .active-border[data-progress="28"], .announcement-detail-content .stats-col .uploading-file[data-progress="28"]:before, .stats-row .announcement-stats .active-border[data-progress="28"], .stats-row .announcement-stats .uploading-file[data-progress="28"]:before, .stats-row .stats-col .active-border[data-progress="28"], .stats-row .stats-col .uploading-file[data-progress="28"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="29"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="29"]:before, .announcement-detail-content .stats-col .active-border[data-progress="29"], .announcement-detail-content .stats-col .uploading-file[data-progress="29"]:before, .stats-row .announcement-stats .active-border[data-progress="29"], .stats-row .announcement-stats .uploading-file[data-progress="29"]:before, .stats-row .stats-col .active-border[data-progress="29"], .stats-row .stats-col .uploading-file[data-progress="29"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="30"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="30"]:before, .announcement-detail-content .stats-col .active-border[data-progress="30"], .announcement-detail-content .stats-col .uploading-file[data-progress="30"]:before, .stats-row .announcement-stats .active-border[data-progress="30"], .stats-row .announcement-stats .uploading-file[data-progress="30"]:before, .stats-row .stats-col .active-border[data-progress="30"], .stats-row .stats-col .uploading-file[data-progress="30"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="31"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="31"]:before, .announcement-detail-content .stats-col .active-border[data-progress="31"], .announcement-detail-content .stats-col .uploading-file[data-progress="31"]:before, .stats-row .announcement-stats .active-border[data-progress="31"], .stats-row .announcement-stats .uploading-file[data-progress="31"]:before, .stats-row .stats-col .active-border[data-progress="31"], .stats-row .stats-col .uploading-file[data-progress="31"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="32"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="32"]:before, .announcement-detail-content .stats-col .active-border[data-progress="32"], .announcement-detail-content .stats-col .uploading-file[data-progress="32"]:before, .stats-row .announcement-stats .active-border[data-progress="32"], .stats-row .announcement-stats .uploading-file[data-progress="32"]:before, .stats-row .stats-col .active-border[data-progress="32"], .stats-row .stats-col .uploading-file[data-progress="32"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="33"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="33"]:before, .announcement-detail-content .stats-col .active-border[data-progress="33"], .announcement-detail-content .stats-col .uploading-file[data-progress="33"]:before, .stats-row .announcement-stats .active-border[data-progress="33"], .stats-row .announcement-stats .uploading-file[data-progress="33"]:before, .stats-row .stats-col .active-border[data-progress="33"], .stats-row .stats-col .uploading-file[data-progress="33"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="34"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="34"]:before, .announcement-detail-content .stats-col .active-border[data-progress="34"], .announcement-detail-content .stats-col .uploading-file[data-progress="34"]:before, .stats-row .announcement-stats .active-border[data-progress="34"], .stats-row .announcement-stats .uploading-file[data-progress="34"]:before, .stats-row .stats-col .active-border[data-progress="34"], .stats-row .stats-col .uploading-file[data-progress="34"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="35"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="35"]:before, .announcement-detail-content .stats-col .active-border[data-progress="35"], .announcement-detail-content .stats-col .uploading-file[data-progress="35"]:before, .stats-row .announcement-stats .active-border[data-progress="35"], .stats-row .announcement-stats .uploading-file[data-progress="35"]:before, .stats-row .stats-col .active-border[data-progress="35"], .stats-row .stats-col .uploading-file[data-progress="35"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="36"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="36"]:before, .announcement-detail-content .stats-col .active-border[data-progress="36"], .announcement-detail-content .stats-col .uploading-file[data-progress="36"]:before, .stats-row .announcement-stats .active-border[data-progress="36"], .stats-row .announcement-stats .uploading-file[data-progress="36"]:before, .stats-row .stats-col .active-border[data-progress="36"], .stats-row .stats-col .uploading-file[data-progress="36"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="37"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="37"]:before, .announcement-detail-content .stats-col .active-border[data-progress="37"], .announcement-detail-content .stats-col .uploading-file[data-progress="37"]:before, .stats-row .announcement-stats .active-border[data-progress="37"], .stats-row .announcement-stats .uploading-file[data-progress="37"]:before, .stats-row .stats-col .active-border[data-progress="37"], .stats-row .stats-col .uploading-file[data-progress="37"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="38"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="38"]:before, .announcement-detail-content .stats-col .active-border[data-progress="38"], .announcement-detail-content .stats-col .uploading-file[data-progress="38"]:before, .stats-row .announcement-stats .active-border[data-progress="38"], .stats-row .announcement-stats .uploading-file[data-progress="38"]:before, .stats-row .stats-col .active-border[data-progress="38"], .stats-row .stats-col .uploading-file[data-progress="38"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="39"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="39"]:before, .announcement-detail-content .stats-col .active-border[data-progress="39"], .announcement-detail-content .stats-col .uploading-file[data-progress="39"]:before, .stats-row .announcement-stats .active-border[data-progress="39"], .stats-row .announcement-stats .uploading-file[data-progress="39"]:before, .stats-row .stats-col .active-border[data-progress="39"], .stats-row .stats-col .uploading-file[data-progress="39"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="40"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="40"]:before, .announcement-detail-content .stats-col .active-border[data-progress="40"], .announcement-detail-content .stats-col .uploading-file[data-progress="40"]:before, .stats-row .announcement-stats .active-border[data-progress="40"], .stats-row .announcement-stats .uploading-file[data-progress="40"]:before, .stats-row .stats-col .active-border[data-progress="40"], .stats-row .stats-col .uploading-file[data-progress="40"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="41"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="41"]:before, .announcement-detail-content .stats-col .active-border[data-progress="41"], .announcement-detail-content .stats-col .uploading-file[data-progress="41"]:before, .stats-row .announcement-stats .active-border[data-progress="41"], .stats-row .announcement-stats .uploading-file[data-progress="41"]:before, .stats-row .stats-col .active-border[data-progress="41"], .stats-row .stats-col .uploading-file[data-progress="41"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="42"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="42"]:before, .announcement-detail-content .stats-col .active-border[data-progress="42"], .announcement-detail-content .stats-col .uploading-file[data-progress="42"]:before, .stats-row .announcement-stats .active-border[data-progress="42"], .stats-row .announcement-stats .uploading-file[data-progress="42"]:before, .stats-row .stats-col .active-border[data-progress="42"], .stats-row .stats-col .uploading-file[data-progress="42"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="43"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="43"]:before, .announcement-detail-content .stats-col .active-border[data-progress="43"], .announcement-detail-content .stats-col .uploading-file[data-progress="43"]:before, .stats-row .announcement-stats .active-border[data-progress="43"], .stats-row .announcement-stats .uploading-file[data-progress="43"]:before, .stats-row .stats-col .active-border[data-progress="43"], .stats-row .stats-col .uploading-file[data-progress="43"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="44"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="44"]:before, .announcement-detail-content .stats-col .active-border[data-progress="44"], .announcement-detail-content .stats-col .uploading-file[data-progress="44"]:before, .stats-row .announcement-stats .active-border[data-progress="44"], .stats-row .announcement-stats .uploading-file[data-progress="44"]:before, .stats-row .stats-col .active-border[data-progress="44"], .stats-row .stats-col .uploading-file[data-progress="44"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="45"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="45"]:before, .announcement-detail-content .stats-col .active-border[data-progress="45"], .announcement-detail-content .stats-col .uploading-file[data-progress="45"]:before, .stats-row .announcement-stats .active-border[data-progress="45"], .stats-row .announcement-stats .uploading-file[data-progress="45"]:before, .stats-row .stats-col .active-border[data-progress="45"], .stats-row .stats-col .uploading-file[data-progress="45"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="46"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="46"]:before, .announcement-detail-content .stats-col .active-border[data-progress="46"], .announcement-detail-content .stats-col .uploading-file[data-progress="46"]:before, .stats-row .announcement-stats .active-border[data-progress="46"], .stats-row .announcement-stats .uploading-file[data-progress="46"]:before, .stats-row .stats-col .active-border[data-progress="46"], .stats-row .stats-col .uploading-file[data-progress="46"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="47"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="47"]:before, .announcement-detail-content .stats-col .active-border[data-progress="47"], .announcement-detail-content .stats-col .uploading-file[data-progress="47"]:before, .stats-row .announcement-stats .active-border[data-progress="47"], .stats-row .announcement-stats .uploading-file[data-progress="47"]:before, .stats-row .stats-col .active-border[data-progress="47"], .stats-row .stats-col .uploading-file[data-progress="47"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="48"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="48"]:before, .announcement-detail-content .stats-col .active-border[data-progress="48"], .announcement-detail-content .stats-col .uploading-file[data-progress="48"]:before, .stats-row .announcement-stats .active-border[data-progress="48"], .stats-row .announcement-stats .uploading-file[data-progress="48"]:before, .stats-row .stats-col .active-border[data-progress="48"], .stats-row .stats-col .uploading-file[data-progress="48"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="49"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="49"]:before, .announcement-detail-content .stats-col .active-border[data-progress="49"], .announcement-detail-content .stats-col .uploading-file[data-progress="49"]:before, .stats-row .announcement-stats .active-border[data-progress="49"], .stats-row .announcement-stats .uploading-file[data-progress="49"]:before, .stats-row .stats-col .active-border[data-progress="49"], .stats-row .stats-col .uploading-file[data-progress="49"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="50"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="50"]:before, .announcement-detail-content .stats-col .active-border[data-progress="50"], .announcement-detail-content .stats-col .uploading-file[data-progress="50"]:before, .stats-row .announcement-stats .active-border[data-progress="50"], .stats-row .announcement-stats .uploading-file[data-progress="50"]:before, .stats-row .stats-col .active-border[data-progress="50"], .stats-row .stats-col .uploading-file[data-progress="50"]:before {
    background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="51"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="51"]:before, .announcement-detail-content .stats-col .active-border[data-progress="51"], .announcement-detail-content .stats-col .uploading-file[data-progress="51"]:before, .stats-row .announcement-stats .active-border[data-progress="51"], .stats-row .announcement-stats .uploading-file[data-progress="51"]:before, .stats-row .stats-col .active-border[data-progress="51"], .stats-row .stats-col .uploading-file[data-progress="51"]:before {
    background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="52"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="52"]:before, .announcement-detail-content .stats-col .active-border[data-progress="52"], .announcement-detail-content .stats-col .uploading-file[data-progress="52"]:before, .stats-row .announcement-stats .active-border[data-progress="52"], .stats-row .announcement-stats .uploading-file[data-progress="52"]:before, .stats-row .stats-col .active-border[data-progress="52"], .stats-row .stats-col .uploading-file[data-progress="52"]:before {
    background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="53"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="53"]:before, .announcement-detail-content .stats-col .active-border[data-progress="53"], .announcement-detail-content .stats-col .uploading-file[data-progress="53"]:before, .stats-row .announcement-stats .active-border[data-progress="53"], .stats-row .announcement-stats .uploading-file[data-progress="53"]:before, .stats-row .stats-col .active-border[data-progress="53"], .stats-row .stats-col .uploading-file[data-progress="53"]:before {
    background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="54"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="54"]:before, .announcement-detail-content .stats-col .active-border[data-progress="54"], .announcement-detail-content .stats-col .uploading-file[data-progress="54"]:before, .stats-row .announcement-stats .active-border[data-progress="54"], .stats-row .announcement-stats .uploading-file[data-progress="54"]:before, .stats-row .stats-col .active-border[data-progress="54"], .stats-row .stats-col .uploading-file[data-progress="54"]:before {
    background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="55"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="55"]:before, .announcement-detail-content .stats-col .active-border[data-progress="55"], .announcement-detail-content .stats-col .uploading-file[data-progress="55"]:before, .stats-row .announcement-stats .active-border[data-progress="55"], .stats-row .announcement-stats .uploading-file[data-progress="55"]:before, .stats-row .stats-col .active-border[data-progress="55"], .stats-row .stats-col .uploading-file[data-progress="55"]:before {
    background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="56"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="56"]:before, .announcement-detail-content .stats-col .active-border[data-progress="56"], .announcement-detail-content .stats-col .uploading-file[data-progress="56"]:before, .stats-row .announcement-stats .active-border[data-progress="56"], .stats-row .announcement-stats .uploading-file[data-progress="56"]:before, .stats-row .stats-col .active-border[data-progress="56"], .stats-row .stats-col .uploading-file[data-progress="56"]:before {
    background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="57"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="57"]:before, .announcement-detail-content .stats-col .active-border[data-progress="57"], .announcement-detail-content .stats-col .uploading-file[data-progress="57"]:before, .stats-row .announcement-stats .active-border[data-progress="57"], .stats-row .announcement-stats .uploading-file[data-progress="57"]:before, .stats-row .stats-col .active-border[data-progress="57"], .stats-row .stats-col .uploading-file[data-progress="57"]:before {
    background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="58"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="58"]:before, .announcement-detail-content .stats-col .active-border[data-progress="58"], .announcement-detail-content .stats-col .uploading-file[data-progress="58"]:before, .stats-row .announcement-stats .active-border[data-progress="58"], .stats-row .announcement-stats .uploading-file[data-progress="58"]:before, .stats-row .stats-col .active-border[data-progress="58"], .stats-row .stats-col .uploading-file[data-progress="58"]:before {
    background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="59"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="59"]:before, .announcement-detail-content .stats-col .active-border[data-progress="59"], .announcement-detail-content .stats-col .uploading-file[data-progress="59"]:before, .stats-row .announcement-stats .active-border[data-progress="59"], .stats-row .announcement-stats .uploading-file[data-progress="59"]:before, .stats-row .stats-col .active-border[data-progress="59"], .stats-row .stats-col .uploading-file[data-progress="59"]:before {
    background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="60"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="60"]:before, .announcement-detail-content .stats-col .active-border[data-progress="60"], .announcement-detail-content .stats-col .uploading-file[data-progress="60"]:before, .stats-row .announcement-stats .active-border[data-progress="60"], .stats-row .announcement-stats .uploading-file[data-progress="60"]:before, .stats-row .stats-col .active-border[data-progress="60"], .stats-row .stats-col .uploading-file[data-progress="60"]:before {
    background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="61"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="61"]:before, .announcement-detail-content .stats-col .active-border[data-progress="61"], .announcement-detail-content .stats-col .uploading-file[data-progress="61"]:before, .stats-row .announcement-stats .active-border[data-progress="61"], .stats-row .announcement-stats .uploading-file[data-progress="61"]:before, .stats-row .stats-col .active-border[data-progress="61"], .stats-row .stats-col .uploading-file[data-progress="61"]:before {
    background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="62"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="62"]:before, .announcement-detail-content .stats-col .active-border[data-progress="62"], .announcement-detail-content .stats-col .uploading-file[data-progress="62"]:before, .stats-row .announcement-stats .active-border[data-progress="62"], .stats-row .announcement-stats .uploading-file[data-progress="62"]:before, .stats-row .stats-col .active-border[data-progress="62"], .stats-row .stats-col .uploading-file[data-progress="62"]:before {
    background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="63"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="63"]:before, .announcement-detail-content .stats-col .active-border[data-progress="63"], .announcement-detail-content .stats-col .uploading-file[data-progress="63"]:before, .stats-row .announcement-stats .active-border[data-progress="63"], .stats-row .announcement-stats .uploading-file[data-progress="63"]:before, .stats-row .stats-col .active-border[data-progress="63"], .stats-row .stats-col .uploading-file[data-progress="63"]:before {
    background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="64"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="64"]:before, .announcement-detail-content .stats-col .active-border[data-progress="64"], .announcement-detail-content .stats-col .uploading-file[data-progress="64"]:before, .stats-row .announcement-stats .active-border[data-progress="64"], .stats-row .announcement-stats .uploading-file[data-progress="64"]:before, .stats-row .stats-col .active-border[data-progress="64"], .stats-row .stats-col .uploading-file[data-progress="64"]:before {
    background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="65"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="65"]:before, .announcement-detail-content .stats-col .active-border[data-progress="65"], .announcement-detail-content .stats-col .uploading-file[data-progress="65"]:before, .stats-row .announcement-stats .active-border[data-progress="65"], .stats-row .announcement-stats .uploading-file[data-progress="65"]:before, .stats-row .stats-col .active-border[data-progress="65"], .stats-row .stats-col .uploading-file[data-progress="65"]:before {
    background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="66"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="66"]:before, .announcement-detail-content .stats-col .active-border[data-progress="66"], .announcement-detail-content .stats-col .uploading-file[data-progress="66"]:before, .stats-row .announcement-stats .active-border[data-progress="66"], .stats-row .announcement-stats .uploading-file[data-progress="66"]:before, .stats-row .stats-col .active-border[data-progress="66"], .stats-row .stats-col .uploading-file[data-progress="66"]:before {
    background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="67"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="67"]:before, .announcement-detail-content .stats-col .active-border[data-progress="67"], .announcement-detail-content .stats-col .uploading-file[data-progress="67"]:before, .stats-row .announcement-stats .active-border[data-progress="67"], .stats-row .announcement-stats .uploading-file[data-progress="67"]:before, .stats-row .stats-col .active-border[data-progress="67"], .stats-row .stats-col .uploading-file[data-progress="67"]:before {
    background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="68"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="68"]:before, .announcement-detail-content .stats-col .active-border[data-progress="68"], .announcement-detail-content .stats-col .uploading-file[data-progress="68"]:before, .stats-row .announcement-stats .active-border[data-progress="68"], .stats-row .announcement-stats .uploading-file[data-progress="68"]:before, .stats-row .stats-col .active-border[data-progress="68"], .stats-row .stats-col .uploading-file[data-progress="68"]:before {
    background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="69"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="69"]:before, .announcement-detail-content .stats-col .active-border[data-progress="69"], .announcement-detail-content .stats-col .uploading-file[data-progress="69"]:before, .stats-row .announcement-stats .active-border[data-progress="69"], .stats-row .announcement-stats .uploading-file[data-progress="69"]:before, .stats-row .stats-col .active-border[data-progress="69"], .stats-row .stats-col .uploading-file[data-progress="69"]:before {
    background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="70"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="70"]:before, .announcement-detail-content .stats-col .active-border[data-progress="70"], .announcement-detail-content .stats-col .uploading-file[data-progress="70"]:before, .stats-row .announcement-stats .active-border[data-progress="70"], .stats-row .announcement-stats .uploading-file[data-progress="70"]:before, .stats-row .stats-col .active-border[data-progress="70"], .stats-row .stats-col .uploading-file[data-progress="70"]:before {
    background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="71"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="71"]:before, .announcement-detail-content .stats-col .active-border[data-progress="71"], .announcement-detail-content .stats-col .uploading-file[data-progress="71"]:before, .stats-row .announcement-stats .active-border[data-progress="71"], .stats-row .announcement-stats .uploading-file[data-progress="71"]:before, .stats-row .stats-col .active-border[data-progress="71"], .stats-row .stats-col .uploading-file[data-progress="71"]:before {
    background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="72"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="72"]:before, .announcement-detail-content .stats-col .active-border[data-progress="72"], .announcement-detail-content .stats-col .uploading-file[data-progress="72"]:before, .stats-row .announcement-stats .active-border[data-progress="72"], .stats-row .announcement-stats .uploading-file[data-progress="72"]:before, .stats-row .stats-col .active-border[data-progress="72"], .stats-row .stats-col .uploading-file[data-progress="72"]:before {
    background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="73"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="73"]:before, .announcement-detail-content .stats-col .active-border[data-progress="73"], .announcement-detail-content .stats-col .uploading-file[data-progress="73"]:before, .stats-row .announcement-stats .active-border[data-progress="73"], .stats-row .announcement-stats .uploading-file[data-progress="73"]:before, .stats-row .stats-col .active-border[data-progress="73"], .stats-row .stats-col .uploading-file[data-progress="73"]:before {
    background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="74"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="74"]:before, .announcement-detail-content .stats-col .active-border[data-progress="74"], .announcement-detail-content .stats-col .uploading-file[data-progress="74"]:before, .stats-row .announcement-stats .active-border[data-progress="74"], .stats-row .announcement-stats .uploading-file[data-progress="74"]:before, .stats-row .stats-col .active-border[data-progress="74"], .stats-row .stats-col .uploading-file[data-progress="74"]:before {
    background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="75"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="75"]:before, .announcement-detail-content .stats-col .active-border[data-progress="75"], .announcement-detail-content .stats-col .uploading-file[data-progress="75"]:before, .stats-row .announcement-stats .active-border[data-progress="75"], .stats-row .announcement-stats .uploading-file[data-progress="75"]:before, .stats-row .stats-col .active-border[data-progress="75"], .stats-row .stats-col .uploading-file[data-progress="75"]:before {
    background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="76"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="76"]:before, .announcement-detail-content .stats-col .active-border[data-progress="76"], .announcement-detail-content .stats-col .uploading-file[data-progress="76"]:before, .stats-row .announcement-stats .active-border[data-progress="76"], .stats-row .announcement-stats .uploading-file[data-progress="76"]:before, .stats-row .stats-col .active-border[data-progress="76"], .stats-row .stats-col .uploading-file[data-progress="76"]:before {
    background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="77"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="77"]:before, .announcement-detail-content .stats-col .active-border[data-progress="77"], .announcement-detail-content .stats-col .uploading-file[data-progress="77"]:before, .stats-row .announcement-stats .active-border[data-progress="77"], .stats-row .announcement-stats .uploading-file[data-progress="77"]:before, .stats-row .stats-col .active-border[data-progress="77"], .stats-row .stats-col .uploading-file[data-progress="77"]:before {
    background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="78"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="78"]:before, .announcement-detail-content .stats-col .active-border[data-progress="78"], .announcement-detail-content .stats-col .uploading-file[data-progress="78"]:before, .stats-row .announcement-stats .active-border[data-progress="78"], .stats-row .announcement-stats .uploading-file[data-progress="78"]:before, .stats-row .stats-col .active-border[data-progress="78"], .stats-row .stats-col .uploading-file[data-progress="78"]:before {
    background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="79"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="79"]:before, .announcement-detail-content .stats-col .active-border[data-progress="79"], .announcement-detail-content .stats-col .uploading-file[data-progress="79"]:before, .stats-row .announcement-stats .active-border[data-progress="79"], .stats-row .announcement-stats .uploading-file[data-progress="79"]:before, .stats-row .stats-col .active-border[data-progress="79"], .stats-row .stats-col .uploading-file[data-progress="79"]:before {
    background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="80"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="80"]:before, .announcement-detail-content .stats-col .active-border[data-progress="80"], .announcement-detail-content .stats-col .uploading-file[data-progress="80"]:before, .stats-row .announcement-stats .active-border[data-progress="80"], .stats-row .announcement-stats .uploading-file[data-progress="80"]:before, .stats-row .stats-col .active-border[data-progress="80"], .stats-row .stats-col .uploading-file[data-progress="80"]:before {
    background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="81"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="81"]:before, .announcement-detail-content .stats-col .active-border[data-progress="81"], .announcement-detail-content .stats-col .uploading-file[data-progress="81"]:before, .stats-row .announcement-stats .active-border[data-progress="81"], .stats-row .announcement-stats .uploading-file[data-progress="81"]:before, .stats-row .stats-col .active-border[data-progress="81"], .stats-row .stats-col .uploading-file[data-progress="81"]:before {
    background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="82"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="82"]:before, .announcement-detail-content .stats-col .active-border[data-progress="82"], .announcement-detail-content .stats-col .uploading-file[data-progress="82"]:before, .stats-row .announcement-stats .active-border[data-progress="82"], .stats-row .announcement-stats .uploading-file[data-progress="82"]:before, .stats-row .stats-col .active-border[data-progress="82"], .stats-row .stats-col .uploading-file[data-progress="82"]:before {
    background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="83"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="83"]:before, .announcement-detail-content .stats-col .active-border[data-progress="83"], .announcement-detail-content .stats-col .uploading-file[data-progress="83"]:before, .stats-row .announcement-stats .active-border[data-progress="83"], .stats-row .announcement-stats .uploading-file[data-progress="83"]:before, .stats-row .stats-col .active-border[data-progress="83"], .stats-row .stats-col .uploading-file[data-progress="83"]:before {
    background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="84"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="84"]:before, .announcement-detail-content .stats-col .active-border[data-progress="84"], .announcement-detail-content .stats-col .uploading-file[data-progress="84"]:before, .stats-row .announcement-stats .active-border[data-progress="84"], .stats-row .announcement-stats .uploading-file[data-progress="84"]:before, .stats-row .stats-col .active-border[data-progress="84"], .stats-row .stats-col .uploading-file[data-progress="84"]:before {
    background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="85"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="85"]:before, .announcement-detail-content .stats-col .active-border[data-progress="85"], .announcement-detail-content .stats-col .uploading-file[data-progress="85"]:before, .stats-row .announcement-stats .active-border[data-progress="85"], .stats-row .announcement-stats .uploading-file[data-progress="85"]:before, .stats-row .stats-col .active-border[data-progress="85"], .stats-row .stats-col .uploading-file[data-progress="85"]:before {
    background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="86"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="86"]:before, .announcement-detail-content .stats-col .active-border[data-progress="86"], .announcement-detail-content .stats-col .uploading-file[data-progress="86"]:before, .stats-row .announcement-stats .active-border[data-progress="86"], .stats-row .announcement-stats .uploading-file[data-progress="86"]:before, .stats-row .stats-col .active-border[data-progress="86"], .stats-row .stats-col .uploading-file[data-progress="86"]:before {
    background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="87"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="87"]:before, .announcement-detail-content .stats-col .active-border[data-progress="87"], .announcement-detail-content .stats-col .uploading-file[data-progress="87"]:before, .stats-row .announcement-stats .active-border[data-progress="87"], .stats-row .announcement-stats .uploading-file[data-progress="87"]:before, .stats-row .stats-col .active-border[data-progress="87"], .stats-row .stats-col .uploading-file[data-progress="87"]:before {
    background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="88"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="88"]:before, .announcement-detail-content .stats-col .active-border[data-progress="88"], .announcement-detail-content .stats-col .uploading-file[data-progress="88"]:before, .stats-row .announcement-stats .active-border[data-progress="88"], .stats-row .announcement-stats .uploading-file[data-progress="88"]:before, .stats-row .stats-col .active-border[data-progress="88"], .stats-row .stats-col .uploading-file[data-progress="88"]:before {
    background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="89"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="89"]:before, .announcement-detail-content .stats-col .active-border[data-progress="89"], .announcement-detail-content .stats-col .uploading-file[data-progress="89"]:before, .stats-row .announcement-stats .active-border[data-progress="89"], .stats-row .announcement-stats .uploading-file[data-progress="89"]:before, .stats-row .stats-col .active-border[data-progress="89"], .stats-row .stats-col .uploading-file[data-progress="89"]:before {
    background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="90"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="90"]:before, .announcement-detail-content .stats-col .active-border[data-progress="90"], .announcement-detail-content .stats-col .uploading-file[data-progress="90"]:before, .stats-row .announcement-stats .active-border[data-progress="90"], .stats-row .announcement-stats .uploading-file[data-progress="90"]:before, .stats-row .stats-col .active-border[data-progress="90"], .stats-row .stats-col .uploading-file[data-progress="90"]:before {
    background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="91"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="91"]:before, .announcement-detail-content .stats-col .active-border[data-progress="91"], .announcement-detail-content .stats-col .uploading-file[data-progress="91"]:before, .stats-row .announcement-stats .active-border[data-progress="91"], .stats-row .announcement-stats .uploading-file[data-progress="91"]:before, .stats-row .stats-col .active-border[data-progress="91"], .stats-row .stats-col .uploading-file[data-progress="91"]:before {
    background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="92"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="92"]:before, .announcement-detail-content .stats-col .active-border[data-progress="92"], .announcement-detail-content .stats-col .uploading-file[data-progress="92"]:before, .stats-row .announcement-stats .active-border[data-progress="92"], .stats-row .announcement-stats .uploading-file[data-progress="92"]:before, .stats-row .stats-col .active-border[data-progress="92"], .stats-row .stats-col .uploading-file[data-progress="92"]:before {
    background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="93"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="93"]:before, .announcement-detail-content .stats-col .active-border[data-progress="93"], .announcement-detail-content .stats-col .uploading-file[data-progress="93"]:before, .stats-row .announcement-stats .active-border[data-progress="93"], .stats-row .announcement-stats .uploading-file[data-progress="93"]:before, .stats-row .stats-col .active-border[data-progress="93"], .stats-row .stats-col .uploading-file[data-progress="93"]:before {
    background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="94"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="94"]:before, .announcement-detail-content .stats-col .active-border[data-progress="94"], .announcement-detail-content .stats-col .uploading-file[data-progress="94"]:before, .stats-row .announcement-stats .active-border[data-progress="94"], .stats-row .announcement-stats .uploading-file[data-progress="94"]:before, .stats-row .stats-col .active-border[data-progress="94"], .stats-row .stats-col .uploading-file[data-progress="94"]:before {
    background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="95"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="95"]:before, .announcement-detail-content .stats-col .active-border[data-progress="95"], .announcement-detail-content .stats-col .uploading-file[data-progress="95"]:before, .stats-row .announcement-stats .active-border[data-progress="95"], .stats-row .announcement-stats .uploading-file[data-progress="95"]:before, .stats-row .stats-col .active-border[data-progress="95"], .stats-row .stats-col .uploading-file[data-progress="95"]:before {
    background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="96"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="96"]:before, .announcement-detail-content .stats-col .active-border[data-progress="96"], .announcement-detail-content .stats-col .uploading-file[data-progress="96"]:before, .stats-row .announcement-stats .active-border[data-progress="96"], .stats-row .announcement-stats .uploading-file[data-progress="96"]:before, .stats-row .stats-col .active-border[data-progress="96"], .stats-row .stats-col .uploading-file[data-progress="96"]:before {
    background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="97"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="97"]:before, .announcement-detail-content .stats-col .active-border[data-progress="97"], .announcement-detail-content .stats-col .uploading-file[data-progress="97"]:before, .stats-row .announcement-stats .active-border[data-progress="97"], .stats-row .announcement-stats .uploading-file[data-progress="97"]:before, .stats-row .stats-col .active-border[data-progress="97"], .stats-row .stats-col .uploading-file[data-progress="97"]:before {
    background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="98"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="98"]:before, .announcement-detail-content .stats-col .active-border[data-progress="98"], .announcement-detail-content .stats-col .uploading-file[data-progress="98"]:before, .stats-row .announcement-stats .active-border[data-progress="98"], .stats-row .announcement-stats .uploading-file[data-progress="98"]:before, .stats-row .stats-col .active-border[data-progress="98"], .stats-row .stats-col .uploading-file[data-progress="98"]:before {
    background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="99"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="99"]:before, .announcement-detail-content .stats-col .active-border[data-progress="99"], .announcement-detail-content .stats-col .uploading-file[data-progress="99"]:before, .stats-row .announcement-stats .active-border[data-progress="99"], .stats-row .announcement-stats .uploading-file[data-progress="99"]:before, .stats-row .stats-col .active-border[data-progress="99"], .stats-row .stats-col .uploading-file[data-progress="99"]:before {
    background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .active-border[data-progress="100"], .announcement-detail-content .announcement-stats .uploading-file[data-progress="100"]:before, .announcement-detail-content .stats-col .active-border[data-progress="100"], .announcement-detail-content .stats-col .uploading-file[data-progress="100"]:before, .stats-row .announcement-stats .active-border[data-progress="100"], .stats-row .announcement-stats .uploading-file[data-progress="100"]:before, .stats-row .stats-col .active-border[data-progress="100"], .stats-row .stats-col .uploading-file[data-progress="100"]:before {
    background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .announcement-detail-content .announcement-stats .circle, .announcement-detail-content .stats-col .circle, .stats-row .announcement-stats .circle, .stats-row .stats-col .circle {
    font-size: 12px;
    color: rgba(50, 50, 49, 0.5); }
    .announcement-detail-content .announcement-stats .circle small, .announcement-detail-content .stats-col .circle small, .stats-row .announcement-stats .circle small, .stats-row .stats-col .circle small {
      font-size: 10px; }

.announcement-detail-content {
  background: #fbfaf6;
  min-height: 100vh; }
  .announcement-detail-content .user-info {
    padding: 26px 0 0;
    align-items: center; }
    .announcement-detail-content .user-info > * {
      margin-top: 10px; }
    .announcement-detail-content .user-info span {
      color: rgba(50, 50, 49, 0.5); }
    .announcement-detail-content .user-info i {
      color: #b0afac;
      font-size: 18px; }
    .announcement-detail-content .user-info img {
      width: 32px;
      height: 32px; }
    .announcement-detail-content .user-info .category-tag-circle {
      margin-left: 4px; }
  .announcement-detail-content .date {
    color: #979694;
    font-size: 12px; }
  .announcement-detail-content .announcement-content {
    margin-top: 20px; }
    .announcement-detail-content .announcement-content p, .announcement-detail-content .announcement-content li {
      font-size: 15px; }
      .announcement-detail-content .announcement-content p a, .announcement-detail-content .announcement-content li a {
        color: #0099ff; }
    .announcement-detail-content .announcement-content ul li {
      color: #4b4b4a; }
  .announcement-detail-content .upload-files-name .file-name {
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    font-size: 14px;
    align-items: center;
    display: inline-flex;
    height: 36px;
    padding: 0 16px;
    margin: 8px 8px 0 0; }
    .announcement-detail-content .upload-files-name .file-name i {
      font-size: 18px;
      margin-right: 8px; }
    .announcement-detail-content .upload-files-name .file-name span {
      color: #979694;
      margin-left: 10px; }
  .announcement-detail-content .upload-file-display {
    overflow: hidden;
    margin-top: 10px; }
    .announcement-detail-content .upload-file-display .row {
      margin: 0 -7px; }
      .announcement-detail-content .upload-file-display .row > [class*="col-"] {
        padding: 0 7px;
        margin-top: 14px; }
    .announcement-detail-content .upload-file-display img {
      width: 100%; }
  .announcement-detail-content .button-options button {
    margin-top: 10px; }

.announcement-modal .modal-dialog {
  max-width: 730px; }

.announcement-modal .modal-header {
  padding: 0;
  border: none; }

.announcement-modal .modal-body {
  padding: 0; }

.announcement-modal .modal-content {
  padding: 30px;
  background: #fbfaf6; }

.stats-blocks h6 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase; }

.stats-blocks .card {
  margin-top: 20px;
  border: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08); }

.stats-blocks .card-header {
  padding: 17px 5px;
  text-align: center;
  border: none; }

.stats-blocks .card-body {
  padding: 20px 15px; }

.stats-blocks .stats-value {
  display: block;
  line-height: 24px; }

.stats-blocks .counter-label, .stats-blocks .stats-value {
  color: #323231;
  opacity: 0.5;
  font-size: 14px; }

.stats-blocks .report-counter {
  text-align: center; }
  .stats-blocks .report-counter .counter-label {
    font-size: 11px; }
  .stats-blocks .report-counter .circle {
    text-align: center;
    width: 54px;
    height: 54px;
    border-radius: 100%;
    background-color: #eeede9;
    display: flex;
    justify-content: center;
    align-items: center; }
  .stats-blocks .report-counter .active-border {
    text-align: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center; }
  .stats-blocks .report-counter .uploading-file:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
    transform: rotate(-180deg); }
  .stats-blocks .report-counter .active-border[data-progress="0"], .stats-blocks .report-counter .uploading-file[data-progress="0"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="1"], .stats-blocks .report-counter .uploading-file[data-progress="1"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="2"], .stats-blocks .report-counter .uploading-file[data-progress="2"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="3"], .stats-blocks .report-counter .uploading-file[data-progress="3"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="4"], .stats-blocks .report-counter .uploading-file[data-progress="4"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="5"], .stats-blocks .report-counter .uploading-file[data-progress="5"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="6"], .stats-blocks .report-counter .uploading-file[data-progress="6"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="7"], .stats-blocks .report-counter .uploading-file[data-progress="7"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="8"], .stats-blocks .report-counter .uploading-file[data-progress="8"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="9"], .stats-blocks .report-counter .uploading-file[data-progress="9"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="10"], .stats-blocks .report-counter .uploading-file[data-progress="10"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="11"], .stats-blocks .report-counter .uploading-file[data-progress="11"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="12"], .stats-blocks .report-counter .uploading-file[data-progress="12"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="13"], .stats-blocks .report-counter .uploading-file[data-progress="13"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="14"], .stats-blocks .report-counter .uploading-file[data-progress="14"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="15"], .stats-blocks .report-counter .uploading-file[data-progress="15"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="16"], .stats-blocks .report-counter .uploading-file[data-progress="16"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="17"], .stats-blocks .report-counter .uploading-file[data-progress="17"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="18"], .stats-blocks .report-counter .uploading-file[data-progress="18"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="19"], .stats-blocks .report-counter .uploading-file[data-progress="19"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="20"], .stats-blocks .report-counter .uploading-file[data-progress="20"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="21"], .stats-blocks .report-counter .uploading-file[data-progress="21"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="22"], .stats-blocks .report-counter .uploading-file[data-progress="22"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="23"], .stats-blocks .report-counter .uploading-file[data-progress="23"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="24"], .stats-blocks .report-counter .uploading-file[data-progress="24"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="25"], .stats-blocks .report-counter .uploading-file[data-progress="25"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="26"], .stats-blocks .report-counter .uploading-file[data-progress="26"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="27"], .stats-blocks .report-counter .uploading-file[data-progress="27"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="28"], .stats-blocks .report-counter .uploading-file[data-progress="28"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="29"], .stats-blocks .report-counter .uploading-file[data-progress="29"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="30"], .stats-blocks .report-counter .uploading-file[data-progress="30"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="31"], .stats-blocks .report-counter .uploading-file[data-progress="31"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="32"], .stats-blocks .report-counter .uploading-file[data-progress="32"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="33"], .stats-blocks .report-counter .uploading-file[data-progress="33"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="34"], .stats-blocks .report-counter .uploading-file[data-progress="34"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="35"], .stats-blocks .report-counter .uploading-file[data-progress="35"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="36"], .stats-blocks .report-counter .uploading-file[data-progress="36"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="37"], .stats-blocks .report-counter .uploading-file[data-progress="37"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="38"], .stats-blocks .report-counter .uploading-file[data-progress="38"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="39"], .stats-blocks .report-counter .uploading-file[data-progress="39"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="40"], .stats-blocks .report-counter .uploading-file[data-progress="40"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="41"], .stats-blocks .report-counter .uploading-file[data-progress="41"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="42"], .stats-blocks .report-counter .uploading-file[data-progress="42"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="43"], .stats-blocks .report-counter .uploading-file[data-progress="43"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="44"], .stats-blocks .report-counter .uploading-file[data-progress="44"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="45"], .stats-blocks .report-counter .uploading-file[data-progress="45"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="46"], .stats-blocks .report-counter .uploading-file[data-progress="46"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="47"], .stats-blocks .report-counter .uploading-file[data-progress="47"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="48"], .stats-blocks .report-counter .uploading-file[data-progress="48"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="49"], .stats-blocks .report-counter .uploading-file[data-progress="49"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="50"], .stats-blocks .report-counter .uploading-file[data-progress="50"]:before {
    background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="51"], .stats-blocks .report-counter .uploading-file[data-progress="51"]:before {
    background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="52"], .stats-blocks .report-counter .uploading-file[data-progress="52"]:before {
    background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="53"], .stats-blocks .report-counter .uploading-file[data-progress="53"]:before {
    background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="54"], .stats-blocks .report-counter .uploading-file[data-progress="54"]:before {
    background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="55"], .stats-blocks .report-counter .uploading-file[data-progress="55"]:before {
    background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="56"], .stats-blocks .report-counter .uploading-file[data-progress="56"]:before {
    background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="57"], .stats-blocks .report-counter .uploading-file[data-progress="57"]:before {
    background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="58"], .stats-blocks .report-counter .uploading-file[data-progress="58"]:before {
    background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="59"], .stats-blocks .report-counter .uploading-file[data-progress="59"]:before {
    background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="60"], .stats-blocks .report-counter .uploading-file[data-progress="60"]:before {
    background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="61"], .stats-blocks .report-counter .uploading-file[data-progress="61"]:before {
    background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="62"], .stats-blocks .report-counter .uploading-file[data-progress="62"]:before {
    background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="63"], .stats-blocks .report-counter .uploading-file[data-progress="63"]:before {
    background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="64"], .stats-blocks .report-counter .uploading-file[data-progress="64"]:before {
    background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="65"], .stats-blocks .report-counter .uploading-file[data-progress="65"]:before {
    background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="66"], .stats-blocks .report-counter .uploading-file[data-progress="66"]:before {
    background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="67"], .stats-blocks .report-counter .uploading-file[data-progress="67"]:before {
    background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="68"], .stats-blocks .report-counter .uploading-file[data-progress="68"]:before {
    background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="69"], .stats-blocks .report-counter .uploading-file[data-progress="69"]:before {
    background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="70"], .stats-blocks .report-counter .uploading-file[data-progress="70"]:before {
    background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="71"], .stats-blocks .report-counter .uploading-file[data-progress="71"]:before {
    background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="72"], .stats-blocks .report-counter .uploading-file[data-progress="72"]:before {
    background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="73"], .stats-blocks .report-counter .uploading-file[data-progress="73"]:before {
    background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="74"], .stats-blocks .report-counter .uploading-file[data-progress="74"]:before {
    background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="75"], .stats-blocks .report-counter .uploading-file[data-progress="75"]:before {
    background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="76"], .stats-blocks .report-counter .uploading-file[data-progress="76"]:before {
    background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="77"], .stats-blocks .report-counter .uploading-file[data-progress="77"]:before {
    background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="78"], .stats-blocks .report-counter .uploading-file[data-progress="78"]:before {
    background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="79"], .stats-blocks .report-counter .uploading-file[data-progress="79"]:before {
    background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="80"], .stats-blocks .report-counter .uploading-file[data-progress="80"]:before {
    background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="81"], .stats-blocks .report-counter .uploading-file[data-progress="81"]:before {
    background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="82"], .stats-blocks .report-counter .uploading-file[data-progress="82"]:before {
    background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="83"], .stats-blocks .report-counter .uploading-file[data-progress="83"]:before {
    background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="84"], .stats-blocks .report-counter .uploading-file[data-progress="84"]:before {
    background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="85"], .stats-blocks .report-counter .uploading-file[data-progress="85"]:before {
    background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="86"], .stats-blocks .report-counter .uploading-file[data-progress="86"]:before {
    background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="87"], .stats-blocks .report-counter .uploading-file[data-progress="87"]:before {
    background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="88"], .stats-blocks .report-counter .uploading-file[data-progress="88"]:before {
    background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="89"], .stats-blocks .report-counter .uploading-file[data-progress="89"]:before {
    background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="90"], .stats-blocks .report-counter .uploading-file[data-progress="90"]:before {
    background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="91"], .stats-blocks .report-counter .uploading-file[data-progress="91"]:before {
    background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="92"], .stats-blocks .report-counter .uploading-file[data-progress="92"]:before {
    background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="93"], .stats-blocks .report-counter .uploading-file[data-progress="93"]:before {
    background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="94"], .stats-blocks .report-counter .uploading-file[data-progress="94"]:before {
    background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="95"], .stats-blocks .report-counter .uploading-file[data-progress="95"]:before {
    background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="96"], .stats-blocks .report-counter .uploading-file[data-progress="96"]:before {
    background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="97"], .stats-blocks .report-counter .uploading-file[data-progress="97"]:before {
    background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="98"], .stats-blocks .report-counter .uploading-file[data-progress="98"]:before {
    background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="99"], .stats-blocks .report-counter .uploading-file[data-progress="99"]:before {
    background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .stats-blocks .report-counter .active-border[data-progress="100"], .stats-blocks .report-counter .uploading-file[data-progress="100"]:before {
    background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.buyer-stats {
  margin-top: 45px; }
  .buyer-stats .options i {
    font-size: 18px; }
  .buyer-stats .options a, .buyer-stats .options i {
    color: #646462;
    text-decoration: none; }
  .buyer-stats .options svg g {
    fill: #646462; }
  .buyer-stats .options > * {
    min-width: 24px;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none; }
  .buyer-stats .search:not(.show) {
    visibility: hidden;
    z-index: -1; }
  .buyer-stats .search .input-group:before {
    content: "search";
    font-family: 'Material Icons';
    position: absolute;
    right: 5px;
    top: 4px;
    z-index: 9;
    color: rgba(25, 29, 33, 0.7); }
  .buyer-stats .search .input-group-text {
    border: none;
    padding-left: 0;
    background: none;
    cursor: pointer; }
  .buyer-stats .search .form-control {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border: none;
    color: rgba(25, 29, 33, 0.6);
    font-size: 14px;
    border-radius: 4px !important; }
  .buyer-stats .search i {
    font-size: 18px; }
  .buyer-stats .search {
    top: -4px;
    right: 12px;
    bottom: auto;
    left: 14px;
    position: absolute; }
  .buyer-stats .form-control {
    padding-right: 26px; }
  .buyer-stats .options a {
    font-size: 14px; }

.buyer-stats-table {
  margin: 0px -30px; }
  .buyer-stats-table th {
    padding: 10px 5px;
    font-size: 14px;
    font-weight: 500;
    border-top: none; }
  .buyer-stats-table th:last-child, .buyer-stats-table td:last-child {
    padding-right: 30px; }
  .buyer-stats-table th:first-child, .buyer-stats-table td:first-child {
    width: 60%;
    padding-left: 30px; }
  .buyer-stats-table td {
    padding: 4px 5px;
    border: none;
    vertical-align: middle; }
  .buyer-stats-table tr:first-child td {
    padding-top: 14px; }
  .buyer-stats-table td:first-child span {
    font-size: 14px; }
  .buyer-stats-table td:not(:first-child), .buyer-stats-table th:not(:first-child) {
    text-transform: uppercase;
    text-align: center; }
  .buyer-stats-table .material-icons {
    font-size: 18px;
    margin-top: 7px; }
  .buyer-stats-table .gray-tick {
    color: #979694; }
  .buyer-stats-table .blue-tick {
    color: #0099ff; }
  .buyer-stats-table tbody tr:nth-of-type(even) {
    background: #eeede9; }

.add-announcement .upload-image {
  margin-top: 30px; }

.attached-file {
  background: #eeede9;
  padding: 12px 10px;
  margin-top: 30px;
  cursor: pointer; }
  .attached-file p {
    font-size: 14px;
    margin: 0;
    color: #646462; }
  .attached-file .material-icons {
    font-size: 22px;
    color: #646462; }
  .attached-file a {
    color: #0099ff; }
  .attached-file .uploaded-file {
    background: #fff; }
    .attached-file .uploaded-file p {
      color: #323231; }
  .attached-file .uploading-file {
    background: #fbfaf6;
    position: relative; }
    .attached-file .uploading-file p {
      color: #c9c8c5; }
    .attached-file .uploading-file:after {
      content: ""; }
  .attached-file .uploaded-file, .attached-file .uploading-file {
    padding: 6px 10px;
    margin-bottom: 8px; }
    .attached-file .uploaded-file i, .attached-file .uploading-file i {
      font-size: 18px; }
    .attached-file .uploaded-file span, .attached-file .uploaded-file i, .attached-file .uploading-file span, .attached-file .uploading-file i {
      color: #979694; }
    .attached-file .uploaded-file span, .attached-file .uploading-file span {
      margin-left: 8px;
      display: inline-block; }
  .attached-file .circle {
    text-align: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .attached-file .active-border {
    text-align: center;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center; }
  .attached-file .uploading-file:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
    transform: rotate(-180deg); }
  .attached-file .active-border[data-progress="0"], .attached-file .uploading-file[data-progress="0"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="1"], .attached-file .uploading-file[data-progress="1"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="2"], .attached-file .uploading-file[data-progress="2"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="3"], .attached-file .uploading-file[data-progress="3"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="4"], .attached-file .uploading-file[data-progress="4"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="5"], .attached-file .uploading-file[data-progress="5"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="6"], .attached-file .uploading-file[data-progress="6"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="7"], .attached-file .uploading-file[data-progress="7"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="8"], .attached-file .uploading-file[data-progress="8"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="9"], .attached-file .uploading-file[data-progress="9"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="10"], .attached-file .uploading-file[data-progress="10"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="11"], .attached-file .uploading-file[data-progress="11"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="12"], .attached-file .uploading-file[data-progress="12"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="13"], .attached-file .uploading-file[data-progress="13"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="14"], .attached-file .uploading-file[data-progress="14"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="15"], .attached-file .uploading-file[data-progress="15"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="16"], .attached-file .uploading-file[data-progress="16"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="17"], .attached-file .uploading-file[data-progress="17"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="18"], .attached-file .uploading-file[data-progress="18"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="19"], .attached-file .uploading-file[data-progress="19"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="20"], .attached-file .uploading-file[data-progress="20"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="21"], .attached-file .uploading-file[data-progress="21"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="22"], .attached-file .uploading-file[data-progress="22"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="23"], .attached-file .uploading-file[data-progress="23"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="24"], .attached-file .uploading-file[data-progress="24"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="25"], .attached-file .uploading-file[data-progress="25"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="26"], .attached-file .uploading-file[data-progress="26"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="27"], .attached-file .uploading-file[data-progress="27"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="28"], .attached-file .uploading-file[data-progress="28"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="29"], .attached-file .uploading-file[data-progress="29"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="30"], .attached-file .uploading-file[data-progress="30"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="31"], .attached-file .uploading-file[data-progress="31"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="32"], .attached-file .uploading-file[data-progress="32"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="33"], .attached-file .uploading-file[data-progress="33"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="34"], .attached-file .uploading-file[data-progress="34"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="35"], .attached-file .uploading-file[data-progress="35"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="36"], .attached-file .uploading-file[data-progress="36"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="37"], .attached-file .uploading-file[data-progress="37"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="38"], .attached-file .uploading-file[data-progress="38"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="39"], .attached-file .uploading-file[data-progress="39"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="40"], .attached-file .uploading-file[data-progress="40"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="41"], .attached-file .uploading-file[data-progress="41"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="42"], .attached-file .uploading-file[data-progress="42"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="43"], .attached-file .uploading-file[data-progress="43"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="44"], .attached-file .uploading-file[data-progress="44"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="45"], .attached-file .uploading-file[data-progress="45"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="46"], .attached-file .uploading-file[data-progress="46"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="47"], .attached-file .uploading-file[data-progress="47"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="48"], .attached-file .uploading-file[data-progress="48"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="49"], .attached-file .uploading-file[data-progress="49"]:before {
    background-image: linear-gradient(90deg, #fbfaf6 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="50"], .attached-file .uploading-file[data-progress="50"]:before {
    background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="51"], .attached-file .uploading-file[data-progress="51"]:before {
    background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="52"], .attached-file .uploading-file[data-progress="52"]:before {
    background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="53"], .attached-file .uploading-file[data-progress="53"]:before {
    background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="54"], .attached-file .uploading-file[data-progress="54"]:before {
    background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="55"], .attached-file .uploading-file[data-progress="55"]:before {
    background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="56"], .attached-file .uploading-file[data-progress="56"]:before {
    background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="57"], .attached-file .uploading-file[data-progress="57"]:before {
    background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="58"], .attached-file .uploading-file[data-progress="58"]:before {
    background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="59"], .attached-file .uploading-file[data-progress="59"]:before {
    background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="60"], .attached-file .uploading-file[data-progress="60"]:before {
    background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="61"], .attached-file .uploading-file[data-progress="61"]:before {
    background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="62"], .attached-file .uploading-file[data-progress="62"]:before {
    background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="63"], .attached-file .uploading-file[data-progress="63"]:before {
    background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="64"], .attached-file .uploading-file[data-progress="64"]:before {
    background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="65"], .attached-file .uploading-file[data-progress="65"]:before {
    background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="66"], .attached-file .uploading-file[data-progress="66"]:before {
    background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="67"], .attached-file .uploading-file[data-progress="67"]:before {
    background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="68"], .attached-file .uploading-file[data-progress="68"]:before {
    background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="69"], .attached-file .uploading-file[data-progress="69"]:before {
    background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="70"], .attached-file .uploading-file[data-progress="70"]:before {
    background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="71"], .attached-file .uploading-file[data-progress="71"]:before {
    background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="72"], .attached-file .uploading-file[data-progress="72"]:before {
    background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="73"], .attached-file .uploading-file[data-progress="73"]:before {
    background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="74"], .attached-file .uploading-file[data-progress="74"]:before {
    background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="75"], .attached-file .uploading-file[data-progress="75"]:before {
    background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="76"], .attached-file .uploading-file[data-progress="76"]:before {
    background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="77"], .attached-file .uploading-file[data-progress="77"]:before {
    background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="78"], .attached-file .uploading-file[data-progress="78"]:before {
    background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="79"], .attached-file .uploading-file[data-progress="79"]:before {
    background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="80"], .attached-file .uploading-file[data-progress="80"]:before {
    background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="81"], .attached-file .uploading-file[data-progress="81"]:before {
    background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="82"], .attached-file .uploading-file[data-progress="82"]:before {
    background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="83"], .attached-file .uploading-file[data-progress="83"]:before {
    background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="84"], .attached-file .uploading-file[data-progress="84"]:before {
    background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="85"], .attached-file .uploading-file[data-progress="85"]:before {
    background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="86"], .attached-file .uploading-file[data-progress="86"]:before {
    background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="87"], .attached-file .uploading-file[data-progress="87"]:before {
    background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="88"], .attached-file .uploading-file[data-progress="88"]:before {
    background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="89"], .attached-file .uploading-file[data-progress="89"]:before {
    background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="90"], .attached-file .uploading-file[data-progress="90"]:before {
    background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="91"], .attached-file .uploading-file[data-progress="91"]:before {
    background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="92"], .attached-file .uploading-file[data-progress="92"]:before {
    background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="93"], .attached-file .uploading-file[data-progress="93"]:before {
    background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="94"], .attached-file .uploading-file[data-progress="94"]:before {
    background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="95"], .attached-file .uploading-file[data-progress="95"]:before {
    background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="96"], .attached-file .uploading-file[data-progress="96"]:before {
    background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="97"], .attached-file .uploading-file[data-progress="97"]:before {
    background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="98"], .attached-file .uploading-file[data-progress="98"]:before {
    background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="99"], .attached-file .uploading-file[data-progress="99"]:before {
    background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }
  .attached-file .active-border[data-progress="100"], .attached-file .uploading-file[data-progress="100"]:before {
    background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fbfaf6 50%, #fbfaf6); }

.add-session-row {
  padding: 10px 10px 10px 16px;
  background: #fbfaf6;
  margin-top: 10px; }
  .add-session-row span {
    color: rgba(33, 37, 41, 0.5);
    font-size: 14px; }
  .add-session-row h6 {
    font-size: 15px;
    color: #212529;
    margin: 0; }

/*QUESTION LIST*/
.questions {
  background: #fbfaf6;
  min-height: 100vh; }

.question-filter .tag-gray[aria-expanded="true"] i, .exam-modal-stats .tag-gray[aria-expanded="true"] i {
  transform: rotate(180deg); }

.question-filter .tag-gray[aria-expanded="true"] .open, .exam-modal-stats .tag-gray[aria-expanded="true"] .open {
  display: block; }

.question-filter .tag-gray[aria-expanded="false"] .closed, .exam-modal-stats .tag-gray[aria-expanded="false"] .closed {
  display: block; }

.question-filter .options .dropdown-menu, .conversation-window .conversation-header .dropdown-menu {
  border: none;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12); }
  .question-filter .options .dropdown-menu .dropdown-item:hover, .conversation-window .conversation-header .dropdown-menu .dropdown-item:hover {
    background: #eeede9;
    color: #323231; }

.question-filter {
  padding: 20px 0 0; }
  .question-filter .options i {
    font-size: 18px; }
  .question-filter .options a, .question-filter .options i {
    color: #7e7d7b;
    text-decoration: none; }
  .question-filter .options svg g {
    fill: #7e7d7b; }
  .question-filter .options > * {
    min-width: 24px;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none; }
  .question-filter .search:not(.show) {
    visibility: hidden;
    z-index: -1; }
  .question-filter .search .input-group:before {
    content: "search";
    font-family: 'Material Icons';
    position: absolute;
    right: 5px;
    top: 4px;
    z-index: 9;
    color: rgba(25, 29, 33, 0.7); }
  .question-filter .search .input-group-text {
    border: none;
    padding-left: 0;
    background: #fbfaf6;
    cursor: pointer; }
  .question-filter .search .form-control {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border: none;
    color: rgba(25, 29, 33, 0.6);
    font-size: 14px;
    border-radius: 4px !important; }
  .question-filter .search i {
    font-size: 18px; }
  .question-filter span {
    display: none; }
  .question-filter .search {
    top: 0;
    right: 12px;
    bottom: auto;
    left: 14px;
    position: absolute; }
  .question-filter .options .dropdown-menu {
    left: auto !important;
    right: 0; }

.advance-search {
  display: none; }

.filter-fields {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  padding: 15px 15px 0; }

.page-sorting {
  color: #b0afac;
  font-size: 14px; }

.exam-modal-stats .white-box {
  padding: 20px;
  background: #fff;
  margin-top: 20px; }
  .exam-modal-stats .white-box h6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 32px;
    margin-bottom: 12px; }
    .exam-modal-stats .white-box h6 span, .exam-modal-stats .white-box h6 i {
      font-size: 15px; }
    .exam-modal-stats .white-box h6 span {
      display: flex;
      align-items: center; }
    .exam-modal-stats .white-box h6 i {
      color: #979694; }
  .exam-modal-stats .white-box table {
    margin-bottom: 0; }
  .exam-modal-stats .white-box td {
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0;
    border-color: #eeede9; }
    .exam-modal-stats .white-box td:first-child img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      margin-right: 16px; }
    .exam-modal-stats .white-box td:last-child {
      text-align: right; }
  .exam-modal-stats .white-box tbody td {
    color: #323231; }
  .exam-modal-stats .white-box tbody tr:first-child td {
    border-top: none; }
  .exam-modal-stats .white-box tfoot td {
    border-top: 2px solid #e2e1dd;
    font-weight: 600; }

.exam-modal-stats .buyer-stats .search {
  top: -3px;
  right: 0;
  bottom: auto;
  left: -36px; }

.exam-modal-stats .stats-bar-block .exam-points-table.table {
  background: #eeede9;
  border-radius: 4px; }
  .exam-modal-stats .stats-bar-block .exam-points-table.table td {
    border: none;
    padding: 10px; }
    .exam-modal-stats .stats-bar-block .exam-points-table.table td:first-child {
      font-weight: 400; }
  .exam-modal-stats .stats-bar-block .exam-points-table.table th {
    font-size: 14px;
    font-weight: 600;
    padding: 10px; }
    .exam-modal-stats .stats-bar-block .exam-points-table.table th:last-child {
      text-align: right; }

.question-modal .modal-content, .exam-modal-stats .modal-content, .exam-miss-modal .modal-content {
  padding: 30px 30px 20px;
  background: #fbfaf6;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.32); }

.question-modal .modal-header, .exam-modal-stats .modal-header, .exam-miss-modal .modal-header {
  padding: 0 0 10px; }
  .question-modal .modal-header h6, .question-modal .modal-header i, .exam-modal-stats .modal-header h6, .exam-modal-stats .modal-header i, .exam-miss-modal .modal-header h6, .exam-miss-modal .modal-header i {
    font-size: 14px;
    color: #646462; }
  .question-modal .modal-header .close, .exam-modal-stats .modal-header .close, .exam-miss-modal .modal-header .close {
    font-weight: normal; }

.question-modal .circle, .exam-modal-stats .circle, .exam-miss-modal .circle {
  text-align: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #fbfaf6;
  display: flex;
  justify-content: center;
  align-items: center; }

.question-modal .active-border, .exam-modal-stats .active-border, .exam-miss-modal .active-border {
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }

.question-modal .uploading-file:before, .exam-modal-stats .uploading-file:before, .exam-miss-modal .uploading-file:before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0px;
  transform: rotate(-180deg); }

.question-modal .active-border[data-progress="0"], .question-modal .uploading-file[data-progress="0"]:before, .exam-modal-stats .active-border[data-progress="0"], .exam-modal-stats .uploading-file[data-progress="0"]:before, .exam-miss-modal .active-border[data-progress="0"], .exam-miss-modal .uploading-file[data-progress="0"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="1"], .question-modal .uploading-file[data-progress="1"]:before, .exam-modal-stats .active-border[data-progress="1"], .exam-modal-stats .uploading-file[data-progress="1"]:before, .exam-miss-modal .active-border[data-progress="1"], .exam-miss-modal .uploading-file[data-progress="1"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="2"], .question-modal .uploading-file[data-progress="2"]:before, .exam-modal-stats .active-border[data-progress="2"], .exam-modal-stats .uploading-file[data-progress="2"]:before, .exam-miss-modal .active-border[data-progress="2"], .exam-miss-modal .uploading-file[data-progress="2"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="3"], .question-modal .uploading-file[data-progress="3"]:before, .exam-modal-stats .active-border[data-progress="3"], .exam-modal-stats .uploading-file[data-progress="3"]:before, .exam-miss-modal .active-border[data-progress="3"], .exam-miss-modal .uploading-file[data-progress="3"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="4"], .question-modal .uploading-file[data-progress="4"]:before, .exam-modal-stats .active-border[data-progress="4"], .exam-modal-stats .uploading-file[data-progress="4"]:before, .exam-miss-modal .active-border[data-progress="4"], .exam-miss-modal .uploading-file[data-progress="4"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="5"], .question-modal .uploading-file[data-progress="5"]:before, .exam-modal-stats .active-border[data-progress="5"], .exam-modal-stats .uploading-file[data-progress="5"]:before, .exam-miss-modal .active-border[data-progress="5"], .exam-miss-modal .uploading-file[data-progress="5"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="6"], .question-modal .uploading-file[data-progress="6"]:before, .exam-modal-stats .active-border[data-progress="6"], .exam-modal-stats .uploading-file[data-progress="6"]:before, .exam-miss-modal .active-border[data-progress="6"], .exam-miss-modal .uploading-file[data-progress="6"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="7"], .question-modal .uploading-file[data-progress="7"]:before, .exam-modal-stats .active-border[data-progress="7"], .exam-modal-stats .uploading-file[data-progress="7"]:before, .exam-miss-modal .active-border[data-progress="7"], .exam-miss-modal .uploading-file[data-progress="7"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="8"], .question-modal .uploading-file[data-progress="8"]:before, .exam-modal-stats .active-border[data-progress="8"], .exam-modal-stats .uploading-file[data-progress="8"]:before, .exam-miss-modal .active-border[data-progress="8"], .exam-miss-modal .uploading-file[data-progress="8"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="9"], .question-modal .uploading-file[data-progress="9"]:before, .exam-modal-stats .active-border[data-progress="9"], .exam-modal-stats .uploading-file[data-progress="9"]:before, .exam-miss-modal .active-border[data-progress="9"], .exam-miss-modal .uploading-file[data-progress="9"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="10"], .question-modal .uploading-file[data-progress="10"]:before, .exam-modal-stats .active-border[data-progress="10"], .exam-modal-stats .uploading-file[data-progress="10"]:before, .exam-miss-modal .active-border[data-progress="10"], .exam-miss-modal .uploading-file[data-progress="10"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="11"], .question-modal .uploading-file[data-progress="11"]:before, .exam-modal-stats .active-border[data-progress="11"], .exam-modal-stats .uploading-file[data-progress="11"]:before, .exam-miss-modal .active-border[data-progress="11"], .exam-miss-modal .uploading-file[data-progress="11"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="12"], .question-modal .uploading-file[data-progress="12"]:before, .exam-modal-stats .active-border[data-progress="12"], .exam-modal-stats .uploading-file[data-progress="12"]:before, .exam-miss-modal .active-border[data-progress="12"], .exam-miss-modal .uploading-file[data-progress="12"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="13"], .question-modal .uploading-file[data-progress="13"]:before, .exam-modal-stats .active-border[data-progress="13"], .exam-modal-stats .uploading-file[data-progress="13"]:before, .exam-miss-modal .active-border[data-progress="13"], .exam-miss-modal .uploading-file[data-progress="13"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="14"], .question-modal .uploading-file[data-progress="14"]:before, .exam-modal-stats .active-border[data-progress="14"], .exam-modal-stats .uploading-file[data-progress="14"]:before, .exam-miss-modal .active-border[data-progress="14"], .exam-miss-modal .uploading-file[data-progress="14"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="15"], .question-modal .uploading-file[data-progress="15"]:before, .exam-modal-stats .active-border[data-progress="15"], .exam-modal-stats .uploading-file[data-progress="15"]:before, .exam-miss-modal .active-border[data-progress="15"], .exam-miss-modal .uploading-file[data-progress="15"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="16"], .question-modal .uploading-file[data-progress="16"]:before, .exam-modal-stats .active-border[data-progress="16"], .exam-modal-stats .uploading-file[data-progress="16"]:before, .exam-miss-modal .active-border[data-progress="16"], .exam-miss-modal .uploading-file[data-progress="16"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="17"], .question-modal .uploading-file[data-progress="17"]:before, .exam-modal-stats .active-border[data-progress="17"], .exam-modal-stats .uploading-file[data-progress="17"]:before, .exam-miss-modal .active-border[data-progress="17"], .exam-miss-modal .uploading-file[data-progress="17"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="18"], .question-modal .uploading-file[data-progress="18"]:before, .exam-modal-stats .active-border[data-progress="18"], .exam-modal-stats .uploading-file[data-progress="18"]:before, .exam-miss-modal .active-border[data-progress="18"], .exam-miss-modal .uploading-file[data-progress="18"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="19"], .question-modal .uploading-file[data-progress="19"]:before, .exam-modal-stats .active-border[data-progress="19"], .exam-modal-stats .uploading-file[data-progress="19"]:before, .exam-miss-modal .active-border[data-progress="19"], .exam-miss-modal .uploading-file[data-progress="19"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="20"], .question-modal .uploading-file[data-progress="20"]:before, .exam-modal-stats .active-border[data-progress="20"], .exam-modal-stats .uploading-file[data-progress="20"]:before, .exam-miss-modal .active-border[data-progress="20"], .exam-miss-modal .uploading-file[data-progress="20"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="21"], .question-modal .uploading-file[data-progress="21"]:before, .exam-modal-stats .active-border[data-progress="21"], .exam-modal-stats .uploading-file[data-progress="21"]:before, .exam-miss-modal .active-border[data-progress="21"], .exam-miss-modal .uploading-file[data-progress="21"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="22"], .question-modal .uploading-file[data-progress="22"]:before, .exam-modal-stats .active-border[data-progress="22"], .exam-modal-stats .uploading-file[data-progress="22"]:before, .exam-miss-modal .active-border[data-progress="22"], .exam-miss-modal .uploading-file[data-progress="22"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="23"], .question-modal .uploading-file[data-progress="23"]:before, .exam-modal-stats .active-border[data-progress="23"], .exam-modal-stats .uploading-file[data-progress="23"]:before, .exam-miss-modal .active-border[data-progress="23"], .exam-miss-modal .uploading-file[data-progress="23"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="24"], .question-modal .uploading-file[data-progress="24"]:before, .exam-modal-stats .active-border[data-progress="24"], .exam-modal-stats .uploading-file[data-progress="24"]:before, .exam-miss-modal .active-border[data-progress="24"], .exam-miss-modal .uploading-file[data-progress="24"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="25"], .question-modal .uploading-file[data-progress="25"]:before, .exam-modal-stats .active-border[data-progress="25"], .exam-modal-stats .uploading-file[data-progress="25"]:before, .exam-miss-modal .active-border[data-progress="25"], .exam-miss-modal .uploading-file[data-progress="25"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="26"], .question-modal .uploading-file[data-progress="26"]:before, .exam-modal-stats .active-border[data-progress="26"], .exam-modal-stats .uploading-file[data-progress="26"]:before, .exam-miss-modal .active-border[data-progress="26"], .exam-miss-modal .uploading-file[data-progress="26"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="27"], .question-modal .uploading-file[data-progress="27"]:before, .exam-modal-stats .active-border[data-progress="27"], .exam-modal-stats .uploading-file[data-progress="27"]:before, .exam-miss-modal .active-border[data-progress="27"], .exam-miss-modal .uploading-file[data-progress="27"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="28"], .question-modal .uploading-file[data-progress="28"]:before, .exam-modal-stats .active-border[data-progress="28"], .exam-modal-stats .uploading-file[data-progress="28"]:before, .exam-miss-modal .active-border[data-progress="28"], .exam-miss-modal .uploading-file[data-progress="28"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="29"], .question-modal .uploading-file[data-progress="29"]:before, .exam-modal-stats .active-border[data-progress="29"], .exam-modal-stats .uploading-file[data-progress="29"]:before, .exam-miss-modal .active-border[data-progress="29"], .exam-miss-modal .uploading-file[data-progress="29"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="30"], .question-modal .uploading-file[data-progress="30"]:before, .exam-modal-stats .active-border[data-progress="30"], .exam-modal-stats .uploading-file[data-progress="30"]:before, .exam-miss-modal .active-border[data-progress="30"], .exam-miss-modal .uploading-file[data-progress="30"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="31"], .question-modal .uploading-file[data-progress="31"]:before, .exam-modal-stats .active-border[data-progress="31"], .exam-modal-stats .uploading-file[data-progress="31"]:before, .exam-miss-modal .active-border[data-progress="31"], .exam-miss-modal .uploading-file[data-progress="31"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="32"], .question-modal .uploading-file[data-progress="32"]:before, .exam-modal-stats .active-border[data-progress="32"], .exam-modal-stats .uploading-file[data-progress="32"]:before, .exam-miss-modal .active-border[data-progress="32"], .exam-miss-modal .uploading-file[data-progress="32"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="33"], .question-modal .uploading-file[data-progress="33"]:before, .exam-modal-stats .active-border[data-progress="33"], .exam-modal-stats .uploading-file[data-progress="33"]:before, .exam-miss-modal .active-border[data-progress="33"], .exam-miss-modal .uploading-file[data-progress="33"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="34"], .question-modal .uploading-file[data-progress="34"]:before, .exam-modal-stats .active-border[data-progress="34"], .exam-modal-stats .uploading-file[data-progress="34"]:before, .exam-miss-modal .active-border[data-progress="34"], .exam-miss-modal .uploading-file[data-progress="34"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="35"], .question-modal .uploading-file[data-progress="35"]:before, .exam-modal-stats .active-border[data-progress="35"], .exam-modal-stats .uploading-file[data-progress="35"]:before, .exam-miss-modal .active-border[data-progress="35"], .exam-miss-modal .uploading-file[data-progress="35"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="36"], .question-modal .uploading-file[data-progress="36"]:before, .exam-modal-stats .active-border[data-progress="36"], .exam-modal-stats .uploading-file[data-progress="36"]:before, .exam-miss-modal .active-border[data-progress="36"], .exam-miss-modal .uploading-file[data-progress="36"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="37"], .question-modal .uploading-file[data-progress="37"]:before, .exam-modal-stats .active-border[data-progress="37"], .exam-modal-stats .uploading-file[data-progress="37"]:before, .exam-miss-modal .active-border[data-progress="37"], .exam-miss-modal .uploading-file[data-progress="37"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="38"], .question-modal .uploading-file[data-progress="38"]:before, .exam-modal-stats .active-border[data-progress="38"], .exam-modal-stats .uploading-file[data-progress="38"]:before, .exam-miss-modal .active-border[data-progress="38"], .exam-miss-modal .uploading-file[data-progress="38"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="39"], .question-modal .uploading-file[data-progress="39"]:before, .exam-modal-stats .active-border[data-progress="39"], .exam-modal-stats .uploading-file[data-progress="39"]:before, .exam-miss-modal .active-border[data-progress="39"], .exam-miss-modal .uploading-file[data-progress="39"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="40"], .question-modal .uploading-file[data-progress="40"]:before, .exam-modal-stats .active-border[data-progress="40"], .exam-modal-stats .uploading-file[data-progress="40"]:before, .exam-miss-modal .active-border[data-progress="40"], .exam-miss-modal .uploading-file[data-progress="40"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="41"], .question-modal .uploading-file[data-progress="41"]:before, .exam-modal-stats .active-border[data-progress="41"], .exam-modal-stats .uploading-file[data-progress="41"]:before, .exam-miss-modal .active-border[data-progress="41"], .exam-miss-modal .uploading-file[data-progress="41"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="42"], .question-modal .uploading-file[data-progress="42"]:before, .exam-modal-stats .active-border[data-progress="42"], .exam-modal-stats .uploading-file[data-progress="42"]:before, .exam-miss-modal .active-border[data-progress="42"], .exam-miss-modal .uploading-file[data-progress="42"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="43"], .question-modal .uploading-file[data-progress="43"]:before, .exam-modal-stats .active-border[data-progress="43"], .exam-modal-stats .uploading-file[data-progress="43"]:before, .exam-miss-modal .active-border[data-progress="43"], .exam-miss-modal .uploading-file[data-progress="43"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="44"], .question-modal .uploading-file[data-progress="44"]:before, .exam-modal-stats .active-border[data-progress="44"], .exam-modal-stats .uploading-file[data-progress="44"]:before, .exam-miss-modal .active-border[data-progress="44"], .exam-miss-modal .uploading-file[data-progress="44"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="45"], .question-modal .uploading-file[data-progress="45"]:before, .exam-modal-stats .active-border[data-progress="45"], .exam-modal-stats .uploading-file[data-progress="45"]:before, .exam-miss-modal .active-border[data-progress="45"], .exam-miss-modal .uploading-file[data-progress="45"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="46"], .question-modal .uploading-file[data-progress="46"]:before, .exam-modal-stats .active-border[data-progress="46"], .exam-modal-stats .uploading-file[data-progress="46"]:before, .exam-miss-modal .active-border[data-progress="46"], .exam-miss-modal .uploading-file[data-progress="46"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="47"], .question-modal .uploading-file[data-progress="47"]:before, .exam-modal-stats .active-border[data-progress="47"], .exam-modal-stats .uploading-file[data-progress="47"]:before, .exam-miss-modal .active-border[data-progress="47"], .exam-miss-modal .uploading-file[data-progress="47"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="48"], .question-modal .uploading-file[data-progress="48"]:before, .exam-modal-stats .active-border[data-progress="48"], .exam-modal-stats .uploading-file[data-progress="48"]:before, .exam-miss-modal .active-border[data-progress="48"], .exam-miss-modal .uploading-file[data-progress="48"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="49"], .question-modal .uploading-file[data-progress="49"]:before, .exam-modal-stats .active-border[data-progress="49"], .exam-modal-stats .uploading-file[data-progress="49"]:before, .exam-miss-modal .active-border[data-progress="49"], .exam-miss-modal .uploading-file[data-progress="49"]:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="50"], .question-modal .uploading-file[data-progress="50"]:before, .exam-modal-stats .active-border[data-progress="50"], .exam-modal-stats .uploading-file[data-progress="50"]:before, .exam-miss-modal .active-border[data-progress="50"], .exam-miss-modal .uploading-file[data-progress="50"]:before {
  background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="51"], .question-modal .uploading-file[data-progress="51"]:before, .exam-modal-stats .active-border[data-progress="51"], .exam-modal-stats .uploading-file[data-progress="51"]:before, .exam-miss-modal .active-border[data-progress="51"], .exam-miss-modal .uploading-file[data-progress="51"]:before {
  background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="52"], .question-modal .uploading-file[data-progress="52"]:before, .exam-modal-stats .active-border[data-progress="52"], .exam-modal-stats .uploading-file[data-progress="52"]:before, .exam-miss-modal .active-border[data-progress="52"], .exam-miss-modal .uploading-file[data-progress="52"]:before {
  background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="53"], .question-modal .uploading-file[data-progress="53"]:before, .exam-modal-stats .active-border[data-progress="53"], .exam-modal-stats .uploading-file[data-progress="53"]:before, .exam-miss-modal .active-border[data-progress="53"], .exam-miss-modal .uploading-file[data-progress="53"]:before {
  background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="54"], .question-modal .uploading-file[data-progress="54"]:before, .exam-modal-stats .active-border[data-progress="54"], .exam-modal-stats .uploading-file[data-progress="54"]:before, .exam-miss-modal .active-border[data-progress="54"], .exam-miss-modal .uploading-file[data-progress="54"]:before {
  background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="55"], .question-modal .uploading-file[data-progress="55"]:before, .exam-modal-stats .active-border[data-progress="55"], .exam-modal-stats .uploading-file[data-progress="55"]:before, .exam-miss-modal .active-border[data-progress="55"], .exam-miss-modal .uploading-file[data-progress="55"]:before {
  background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="56"], .question-modal .uploading-file[data-progress="56"]:before, .exam-modal-stats .active-border[data-progress="56"], .exam-modal-stats .uploading-file[data-progress="56"]:before, .exam-miss-modal .active-border[data-progress="56"], .exam-miss-modal .uploading-file[data-progress="56"]:before {
  background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="57"], .question-modal .uploading-file[data-progress="57"]:before, .exam-modal-stats .active-border[data-progress="57"], .exam-modal-stats .uploading-file[data-progress="57"]:before, .exam-miss-modal .active-border[data-progress="57"], .exam-miss-modal .uploading-file[data-progress="57"]:before {
  background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="58"], .question-modal .uploading-file[data-progress="58"]:before, .exam-modal-stats .active-border[data-progress="58"], .exam-modal-stats .uploading-file[data-progress="58"]:before, .exam-miss-modal .active-border[data-progress="58"], .exam-miss-modal .uploading-file[data-progress="58"]:before {
  background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="59"], .question-modal .uploading-file[data-progress="59"]:before, .exam-modal-stats .active-border[data-progress="59"], .exam-modal-stats .uploading-file[data-progress="59"]:before, .exam-miss-modal .active-border[data-progress="59"], .exam-miss-modal .uploading-file[data-progress="59"]:before {
  background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="60"], .question-modal .uploading-file[data-progress="60"]:before, .exam-modal-stats .active-border[data-progress="60"], .exam-modal-stats .uploading-file[data-progress="60"]:before, .exam-miss-modal .active-border[data-progress="60"], .exam-miss-modal .uploading-file[data-progress="60"]:before {
  background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="61"], .question-modal .uploading-file[data-progress="61"]:before, .exam-modal-stats .active-border[data-progress="61"], .exam-modal-stats .uploading-file[data-progress="61"]:before, .exam-miss-modal .active-border[data-progress="61"], .exam-miss-modal .uploading-file[data-progress="61"]:before {
  background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="62"], .question-modal .uploading-file[data-progress="62"]:before, .exam-modal-stats .active-border[data-progress="62"], .exam-modal-stats .uploading-file[data-progress="62"]:before, .exam-miss-modal .active-border[data-progress="62"], .exam-miss-modal .uploading-file[data-progress="62"]:before {
  background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="63"], .question-modal .uploading-file[data-progress="63"]:before, .exam-modal-stats .active-border[data-progress="63"], .exam-modal-stats .uploading-file[data-progress="63"]:before, .exam-miss-modal .active-border[data-progress="63"], .exam-miss-modal .uploading-file[data-progress="63"]:before {
  background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="64"], .question-modal .uploading-file[data-progress="64"]:before, .exam-modal-stats .active-border[data-progress="64"], .exam-modal-stats .uploading-file[data-progress="64"]:before, .exam-miss-modal .active-border[data-progress="64"], .exam-miss-modal .uploading-file[data-progress="64"]:before {
  background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="65"], .question-modal .uploading-file[data-progress="65"]:before, .exam-modal-stats .active-border[data-progress="65"], .exam-modal-stats .uploading-file[data-progress="65"]:before, .exam-miss-modal .active-border[data-progress="65"], .exam-miss-modal .uploading-file[data-progress="65"]:before {
  background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="66"], .question-modal .uploading-file[data-progress="66"]:before, .exam-modal-stats .active-border[data-progress="66"], .exam-modal-stats .uploading-file[data-progress="66"]:before, .exam-miss-modal .active-border[data-progress="66"], .exam-miss-modal .uploading-file[data-progress="66"]:before {
  background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="67"], .question-modal .uploading-file[data-progress="67"]:before, .exam-modal-stats .active-border[data-progress="67"], .exam-modal-stats .uploading-file[data-progress="67"]:before, .exam-miss-modal .active-border[data-progress="67"], .exam-miss-modal .uploading-file[data-progress="67"]:before {
  background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="68"], .question-modal .uploading-file[data-progress="68"]:before, .exam-modal-stats .active-border[data-progress="68"], .exam-modal-stats .uploading-file[data-progress="68"]:before, .exam-miss-modal .active-border[data-progress="68"], .exam-miss-modal .uploading-file[data-progress="68"]:before {
  background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="69"], .question-modal .uploading-file[data-progress="69"]:before, .exam-modal-stats .active-border[data-progress="69"], .exam-modal-stats .uploading-file[data-progress="69"]:before, .exam-miss-modal .active-border[data-progress="69"], .exam-miss-modal .uploading-file[data-progress="69"]:before {
  background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="70"], .question-modal .uploading-file[data-progress="70"]:before, .exam-modal-stats .active-border[data-progress="70"], .exam-modal-stats .uploading-file[data-progress="70"]:before, .exam-miss-modal .active-border[data-progress="70"], .exam-miss-modal .uploading-file[data-progress="70"]:before {
  background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="71"], .question-modal .uploading-file[data-progress="71"]:before, .exam-modal-stats .active-border[data-progress="71"], .exam-modal-stats .uploading-file[data-progress="71"]:before, .exam-miss-modal .active-border[data-progress="71"], .exam-miss-modal .uploading-file[data-progress="71"]:before {
  background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="72"], .question-modal .uploading-file[data-progress="72"]:before, .exam-modal-stats .active-border[data-progress="72"], .exam-modal-stats .uploading-file[data-progress="72"]:before, .exam-miss-modal .active-border[data-progress="72"], .exam-miss-modal .uploading-file[data-progress="72"]:before {
  background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="73"], .question-modal .uploading-file[data-progress="73"]:before, .exam-modal-stats .active-border[data-progress="73"], .exam-modal-stats .uploading-file[data-progress="73"]:before, .exam-miss-modal .active-border[data-progress="73"], .exam-miss-modal .uploading-file[data-progress="73"]:before {
  background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="74"], .question-modal .uploading-file[data-progress="74"]:before, .exam-modal-stats .active-border[data-progress="74"], .exam-modal-stats .uploading-file[data-progress="74"]:before, .exam-miss-modal .active-border[data-progress="74"], .exam-miss-modal .uploading-file[data-progress="74"]:before {
  background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="75"], .question-modal .uploading-file[data-progress="75"]:before, .exam-modal-stats .active-border[data-progress="75"], .exam-modal-stats .uploading-file[data-progress="75"]:before, .exam-miss-modal .active-border[data-progress="75"], .exam-miss-modal .uploading-file[data-progress="75"]:before {
  background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="76"], .question-modal .uploading-file[data-progress="76"]:before, .exam-modal-stats .active-border[data-progress="76"], .exam-modal-stats .uploading-file[data-progress="76"]:before, .exam-miss-modal .active-border[data-progress="76"], .exam-miss-modal .uploading-file[data-progress="76"]:before {
  background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="77"], .question-modal .uploading-file[data-progress="77"]:before, .exam-modal-stats .active-border[data-progress="77"], .exam-modal-stats .uploading-file[data-progress="77"]:before, .exam-miss-modal .active-border[data-progress="77"], .exam-miss-modal .uploading-file[data-progress="77"]:before {
  background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="78"], .question-modal .uploading-file[data-progress="78"]:before, .exam-modal-stats .active-border[data-progress="78"], .exam-modal-stats .uploading-file[data-progress="78"]:before, .exam-miss-modal .active-border[data-progress="78"], .exam-miss-modal .uploading-file[data-progress="78"]:before {
  background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="79"], .question-modal .uploading-file[data-progress="79"]:before, .exam-modal-stats .active-border[data-progress="79"], .exam-modal-stats .uploading-file[data-progress="79"]:before, .exam-miss-modal .active-border[data-progress="79"], .exam-miss-modal .uploading-file[data-progress="79"]:before {
  background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="80"], .question-modal .uploading-file[data-progress="80"]:before, .exam-modal-stats .active-border[data-progress="80"], .exam-modal-stats .uploading-file[data-progress="80"]:before, .exam-miss-modal .active-border[data-progress="80"], .exam-miss-modal .uploading-file[data-progress="80"]:before {
  background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="81"], .question-modal .uploading-file[data-progress="81"]:before, .exam-modal-stats .active-border[data-progress="81"], .exam-modal-stats .uploading-file[data-progress="81"]:before, .exam-miss-modal .active-border[data-progress="81"], .exam-miss-modal .uploading-file[data-progress="81"]:before {
  background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="82"], .question-modal .uploading-file[data-progress="82"]:before, .exam-modal-stats .active-border[data-progress="82"], .exam-modal-stats .uploading-file[data-progress="82"]:before, .exam-miss-modal .active-border[data-progress="82"], .exam-miss-modal .uploading-file[data-progress="82"]:before {
  background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="83"], .question-modal .uploading-file[data-progress="83"]:before, .exam-modal-stats .active-border[data-progress="83"], .exam-modal-stats .uploading-file[data-progress="83"]:before, .exam-miss-modal .active-border[data-progress="83"], .exam-miss-modal .uploading-file[data-progress="83"]:before {
  background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="84"], .question-modal .uploading-file[data-progress="84"]:before, .exam-modal-stats .active-border[data-progress="84"], .exam-modal-stats .uploading-file[data-progress="84"]:before, .exam-miss-modal .active-border[data-progress="84"], .exam-miss-modal .uploading-file[data-progress="84"]:before {
  background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="85"], .question-modal .uploading-file[data-progress="85"]:before, .exam-modal-stats .active-border[data-progress="85"], .exam-modal-stats .uploading-file[data-progress="85"]:before, .exam-miss-modal .active-border[data-progress="85"], .exam-miss-modal .uploading-file[data-progress="85"]:before {
  background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="86"], .question-modal .uploading-file[data-progress="86"]:before, .exam-modal-stats .active-border[data-progress="86"], .exam-modal-stats .uploading-file[data-progress="86"]:before, .exam-miss-modal .active-border[data-progress="86"], .exam-miss-modal .uploading-file[data-progress="86"]:before {
  background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="87"], .question-modal .uploading-file[data-progress="87"]:before, .exam-modal-stats .active-border[data-progress="87"], .exam-modal-stats .uploading-file[data-progress="87"]:before, .exam-miss-modal .active-border[data-progress="87"], .exam-miss-modal .uploading-file[data-progress="87"]:before {
  background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="88"], .question-modal .uploading-file[data-progress="88"]:before, .exam-modal-stats .active-border[data-progress="88"], .exam-modal-stats .uploading-file[data-progress="88"]:before, .exam-miss-modal .active-border[data-progress="88"], .exam-miss-modal .uploading-file[data-progress="88"]:before {
  background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="89"], .question-modal .uploading-file[data-progress="89"]:before, .exam-modal-stats .active-border[data-progress="89"], .exam-modal-stats .uploading-file[data-progress="89"]:before, .exam-miss-modal .active-border[data-progress="89"], .exam-miss-modal .uploading-file[data-progress="89"]:before {
  background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="90"], .question-modal .uploading-file[data-progress="90"]:before, .exam-modal-stats .active-border[data-progress="90"], .exam-modal-stats .uploading-file[data-progress="90"]:before, .exam-miss-modal .active-border[data-progress="90"], .exam-miss-modal .uploading-file[data-progress="90"]:before {
  background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="91"], .question-modal .uploading-file[data-progress="91"]:before, .exam-modal-stats .active-border[data-progress="91"], .exam-modal-stats .uploading-file[data-progress="91"]:before, .exam-miss-modal .active-border[data-progress="91"], .exam-miss-modal .uploading-file[data-progress="91"]:before {
  background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="92"], .question-modal .uploading-file[data-progress="92"]:before, .exam-modal-stats .active-border[data-progress="92"], .exam-modal-stats .uploading-file[data-progress="92"]:before, .exam-miss-modal .active-border[data-progress="92"], .exam-miss-modal .uploading-file[data-progress="92"]:before {
  background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="93"], .question-modal .uploading-file[data-progress="93"]:before, .exam-modal-stats .active-border[data-progress="93"], .exam-modal-stats .uploading-file[data-progress="93"]:before, .exam-miss-modal .active-border[data-progress="93"], .exam-miss-modal .uploading-file[data-progress="93"]:before {
  background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="94"], .question-modal .uploading-file[data-progress="94"]:before, .exam-modal-stats .active-border[data-progress="94"], .exam-modal-stats .uploading-file[data-progress="94"]:before, .exam-miss-modal .active-border[data-progress="94"], .exam-miss-modal .uploading-file[data-progress="94"]:before {
  background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="95"], .question-modal .uploading-file[data-progress="95"]:before, .exam-modal-stats .active-border[data-progress="95"], .exam-modal-stats .uploading-file[data-progress="95"]:before, .exam-miss-modal .active-border[data-progress="95"], .exam-miss-modal .uploading-file[data-progress="95"]:before {
  background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="96"], .question-modal .uploading-file[data-progress="96"]:before, .exam-modal-stats .active-border[data-progress="96"], .exam-modal-stats .uploading-file[data-progress="96"]:before, .exam-miss-modal .active-border[data-progress="96"], .exam-miss-modal .uploading-file[data-progress="96"]:before {
  background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="97"], .question-modal .uploading-file[data-progress="97"]:before, .exam-modal-stats .active-border[data-progress="97"], .exam-modal-stats .uploading-file[data-progress="97"]:before, .exam-miss-modal .active-border[data-progress="97"], .exam-miss-modal .uploading-file[data-progress="97"]:before {
  background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="98"], .question-modal .uploading-file[data-progress="98"]:before, .exam-modal-stats .active-border[data-progress="98"], .exam-modal-stats .uploading-file[data-progress="98"]:before, .exam-miss-modal .active-border[data-progress="98"], .exam-miss-modal .uploading-file[data-progress="98"]:before {
  background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="99"], .question-modal .uploading-file[data-progress="99"]:before, .exam-modal-stats .active-border[data-progress="99"], .exam-modal-stats .uploading-file[data-progress="99"]:before, .exam-miss-modal .active-border[data-progress="99"], .exam-miss-modal .uploading-file[data-progress="99"]:before {
  background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .active-border[data-progress="100"], .question-modal .uploading-file[data-progress="100"]:before, .exam-modal-stats .active-border[data-progress="100"], .exam-modal-stats .uploading-file[data-progress="100"]:before, .exam-miss-modal .active-border[data-progress="100"], .exam-miss-modal .uploading-file[data-progress="100"]:before {
  background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }

.question-modal .circle, .exam-modal-stats .circle, .exam-miss-modal .circle {
  font-size: 12px;
  color: rgba(50, 50, 49, 0.5); }
  .question-modal .circle small, .exam-modal-stats .circle small, .exam-miss-modal .circle small {
    font-size: 10px; }

.question-modal textarea, .exam-modal-stats textarea, .exam-miss-modal textarea {
  margin: 20px 0 0;
  border: none;
  background: #eeede9;
  border-radius: 3px; }
  .question-modal textarea:focus, .exam-modal-stats textarea:focus, .exam-miss-modal textarea:focus {
    box-shadow: none; }

.question-modal .modal-footer, .exam-modal-stats .modal-footer, .exam-miss-modal .modal-footer {
  margin-top: 20px;
  padding: 20px 0 0;
  justify-content: space-between; }

.exam-modal .modal-body {
  padding: 30px; }

.question-modal .modal-body ul, .exam-modal .modal-body ul {
  list-style: none;
  padding: 0;
  margin-top: 20px; }

.question-modal .modal-body li, .exam-modal .modal-body li {
  margin-bottom: 15px; }
  .question-modal .modal-body li span, .exam-modal .modal-body li span {
    color: #4b4b4a;
    font-size: 15px; }
  .question-modal .modal-body li .media-body, .exam-modal .modal-body li .media-body {
    margin-left: 16px;
    color: #7e7d7b; }

.question-modal .questions {
  min-height: auto; }

.question-modal .question-row .tick-radio {
  width: 75%; }

.question-modal .count {
  display: inline-block;
  width: auto;
  height: 20px;
  border-radius: 2px;
  background: #e2e1dd;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  border: none;
  padding: 0 5px; }
  .question-modal .count .percentage {
    background: #5dbe91;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

.question-modal .modal-body {
  padding: 30px 0 0; }
  .question-modal .modal-body .info-box {
    background: #fbf1df;
    border-radius: 3px;
    padding: 20px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    margin: 0 0 20px; }
    .question-modal .modal-body .info-box p {
      margin-bottom: 0;
      font-size: 14px;
      color: #4b4b4a; }
      .question-modal .modal-body .info-box p span {
        font-weight: 600; }
  .question-modal .modal-body h5 {
    color: #323231;
    font-weight: 400;
    line-height: 28px; }
  .question-modal .modal-body .file-name {
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    font-size: 14px;
    align-items: center;
    display: inline-flex;
    height: 36px;
    padding: 0 16px;
    margin: 8px 8px 0 0; }
    .question-modal .modal-body .file-name i {
      font-size: 18px;
      margin-right: 8px; }
    .question-modal .modal-body .file-name span {
      color: #979694;
      margin-left: 10px; }
  .question-modal .modal-body .quiz-tip .form-radio {
    position: relative;
    top: auto; }
  .question-modal .modal-body .quiz-tip .form-radio ~ label:before {
    border: 4px solid red; }

.question-modal .question-note {
  margin-top: 36px;
  padding-top: 36px; }
  .question-modal .question-note span {
    color: #323232; }
  .question-modal .question-note p {
    color: #4b4b4a;
    font-size: 15px;
    margin-top: 4px; }

.ans-option .form-radio {
  width: 15px;
  height: 15px; }
  .ans-option .form-radio:before {
    font-size: 17px; }
  .ans-option .form-radio::checked ~ label {
    color: rgba(0, 0, 0, 0.8); }

.ans-option label {
  width: calc(100% - 20px);
  font-size: 15px;
  line-height: 15px;
  display: block;
  color: #323231 !important; }
  .ans-option label i {
    float: right; }

.ans-option .ans-bar {
  margin: 0 -30px;
  padding: 0 30px; }
  .ans-option .ans-bar i {
    visibility: hidden; }
  .ans-option .ans-bar:hover i {
    visibility: visible; }

.ans-option .del-icon {
  color: #ff6f61; }

.ans-option i {
  cursor: pointer; }

.ans-option .menu {
  top: -5px;
  right: auto;
  bottom: auto;
  left: 3px;
  position: absolute;
  font-size: 23px; }

.ans-option .tag {
  background: #0099ff; }
  .ans-option .tag i, .ans-option .tag span {
    color: #fff; }
  .ans-option .tag i {
    opacity: 1; }

/*Question Accordion*/
.question-accord .card-header {
  padding: 0;
  background: #eeede9;
  border-bottom: none; }
  .question-accord .card-header button {
    width: 100%;
    justify-content: flex-start;
    font-size: 18px;
    height: 56px;
    color: #323231;
    text-decoration: none;
    font-weight: 500;
    position: relative; }
    .question-accord .card-header button[aria-expanded="true"] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    .question-accord .card-header button[aria-expanded="false"] {
      border-bottom: 1px solid transparent; }
    .question-accord .card-header button:after {
      font: 20px/1 'Material Icons';
      top: auto;
      right: 20px;
      bottom: auto;
      left: auto;
      position: absolute;
      color: #979694; }
    .question-accord .card-header button[aria-expanded="true"]:after {
      content: "keyboard_arrow_up"; }
    .question-accord .card-header button[aria-expanded="false"]:after {
      content: "keyboard_arrow_down"; }
    .question-accord .card-header button i {
      margin-right: 12px; }

.question-accord .card {
  margin-bottom: 16px;
  border-color: rgba(0, 0, 0, 0.125); }

.question-accord .card-body {
  background: #fbfaf6; }
  .question-accord .card-body .menu {
    font-size: 16px; }

/* page loader */
.site-loader {
  height: 100vh;
  background: #fbfaf6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  padding: 10px; }
  .site-loader .loader-container {
    max-width: 520px;
    text-align: center; }
  .site-loader p {
    font-weight: 600;
    color: #323232;
    font-size: 20px;
    line-height: 28px;
    margin: 50px 0 10px; }
  .site-loader span {
    color: #979694; }
  .site-loader .circle {
    position: relative;
    display: inline-block;
    padding: 10px; }
    .site-loader .circle div {
      background: url(../images/spinner-2x.png);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      background-size: cover;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.add-new-question .modal-body {
  padding: 30px; }

.add-new-question .difficulty-level .middle-radio {
  margin: 0 40px; }

.add-new-question h5 {
  font-size: 15px; }

.add-new-question .attached-file {
  margin-bottom: 30px; }

.add-row {
  background: #eeede9;
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  margin: 10px 0 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979694;
  font-size: 15px;
  cursor: pointer; }
  .add-row .material-icons {
    font-size: 18px; }

.type-anser .form-control {
  background: none;
  border-width: 0px 0px 1px 0px;
  border-radius: 0;
  padding: 0;
  height: 24px;
  margin-left: 8px;
  color: rgba(50, 50, 49, 0.4); }
  .type-anser .form-control:focus {
    box-shadow: none; }
  .type-anser .form-control:valid ~ .close-icon {
    display: block; }

.type-anser span {
  line-height: 1;
  color: #4b4b4a; }

.type-anser .close-icon {
  position: absolute;
  right: 0;
  display: none; }
  .type-anser .close-icon i {
    font-size: 18px;
    color: rgba(50, 50, 49, 0.4); }

.title-checkboxes {
  background: #eeede9;
  padding: 10px;
  position: relative;
  cursor: pointer; }
  .title-checkboxes .add {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    color: #979694; }
  .title-checkboxes .tick-radio i {
    position: absolute;
    right: 0;
    top: 3px;
    font-size: 18px;
    color: #979694; }
  .title-checkboxes .image-header {
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .title-checkboxes .image-header img:not(.upload-image-sign) {
      width: 100%; }

.sorting-rows {
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #e2e1dd;
  margin-top: 30px; }
  .sorting-rows li {
    display: inline-flex;
    padding: 0 8px;
    position: relative;
    font-size: 15px;
    color: #4b4b4a; }
  .sorting-rows .active {
    font-weight: 600; }
    .sorting-rows .active:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background: #979694;
      top: 100%;
      margin-top: 5px; }

.question-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 13px;
  margin-bottom: 4px;
  cursor: pointer; }
  .question-row:not(.no-hover) {
    background: #fbfaf6;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12); }
    .question-row:not(.no-hover):hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.1);
      background: #fff; }
  .question-row .row-options {
    display: flex;
    align-items: baseline; }
    .question-row .row-options span {
      font-size: 14px;
      min-width: 100px;
      text-align: center; }
    .question-row .row-options > div:not(.tags) {
      min-width: 140px;
      text-align: right; }
    .question-row .row-options .tags {
      min-width: 50px; }
  .question-row .tick-radio {
    width: 65%; }

.user-affect-box {
  background: #e2e1dd;
  padding: 16px 10px 10px;
  border-radius: 3px; }
  .user-affect-box .user-affect-list {
    background: #fbfaf6;
    border-radius: 2px;
    padding: 12px 16px 16px;
    margin-top: 16px; }

.course-intro ul {
  padding: 0 0 0 18px;
  color: #646462; }
  .course-intro ul li {
    padding: 0 0 10px; }

.exam-options-box {
  background: #fff; }
  .exam-options-box .option-header {
    background: #eeede9;
    padding: 22px 18px; }
    .exam-options-box .option-header .user-image {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      margin-right: 15px; }
    .exam-options-box .option-header button {
      margin-left: 16px;
      background: #e2e1dd;
      border: none;
      font-size: 10px;
      border-radius: 3px; }
  .exam-options-box .switch-options {
    padding: 26px 18px;
    border-bottom: 1px solid #e2e1dd; }
  .exam-options-box .circle {
    text-align: center;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center; }
  .exam-options-box .active-border {
    text-align: center;
    width: 96px;
    height: 96px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center; }
  .exam-options-box .uploading-file:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
    transform: rotate(-180deg); }
  .exam-options-box .active-border[data-progress="0"], .exam-options-box .uploading-file[data-progress="0"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="1"], .exam-options-box .uploading-file[data-progress="1"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="2"], .exam-options-box .uploading-file[data-progress="2"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="3"], .exam-options-box .uploading-file[data-progress="3"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="4"], .exam-options-box .uploading-file[data-progress="4"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="5"], .exam-options-box .uploading-file[data-progress="5"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="6"], .exam-options-box .uploading-file[data-progress="6"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="7"], .exam-options-box .uploading-file[data-progress="7"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="8"], .exam-options-box .uploading-file[data-progress="8"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="9"], .exam-options-box .uploading-file[data-progress="9"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="10"], .exam-options-box .uploading-file[data-progress="10"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="11"], .exam-options-box .uploading-file[data-progress="11"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="12"], .exam-options-box .uploading-file[data-progress="12"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="13"], .exam-options-box .uploading-file[data-progress="13"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="14"], .exam-options-box .uploading-file[data-progress="14"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="15"], .exam-options-box .uploading-file[data-progress="15"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="16"], .exam-options-box .uploading-file[data-progress="16"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="17"], .exam-options-box .uploading-file[data-progress="17"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="18"], .exam-options-box .uploading-file[data-progress="18"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="19"], .exam-options-box .uploading-file[data-progress="19"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="20"], .exam-options-box .uploading-file[data-progress="20"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="21"], .exam-options-box .uploading-file[data-progress="21"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="22"], .exam-options-box .uploading-file[data-progress="22"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="23"], .exam-options-box .uploading-file[data-progress="23"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="24"], .exam-options-box .uploading-file[data-progress="24"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="25"], .exam-options-box .uploading-file[data-progress="25"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="26"], .exam-options-box .uploading-file[data-progress="26"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="27"], .exam-options-box .uploading-file[data-progress="27"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="28"], .exam-options-box .uploading-file[data-progress="28"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="29"], .exam-options-box .uploading-file[data-progress="29"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="30"], .exam-options-box .uploading-file[data-progress="30"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="31"], .exam-options-box .uploading-file[data-progress="31"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="32"], .exam-options-box .uploading-file[data-progress="32"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="33"], .exam-options-box .uploading-file[data-progress="33"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="34"], .exam-options-box .uploading-file[data-progress="34"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="35"], .exam-options-box .uploading-file[data-progress="35"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="36"], .exam-options-box .uploading-file[data-progress="36"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="37"], .exam-options-box .uploading-file[data-progress="37"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="38"], .exam-options-box .uploading-file[data-progress="38"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="39"], .exam-options-box .uploading-file[data-progress="39"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="40"], .exam-options-box .uploading-file[data-progress="40"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="41"], .exam-options-box .uploading-file[data-progress="41"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="42"], .exam-options-box .uploading-file[data-progress="42"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="43"], .exam-options-box .uploading-file[data-progress="43"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="44"], .exam-options-box .uploading-file[data-progress="44"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="45"], .exam-options-box .uploading-file[data-progress="45"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="46"], .exam-options-box .uploading-file[data-progress="46"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="47"], .exam-options-box .uploading-file[data-progress="47"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="48"], .exam-options-box .uploading-file[data-progress="48"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="49"], .exam-options-box .uploading-file[data-progress="49"]:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="50"], .exam-options-box .uploading-file[data-progress="50"]:before {
    background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="51"], .exam-options-box .uploading-file[data-progress="51"]:before {
    background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="52"], .exam-options-box .uploading-file[data-progress="52"]:before {
    background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="53"], .exam-options-box .uploading-file[data-progress="53"]:before {
    background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="54"], .exam-options-box .uploading-file[data-progress="54"]:before {
    background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="55"], .exam-options-box .uploading-file[data-progress="55"]:before {
    background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="56"], .exam-options-box .uploading-file[data-progress="56"]:before {
    background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="57"], .exam-options-box .uploading-file[data-progress="57"]:before {
    background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="58"], .exam-options-box .uploading-file[data-progress="58"]:before {
    background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="59"], .exam-options-box .uploading-file[data-progress="59"]:before {
    background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="60"], .exam-options-box .uploading-file[data-progress="60"]:before {
    background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="61"], .exam-options-box .uploading-file[data-progress="61"]:before {
    background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="62"], .exam-options-box .uploading-file[data-progress="62"]:before {
    background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="63"], .exam-options-box .uploading-file[data-progress="63"]:before {
    background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="64"], .exam-options-box .uploading-file[data-progress="64"]:before {
    background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="65"], .exam-options-box .uploading-file[data-progress="65"]:before {
    background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="66"], .exam-options-box .uploading-file[data-progress="66"]:before {
    background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="67"], .exam-options-box .uploading-file[data-progress="67"]:before {
    background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="68"], .exam-options-box .uploading-file[data-progress="68"]:before {
    background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="69"], .exam-options-box .uploading-file[data-progress="69"]:before {
    background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="70"], .exam-options-box .uploading-file[data-progress="70"]:before {
    background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="71"], .exam-options-box .uploading-file[data-progress="71"]:before {
    background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="72"], .exam-options-box .uploading-file[data-progress="72"]:before {
    background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="73"], .exam-options-box .uploading-file[data-progress="73"]:before {
    background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="74"], .exam-options-box .uploading-file[data-progress="74"]:before {
    background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="75"], .exam-options-box .uploading-file[data-progress="75"]:before {
    background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="76"], .exam-options-box .uploading-file[data-progress="76"]:before {
    background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="77"], .exam-options-box .uploading-file[data-progress="77"]:before {
    background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="78"], .exam-options-box .uploading-file[data-progress="78"]:before {
    background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="79"], .exam-options-box .uploading-file[data-progress="79"]:before {
    background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="80"], .exam-options-box .uploading-file[data-progress="80"]:before {
    background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="81"], .exam-options-box .uploading-file[data-progress="81"]:before {
    background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="82"], .exam-options-box .uploading-file[data-progress="82"]:before {
    background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="83"], .exam-options-box .uploading-file[data-progress="83"]:before {
    background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="84"], .exam-options-box .uploading-file[data-progress="84"]:before {
    background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="85"], .exam-options-box .uploading-file[data-progress="85"]:before {
    background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="86"], .exam-options-box .uploading-file[data-progress="86"]:before {
    background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="87"], .exam-options-box .uploading-file[data-progress="87"]:before {
    background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="88"], .exam-options-box .uploading-file[data-progress="88"]:before {
    background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="89"], .exam-options-box .uploading-file[data-progress="89"]:before {
    background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="90"], .exam-options-box .uploading-file[data-progress="90"]:before {
    background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="91"], .exam-options-box .uploading-file[data-progress="91"]:before {
    background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="92"], .exam-options-box .uploading-file[data-progress="92"]:before {
    background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="93"], .exam-options-box .uploading-file[data-progress="93"]:before {
    background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="94"], .exam-options-box .uploading-file[data-progress="94"]:before {
    background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="95"], .exam-options-box .uploading-file[data-progress="95"]:before {
    background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="96"], .exam-options-box .uploading-file[data-progress="96"]:before {
    background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="97"], .exam-options-box .uploading-file[data-progress="97"]:before {
    background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="98"], .exam-options-box .uploading-file[data-progress="98"]:before {
    background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="99"], .exam-options-box .uploading-file[data-progress="99"]:before {
    background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .active-border[data-progress="100"], .exam-options-box .uploading-file[data-progress="100"]:before {
    background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1)); }
  .exam-options-box .clock {
    padding: 20px 10px 26px;
    text-align: center;
    border-bottom: 1px solid #e2e1dd; }
    .exam-options-box .clock > span {
      font-size: 11px;
      color: rgba(33, 37, 41, 0.3);
      text-transform: uppercase; }
    .exam-options-box .clock .active-border {
      margin: 0 auto; }
  .exam-options-box .calendar {
    margin: 0;
    padding: 30px 20px; }
    .exam-options-box .calendar li {
      display: inline-block;
      margin: 0 !important; }
      .exam-options-box .calendar li button {
        width: 24px;
        height: 24px;
        font-size: 12px;
        background: #eeede9;
        border: none;
        border-radius: 3px;
        color: #646462;
        display: inline-block;
        vertical-align: middle;
        line-height: 20px;
        padding: 0;
        margin-bottom: 10px;
        cursor: pointer; }
        .exam-options-box .calendar li button.active {
          background: none;
          border: 1px solid #c9c8c5; }

.exam-miss-modal .modal-dialog {
  max-width: 550px; }

.exam-miss-modal .modal-header {
  border-bottom: none; }

.exam-miss-modal h6 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
  margin: 0;
  line-height: 20px; }

.exam-miss-modal .modal-body h5 {
  font-size: 16px;
  line-height: 32px;
  margin: 0; }

.exam-miss-modal .modal-body > p {
  padding-top: 20px;
  line-height: 24px;
  font-size: 14px; }

.exam-miss-modal .form-group label {
  display: block; }

.exam-miss-modal .form-group textarea {
  width: 100%; }

.meesage-container {
  display: flex; }
  .meesage-container > * {
    float: left;
    min-height: calc(100vh - 64px); }
  .meesage-container .custom-col-1 {
    width: 320px; }
  .meesage-container .custom-col-2 {
    width: calc(100% - 320px - 280px); }
  .meesage-container .custom-col-3 {
    width: 280px; }
  .meesage-container .main-sidebar, .meesage-container .conversation-window {
    height: calc(100vh - 64px);
    overflow: auto; }
  .meesage-container .message-intro {
    padding-right: 25px; }
  .meesage-container .message-count {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #39b27b;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    border: none;
    color: #fff; }
    .meesage-container .message-count .percentage {
      background: #5dbe91;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
  .meesage-container .side-option {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%); }
  .meesage-container .media-body {
    position: relative; }
  .meesage-container .material-icons {
    color: #979694; }
  .meesage-container .material-icons.active {
    color: #0099ff; }
  .meesage-container .single-messages > *:not(:last-child) .media-body {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 15px; }

.message-input {
  position: fixed;
  width: calc(100% - 320px - 280px);
  bottom: 0;
  font-size: 14px;
  background: #eeede9; }
  .message-input .userinfo {
    padding: 20px; }
  .message-input div[contentEditable="true"] {
    display: inline-block;
    height: 56px;
    width: calc(100% - 104px);
    padding: 15px;
    box-sizing: border-box;
    overflow-y: auto;
    position: relative; }
    .message-input div[contentEditable="true"] span {
      color: #b0afac;
      position: absolute;
      top: 22px; }
    .message-input div[contentEditable="true"]:focus {
      outline: none; }
      .message-input div[contentEditable="true"]:focus span {
        display: none; }

.col-container {
  overflow: hidden; }

.meesage-container .col-label {
  padding-top: 16px;
  padding-left: 16px; }

.meesage-container .col-label, .meesage-container .message-people-list label {
  font-size: 12px;
  color: #b0afac;
  text-transform: uppercase;
  display: block; }

.meesage-container .userinfo img {
  height: 32px;
  width: 32px; }

.meesage-container .userinfo .media-body {
  margin-left: 10px; }
  .meesage-container .userinfo .media-body span {
    font-size: 14px;
    font-weight: 500; }
  .meesage-container .userinfo .media-body i {
    color: #b0afac;
    font-size: 14px;
    font-style: normal; }

.meesage-container .message-bar {
  padding: 20px;
  max-width: 400px; }
  .meesage-container .message-bar .message-content {
    margin-top: 10px;
    background: #eeede9;
    padding: 12px 16px;
    border-radius: 0px 10px 10px 10px;
    display: inline-block; }
    .meesage-container .message-bar .message-content p {
      margin: 0;
      font-size: 14px; }
  .meesage-container .message-bar i {
    margin-left: 10px; }

.message-people-list label {
  padding: 16px 10px 10px 17px; }

.message-people-list li {
  margin-bottom: 12px; }
  .message-people-list li.active:after {
    left: 34px !important; }

.message-people-list .media-body {
  margin-left: 12px;
  width: calc(100% - 48px); }
  .message-people-list .media-body span {
    font-weight: 500;
    font-size: 14px;
    width: calc(100% - 18px);
    display: inline-block;
    vertical-align: middle; }

.message-people-list .media {
  padding: 0 8px; }

.conversation-window {
  background: #fbfaf6; }
  .conversation-window .conversation {
    justify-content: end;
    height: calc(100% - 56px - 63px);
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse; }
    .conversation-window .conversation > .userinfo {
      padding: 20px; }
  .conversation-window .conversation-header {
    padding: 13px 20px 12px;
    border-bottom: 1px solid #e2e1dd; }

@media (min-width: 768px) {
  .login-wrapper {
    padding: 40px;
    min-height: calc(100vh); }
    .login-wrapper .login-block {
      padding: 40px 40px 20px;
      min-height: calc(100vh - 50px - 80px);
      border-radius: 3px; }
  .login-block-footer {
    margin: 0 20px; }
    .login-block-footer a {
      color: #323231;
      text-decoration: none; }
  .login-block-logo img {
    width: 100%; }
  .notification {
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%; }
    .notification .notification-block {
      transform: translateX(320px);
      transition: -webkit-transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
  .active-notification-panel .min-container {
    position: fixed; }
  .active-notification-panel .notification-block {
    transform: translateX(0); }
  .notification-bell, .hide-notification {
    right: 40px;
    bottom: 40px;
    position: fixed; }
  /*Header*/
  header .big-logo {
    height: 72px; }
  header .menu {
    padding: 0;
    margin: 0;
    list-style: none; }
    header .menu > li {
      display: inline-block; }
      header .menu > li:not(:first-child) {
        margin-top: 0; }
      header .menu > li .active {
        background: rgba(0, 0, 0, 0.5); }
      header .menu > li:not(.active):hover {
        background: rgba(0, 0, 0, 0.2); }
      header .menu > li a {
        display: block;
        border-radius: 2px;
        display: block;
        padding: 10px 16px;
        font-size: 15px;
        line-height: 24px;
        color: #fff;
        font-weight: 600; }
        header .menu > li a .count {
          margin-left: 8px;
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: rgba(0, 0, 0, 0.2);
          font-size: 14px;
          text-align: center;
          font-weight: 400;
          line-height: 20px;
          border: none; }
          header .menu > li a .count .percentage {
            background: #5dbe91;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1; }
        header .menu > li a:hover {
          text-decoration: none; }
    header .menu > li.new-notification {
      position: relative; }
      header .menu > li.new-notification:after {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #ff6f61;
        position: absolute;
        left: auto;
        right: 4px;
        top: 4px;
        content: ""; }
    header .menu > li:not(:last-child) {
      margin-right: 12px; }
  header .header-options {
    display: inline-flex;
    align-items: center; }
    header .header-options svg {
      cursor: pointer; }
      header .header-options svg g {
        fill: #fff; }
    header .header-options > * {
      display: inline-flex;
      height: 32px;
      width: 32px;
      align-items: center;
      justify-content: center; }
      header .header-options > * svg, header .header-options > * .material-icons {
        opacity: 0.8; }
      header .header-options > *:hover {
        opacity: 1; }
    header .header-options .material-icons {
      color: #fff;
      cursor: pointer; }
    header .header-options .message-dropdown {
      margin: 0 16px; }
    header .header-options .plus-dropdown {
      margin: 0 8px; }
    header .header-options .user-dropdown {
      padding-left: 18px;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      cursor: pointer;
      width: auto; }
      header .header-options .user-dropdown img {
        width: 32px;
        height: 32px;
        border-radius: 100%; }
  .media h6 {
    width: 100%; }
  .lesson-header .media-body {
    margin-left: 30px; }
  .tab-header .nav {
    border-top: 1px solid #e2e1dd;
    border-bottom: none;
    padding: 11px 0; }
  .tab-header a.active:after {
    content: "";
    height: 2px;
    background: #191919;
    position: absolute;
    top: -12px;
    left: 0;
    right: 0; }
  .tab-header .dropdown-menu {
    position: static !important;
    display: block;
    float: none;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    transform: none !important; }
  .carousel-slide {
    min-width: 350px; }
  .discussion-content {
    padding: 48px 0 0 97px; }
    .discussion-content .content-box {
      padding: 0px 60px 32px; }
    .discussion-content .course-footer {
      padding: 16px 68px 16px 24px; }
    .discussion-content .progressed-bar {
      padding: 0 70px 24px; }
  .content-container {
    overflow: hidden; }
    .content-container > * {
      float: left; }
    .content-container:not(.announcement) .main-sidebar {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    .content-container .main-sidebar {
      width: 320px;
      min-height: 100vh; }
    .content-container .tabs-section {
      padding-left: 34px;
      width: calc(100% -  320px);
      position: relative; }
      .content-container .tabs-section:after {
        content: "";
        position: absolute;
        left: auto;
        width: 320px;
        right: 100%;
        background: #eeede9;
        top: 0;
        bottom: 0;
        z-index: -1;
        border-right: 1px solid #fbfaf6; }
  .progressed-bar {
    position: relative; }
    .progressed-bar:after {
      content: "";
      background: rgba(0, 0, 0, 0.1);
      height: 1px;
      position: absolute;
      top: 18px;
      right: 70px;
      left: 70px; }
  .modal-from-right .tab-content {
    padding: 32px; }
  .modal-from-right .modal-header .close {
    margin-left: -65px;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0; }
  .modal-from-right .modal-dialog {
    max-width: 700px; }
  .content-box.black-bg {
    padding-top: 78px !important;
    padding-bottom: 78px !important; }
  .upload-file-display li {
    max-width: 165px; }
  .question-modal .modal-dialog {
    max-width: 730px; }
  #session {
    margin: -32px; } }

.exam-date p {
  font-size: 14px;
  color: #b0afac; }

.small-switch .switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  cursor: pointer;
  /* Rounded sliders */ }
  .small-switch .switch:focus {
    outline: none; }
  .small-switch .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .small-switch .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    outline: none !important;
    box-shadow: none !important; }
    .small-switch .switch .slider:before {
      content: "";
      right: 2px;
      bottom: 2px; }
    .small-switch .switch .slider:after, .small-switch .switch .slider:before {
      position: absolute;
      -webkit-transition: .4s;
      transition: .4s; }
    .small-switch .switch .slider:before {
      position: absolute;
      height: 16px;
      width: 16px;
      background-color: white; }
  .small-switch .switch input:checked + .slider:before {
    -webkit-transform: translateX(-17px);
    -ms-transform: translateX(-17px);
    transform: translateX(-17px); }
  .small-switch .switch input:checked + .slider {
    background-color: #319b6b; }
  .small-switch .switch input:focus + .slider {
    box-shadow: 0 0 1px #319b6b; }
  .small-switch .switch .slider.round {
    border-radius: 34px; }
  .small-switch .switch .slider.round:before {
    border-radius: 50%; }

.small-switch .switch {
  vertical-align: middle; }

.small-switch label {
  margin: 0 10px 0 0; }

.small-switch span {
  font-size: 15px; }

.exam-modal-stats .stats-bar-block h5 {
  margin: 0;
  line-height: 32px; }

.exam-modal-stats .stats-bar-block .table td {
  padding-bottom: 0;
  border: none; }
  .exam-modal-stats .stats-bar-block .table td:last-child {
    color: #4b4b4a; }
  .exam-modal-stats .stats-bar-block .table td:first-child {
    font-weight: 600; }

.exam-modal-stats .stats-bar-block span {
  color: rgba(25, 25, 25, 0.5); }

@media (min-width: 1025px) {
  .lesson-tabs .tab-header li:not(:last-child):not(:first-child) {
    margin-right: 30px; }
  .lesson-tabs .tab-header li:first-child {
    margin-right: 20px; }
  .carousel-slide {
    margin: 0 0 74px;
    height: calc(100% - 74px); }
  #examStatsModal .modal-dialog {
    max-width: calc(1110px - 64px); }
  .modal-from-right .modal-dialog {
    max-width: 50%; }
  .modal-from-right .tab-content .tab-pane {
    display: block;
    position: absolute;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    width: 600px;
    top: -100%;
    z-index: -1; }
  .modal-from-right .tab-pane.active {
    position: relative;
    z-index: 9;
    top: auto; }
    .modal-from-right .tab-pane.active#video {
      width: calc(1100px - 64px); }
    .modal-from-right .tab-pane.active#profile, .modal-from-right .tab-pane.active#exam-date {
      width: calc(720px - 64px); }
    .modal-from-right .tab-pane.active#contact, .modal-from-right .tab-pane.active#home {
      width: calc(600px - 64px); }
    .modal-from-right .tab-pane.active#session {
      width: calc(960px - 65px); }
  #listMakeRequest .modal-dialog {
    width: calc(100% - 64px); }
  #listMakeRequest .modal-body {
    min-height: calc(100vh - 63px); }
  #listMakeRequest .create-announcement {
    max-width: 550px;
    margin: 0 auto;
    border-left: none; }
  .question-accord .card-body {
    padding: 32px 24px; }
  .add-announcement .modal-dialog {
    max-width: 720px;
    width: 100%; }
  .add-announcement .modal-body {
    padding: 30px; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .lesson-tabs .tab-header li:not(:last-child):not(:first-child) {
    margin-right: 15px; }
  .lesson-tabs .tab-header li:first-child {
    margin-right: 10px; } }

@media (max-width: 1024px) {
  .carousel-slide {
    margin-top: 30px; }
  .question-accord .card-body {
    padding: 20px 15px; } }

@media (max-width: 767px) {
  .login-block-logo img {
    display: block;
    width: 100%;
    max-width: 220px;
    margin: 0 auto; }
  html, body {
    overflow-x: hidden; }
  .login-wrapper {
    width: 200%;
    padding: 15px; }
    .login-wrapper > .row {
      transform: translateX(0);
      transition: -webkit-transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
    .login-wrapper .login-block {
      border-radius: 3px 3px 0 0;
      padding: 20px 20px 30px;
      min-height: calc(100vh - 50px - 20px); }
  .notification {
    overflow: hidden; }
    .notification .notification-block:not(:nth-child(-n+2)) {
      display: none; }
  .active-notification-panel .login-wrapper > .row {
    transform: translateX(calc(-50% + 30px)); }
  .active-notification-panel .notification {
    height: auto; }
    .active-notification-panel .notification .notification-block {
      display: block; }
  .notification-bell, .hide-notification {
    right: 20px;
    bottom: 20px;
    position: fixed; }
  .menu-button {
    background: none;
    color: #fff;
    opacity: 0.7;
    float: right; }
    .menu-button:hover {
      opacity: 1; }
  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    background: #fbfaf6;
    left: 47px;
    bottom: 0;
    z-index: 9; }
    .mobile-menu .close {
      background: #fbfaf6;
      z-index: 999;
      opacity: 1;
      font-size: 13px;
      padding: 15px 7px 2px;
      text-shadow: none;
      position: absolute;
      left: -48px;
      top: 0;
      text-transform: uppercase; }
      .mobile-menu .close:focus {
        outline: none; }
      .mobile-menu .close:hover {
        opacity: 1; }
      .mobile-menu .close i {
        display: block;
        font-size: 18px; }
      .mobile-menu .close span {
        opacity: 0.3;
        color: #323231;
        font-size: 10px; }
    .mobile-menu:after {
      position: absolute;
      content: "";
      right: 100%;
      bottom: 0;
      top: 0;
      background: #000;
      opacity: 0.6;
      width: 100%; }
    .mobile-menu .menu {
      padding: 0;
      margin: 0;
      list-style: none; }
      .mobile-menu .menu > li {
        display: block; }
        .mobile-menu .menu > li:not(:first-child) {
          margin-top: 0; }
        .mobile-menu .menu > li .active {
          background: #e2e1dd; }
        .mobile-menu .menu > li:not(.active):hover {
          background: #eeede9; }
        .mobile-menu .menu > li a {
          display: block;
          border-radius: 2px;
          display: block;
          padding: 12px 25px;
          font-size: 17px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.9);
          font-weight: 600; }
          .mobile-menu .menu > li a .count {
            margin-left: 8px;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background: rgba(0, 0, 0, 0.2);
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            line-height: 20px;
            border: none; }
            .mobile-menu .menu > li a .count .percentage {
              background: #5dbe91;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: -1; }
          .mobile-menu .menu > li a:hover {
            text-decoration: none; }
      .mobile-menu .menu .new-notification {
        position: relative; }
        .mobile-menu .menu .new-notification:after {
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #ff6f61;
          position: absolute;
          left: 6px;
          right: auto;
          top: 50%;
          content: ""; }
        .mobile-menu .menu .new-notification:after {
          margin-top: -3px; }
  .header-options {
    position: absolute;
    bottom: 0;
    background: rgba(250, 250, 250, 0.9);
    left: 0;
    right: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-around;
    padding: 9px 0;
    text-align: center;
    align-items: flex-end; }
    .header-options .new-notification {
      position: relative; }
      .header-options .new-notification:after {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #ff6f61;
        position: absolute;
        left: auto;
        right: 0;
        top: 0;
        content: ""; }
      .header-options .new-notification:after {
        margin-top: -3px; }
    .header-options .plus-dropdown {
      display: none; }
    .header-options .user-dropdown img {
      display: none; }
    .header-options .user-dropdown svg {
      margin-bottom: 10px; }
    .header-options span {
      font-size: 10px;
      color: #323231; }
    .header-options svg g {
      fill: rgba(0, 0, 0, 0.7); }
  .lesson-header .media {
    display: block;
    text-align: center; }
    .lesson-header .media .media-body {
      margin-top: 15px; }
  .lesson-tabs .tab-header .dropdown-menu {
    padding: 0 15px;
    margin: 0;
    border-radius: 0 0 4px 4px; }
  .lesson-tabs .tab-header .nav li {
    width: block;
    width: 100%;
    padding: 5px 0 0; }
    .lesson-tabs .tab-header .nav li:last-child {
      padding-bottom: 10px; }
    .lesson-tabs .tab-header .nav li.new-notification:after {
      top: 17px; }
  .lesson-tabs .tab-header [data-toggle="dropdown"] {
    box-shadow: none !important;
    width: 100%;
    height: 48px; }
    .lesson-tabs .tab-header [data-toggle="dropdown"][aria-expanded="true"] {
      background: #0099ff;
      color: #fff;
      border-color: #0099ff;
      border-radius: 4px 4px 0px 0px; }
    .lesson-tabs .tab-header [data-toggle="dropdown"][aria-expanded="false"] {
      background: none;
      border: 1px solid #191919;
      color: #191919; }
  .lesson-tabs.studen-tabs .tab-content {
    padding-bottom: 30px; }
  .discussion-content {
    padding: 0; }
    .discussion-content .content-box {
      padding: 0px 24px 32px; }
    .discussion-content .course-footer {
      padding: 20px 24px; }
    .discussion-content .progressed-bar {
      padding: 0 24px 24px;
      text-align: center; }
    .discussion-content .count {
      margin-top: 20px; }
  .main-sidebar {
    display: none; }
  .modal-header .close {
    right: 0;
    margin: 0;
    top: 0; }
  .modal-from-right .tab-content {
    padding: 16px; }
  .modal-from-right .modal-footer button {
    width: 100%; }
  .upload-file-display li {
    width: 45%; }
    .upload-file-display li img {
      width: 100%; } }

.react-switch {
  vertical-align: middle;
  margin-left: 16px;
  float: right;
  transform: scale(0.8); }

.student-course {
  background-color: #eeede9; }
  .student-course .student-course-header {
    padding: 30px 0; }
    .student-course .student-course-header h2 {
      color: #191919;
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8; }
    .student-course .student-course-header a {
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.85;
      letter-spacing: normal;
      color: #646462; }
      .student-course .student-course-header a:hover {
        text-decoration: none;
        color: #007bff; }

.student-course-list {
  margin-bottom: 30px; }
  .student-course-list .list-icons a.notify-dot:after, .student-course-list .list-icons i.notify-dot:after {
    width: 6px;
    height: 6px;
    background: #ff6f61;
    position: absolute;
    left: auto;
    right: -6px;
    top: -4px;
    content: "";
    border-radius: 100%; }

.student-course .student-course-list li {
  list-style: none;
  padding: 10px 10px;
  background-color: #fbfaf6;
  margin-bottom: 4px; }

.student-course-list .active-border, .course-timeline-item .active-border {
  background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1));
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }

.student-course-list .circle, .course-timeline-item .circle {
  font-size: 12px;
  color: rgba(50, 50, 49, 0.5);
  text-align: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #fbfaf6;
  display: flex;
  justify-content: center;
  align-items: center; }

.student-course .student-course-list li .list-icons {
  display: inline-block;
  cursor: pointer;
  position: relative; }

.list-icons:hover > .student-course .student-course-list li {
  background-color: #000; }

.student-course .student-course-list li .course-icon {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top; }

.student-course .student-course-list li h5 {
  color: #323231;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 5px; }

.student-course .student-course-list li p {
  color: #323231;
  opacity: 0.5;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  margin: 0; }

.lead-bar {
  background: rgba(0, 0, 0, 0.05);
  height: 6px;
  border-radius: 32px;
  position: relative; }

.lead-bar-width {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #39b27b;
  border-radius: 32px; }

.lead-bar-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: #646462; }

.scrollable-popoverbody {
  max-height: 256px;
  overflow: auto;
  width: 100%; }

::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px; }

:hover::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2); }

::-webkit-scrollbar-thumb {
  opacity: 0.1;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }
