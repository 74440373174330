/**
 * adding sorting ability to HTML tables with Bootstrap styling
 * @summary HTML tables sorting ability
 * @version 2.0.0
 * @requires tinysort, moment.js, jQuery
 * @license MIT
 * @author Matus Brlit (drvic10k)
 * @copyright Matus Brlit (drvic10k), bootstrap-sortable contributors
 */

table.sortable span.sign {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 12px;
    margin-top: -10px;
    color: #bfbfc1;
}

table.sortable th:after {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 12px;
    margin-top: -10px;
    color: #bfbfc1;
}

table.sortable th.arrow:after {
    content: '';
}

table.sortable span.arrow, span.reversed, th.arrow.down:after, th.reversedarrow.down:after, th.arrow.up:after, th.reversedarrow.up:after {
    border-style: solid;
    border-width: 5px;
    font-size: 0;
    border-color: #ccc transparent transparent transparent;
    line-height: 0;
    height: 0;
    width: 0;
    margin-top: -2px;
}

    table.sortable span.arrow.up, th.arrow.up:after {
        border-color: transparent transparent #ccc transparent;
        margin-top: -7px;
    }

table.sortable span.reversed, th.reversedarrow.down:after {
    border-color: transparent transparent #ccc transparent;
    margin-top: -7px;
}

    table.sortable span.reversed.up, th.reversedarrow.up:after {
        border-color: #ccc transparent transparent transparent;
        margin-top: -2px;
    }

table.sortable span.az:before, th.az.down:after {
    content: "a .. z";
}

table.sortable span.az.up:before, th.az.up:after {
    content: "z .. a";
}

table.sortable th.az.nosort:after, th.AZ.nosort:after, th._19.nosort:after, th.month.nosort:after {
    content: "..";
}

table.sortable span.AZ:before, th.AZ.down:after {
    content: "A .. Z";
}

table.sortable span.AZ.up:before, th.AZ.up:after {
    content: "Z .. A";
}

table.sortable span._19:before, th._19.down:after {
    content: "1 .. 9";
}

table.sortable span._19.up:before, th._19.up:after {
    content: "9 .. 1";
}

table.sortable span.month:before, th.month.down:after {
    content: "jan .. dec";
}

table.sortable span.month.up:before, th.month.up:after {
    content: "dec .. jan";
}

table.sortable>thead th:not([data-defaultsort=disabled]) {
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
}

table.sortable>thead th:hover:not([data-defaultsort=disabled]) {
    background: #efefef;
}

table.sortable>thead th div.mozilla {
    position: relative;
}
